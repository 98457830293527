import { useMemo } from 'react';
import { SummaryFormValues } from '../../../components/ControlLayout/hooks/useSummaryForm';
type Deviation = {
  id: number;
  description: string;
  probability: keyof SummaryFormValues;
  consequence: keyof SummaryFormValues;
};

export const useDeviationTableData = () => {
  const deviations: Deviation[] = [
    {
      id: 1,
      description: 'Skalskydd/inhägnad',
      probability: 'shellProtectionProbability',
      consequence: 'shellProtectionConsequence',
    },
    {
      id: 2,
      description: 'Arbetsmiljö',
      probability: 'workEnvironmentProbability',
      consequence: 'workEnvironmentConsequence',
    },
    {
      id: 3,
      description: 'Elektronisk personalliggare',
      probability: 'workRegisterProbability',
      consequence: 'workRegisterConsequence',
    },
    {
      id: 4,
      description: 'Avsaknad av giltig legitimation',
      probability: 'identificationProbability',
      consequence: 'identificationConsequence',
    },
    {
      id: 5,
      description: 'Brott mot ID06 regelverk',
      probability: 'id06DeviationsProbability',
      consequence: 'id06DeviationsConsequence',
    },
    {
      id: 6,
      description: 'Personlig säkerhetsutrustning',
      probability: 'safetyEquipmentProbability',
      consequence: 'safetyEquipmentConsequence',
    },
    {
      id: 7,
      description: 'Kontroll av arbetstillstånd och uppehållshandlingar',
      probability: 'workPermitProbability',
      consequence: 'workPermitConsequence',
    },
    {
      id: 8,
      description: 'Förgodkända UE',
      probability: 'preApprovedSubcontractorProbability',
      consequence: 'preApprovedSubcontractorConsequence',
    },

    {
      id: 9,
      description: 'Utstationeringsregelverket',
      probability: 'secondmentRegulationsProbability',
      consequence: 'secondmentRegulationsConsequence',
    },
    {
      id: 10,
      description: 'Tecken på onykterhet och drogpåverkan',
      probability: 'intoxicationProbability',
      consequence: 'intoxicationConsequence',
    },

    {
      id: 11,
      description: 'Övrig kontrollverksamhet',
      probability: 'otherProbability',
      consequence: 'otherConsequence',
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID#',
        accessor: 'id',
      },
      {
        Header: 'Beskrivning',
        accessor: 'description',
      },
      {
        Header: 'Sannolikhet',
        accessor: 'probability',
      },
      {
        Header: 'Konsekvens',
        accessor: 'consequence',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return deviations.map((deviation) => {
      return {
        id: deviation.id,
        description: deviation.description,
        probability: deviation.probability,
        consequence: deviation.consequence,
      };
    });
  }, [deviations]);

  return { columns, data };
};
