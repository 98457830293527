const all = ['news'] as const;

const findMany = (filters: { page: number }) => {
  return [...all, { ...filters }] as const;
};

const findOne = (newsId: string) => {
  return [...all, newsId] as const;
};

export const newsKeys = {
  all,
  findMany,
  findOne,
};
