import { ChangeEvent, useRef, useState } from 'react';
import { usePostFile } from '../../../../../../../traits/api/images/usePostFile';

export const useReportUpload = () => {
  const [fileId, setFileId] = useState<null | string>(null);
  const postFile = usePostFile();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    const res = await postFile.mutateAsync(file);
    setFileId(res.fileId);

    return res.fileId;
  };

  return {
    inputRef,
    handleUploadClick,
    handleFileChange,
    fileId,
  };
};
