import React from 'react';
import { S } from './Error.styles';

type Props = {
  refetch: () => void;
  title?: string;
  message?: string;
};

export const Error: React.FC<Props> = (props) => {
  return (
    <S.CenterSection>
      <S.Wrapper>
        <S.TextContainer>
          <S.H3 $color="error">{props.title || 'Något gick fel'}</S.H3>

          {props.message && <S.B3 $align="center">{props.message}</S.B3>}
        </S.TextContainer>

        <S.Button onClick={props.refetch} $width={'60%'}>
          Ladda om
        </S.Button>
      </S.Wrapper>
    </S.CenterSection>
  );
};
