import React, { MouseEvent } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

import { S } from './Dialog.styles';

type Props = React.PropsWithChildren<{
  onClose: () => void;
}>;

export const Dialog: React.FC<Props> = (props) => {
  const onCancel = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    props.onClose();
  };

  return (
    <AlertDialog.Portal>
      <S.Overlay>
        <S.Wrapper>
          <S.CancelButton onClick={onCancel}>
            <S.Icon src="Close" width={24} height={24} />
          </S.CancelButton>
          <S.ContentContainer>{props.children}</S.ContentContainer>
        </S.Wrapper>
      </S.Overlay>
    </AlertDialog.Portal>
  );
};
