import React from 'react';
import { FieldError } from 'react-hook-form';

import { S } from './Input.styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: FieldError | undefined;
}

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, ...rest } = props;
  return (
    <div>
      <S.Wrapper>
        <S.Label>{label}</S.Label>
        <S.Input {...rest} ref={ref} />
      </S.Wrapper>
      {props.error && (
        <S.Error>
          <S.B3 $color="error">{props.error.message}</S.B3>
        </S.Error>
      )}
    </div>
  );
});
