import styled, { css } from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
`;

const Table = styled.table`
  width: calc(100% + 32px);
  transform: translateX(-16px);

  tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  tr:first-child td {
    border-top-style: solid;
  }
  tr td:first-child {
    border-left-style: solid;
  }
`;

const THead = styled.thead``;

const THeadRow = styled.tr`
  height: 42px;
`;

const THeadCell = styled.th`
  padding: 16px;
  padding-bottom: 4px;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

const TBody = styled.tbody``;

const DeleteDataCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  opacity: 0;
  min-width: 210px;
`;

const TBodyRow = styled.tr`
  position: relative;
  cursor: pointer;
  height: 58px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryLight}99;

    ${DeleteDataCell} {
      opacity: 1;
    }
  }
`;

const TBodyCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
`;

const ID06Tag = styled.div<{
  $type: 'Saknas' | 'Aktivt' | 'Inaktivt' | 'Avstängt' | 'Ogiltigt' | 'Annat';
  $hasComment: boolean;
}>`
  position: relative;
  width: fit-content;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  color: ${({ theme, $type }) => {
    switch ($type) {
      case 'Saknas':
        return theme.colors.black;
      case 'Aktivt':
        return theme.colors.white;
      case 'Inaktivt':
        return theme.colors.white;
      case 'Avstängt':
        return theme.colors.white;
      case 'Ogiltigt':
        return theme.colors.white;
      case 'Annat':
        return theme.colors.white;
      default:
        return theme.colors.black;
    }
  }};
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'Saknas':
        return theme.colors.warning;
      case 'Aktivt':
        return theme.colors.success;
      case 'Inaktivt':
        return theme.colors.error;
      case 'Avstängt':
        return theme.colors.error;
      case 'Ogiltigt':
        return theme.colors.error;
      case 'Annat':
        return theme.colors.error;
      default:
        return theme.colors.warning;
    }
  }};

  ${({ $hasComment }) =>
    $hasComment &&
    css`
      &:after {
        content: '*';
        color: ${({ theme }) => theme.colors.black};
        display: block;
        position: absolute;
        font-size: 24px;
        top: 50%;
        transform: translate(0, -50%);
        right: -16px;
        border-radius: 50%;
        margin-left: 8px;
      }
    `}
`;

const WorkRegisterTag = styled.div<{
  $type: 'Avvikelse' | 'Inga avvikelser';
  $hasComment: boolean;
}>`
  position: relative;
  width: fit-content;
  font-size: ${({ $type }) => ($type === 'Avvikelse' ? '14px' : '18px')};
  padding: 8px;
  border-radius: 4px;
  color: ${({ theme, $type }) => {
    switch ($type) {
      case 'Avvikelse':
        return theme.colors.white;
      case 'Inga avvikelser':
        return theme.colors.black;
      default:
        return theme.colors.black;
    }
  }};
  background-color: ${({ theme, $type }) => {
    switch ($type) {
      case 'Avvikelse':
        return theme.colors.error;
      case 'Inga avvikelser':
        return 'transparent';
      default:
        return 'transparent';
    }
  }};

  ${({ $hasComment }) =>
    $hasComment &&
    css`
      &:after {
        content: '*';
        color: ${({ theme }) => theme.colors.black};
        display: block;
        position: absolute;
        font-size: 24px;
        top: 50%;
        transform: translate(0, -50%);
        right: -16px;
        border-radius: 50%;
        margin-left: 8px;
      }
    `}
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin: 0 8px;
  transition: transform 300ms;
`;

const TriggerWrapper = styled.td`
  vertical-align: middle;
`;

const Trigger = styled.button<{ $isExpanded: boolean }>`
  all: unset;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${AnimatedIcon} {
    transform: rotate(
      ${({ $isExpanded }) => ($isExpanded ? '180deg' : '0deg')}
    );
  }
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;
const Input = styled.input`
  display: none;
`;

const HoverContainer = styled.div``;

const HoverText = styled.p`
  position: absolute;
  zindex: 100;
  left: 30px;
  background-color: ${({ theme }) => theme.colors.gray};
  padding: 10px;
`;

export const S = {
  ...UIElements,
  HoverContainer,
  TableContainer,
  Table,
  THead,
  THeadRow,
  THeadCell,
  TBody,
  TBodyRow,
  TBodyCell,
  ID06Tag,
  WorkRegisterTag,
  DeleteDataCell,
  AnimatedIcon,
  TriggerWrapper,
  Trigger,
  PaginationContainer,
  Input,
  HoverText,
};
