import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

type Props = React.PropsWithChildren<{
  isOpen: boolean;
}>;

export const Provider: React.FC<Props> = (props) => {
  return (
    <AlertDialog.Root open={props.isOpen}>{props.children}</AlertDialog.Root>
  );
};
