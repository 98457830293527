import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  max-width: 336px;
  gap: 16px;
`;

export const S = {
  ...UIElements,
  ButtonContainer,
};
