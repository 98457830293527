import React, { useState } from 'react';
import { Dialog } from '../../../../components/Dialog';
import { useNavigate } from 'react-router-dom';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';

import { S } from './CancelQuestion.styles';

type Props = {
  hasBeenEdited: boolean;
};

export const CancelQuestion: React.FC<Props> = (props) => {
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();

  return (
    <Dialog.Provider isOpen={openAlert}>
      <Dialog.Trigger>
        <S.Button
          $outlined
          onClick={() =>
            props.hasBeenEdited ? setOpenAlert(true) : navigate('/faq')
          }
        >
          Avbryt
        </S.Button>
      </Dialog.Trigger>

      <Dialog.Base onClose={() => setOpenAlert(false)}>
        <img src={Images.Delete} width={295} height={215} />

        <Dialog.Title>
          <S.H5>Är du säker på att du vill gå tillbaka?</S.H5>
        </Dialog.Title>

        <Dialog.Description>
          <S.H6>Frågan kommer inte att sparas.</S.H6>
        </Dialog.Description>

        <S.ButtonContainer>
          <Dialog.CancelButton>
            <S.Button $outlined onClick={() => setOpenAlert(false)}>
              Avbryt
            </S.Button>
          </Dialog.CancelButton>

          <Dialog.Button>
            <S.Button
              onClick={(e) => {
                e.stopPropagation();
                navigate('/faq');
              }}
            >
              Gå tillbaka
            </S.Button>
          </Dialog.Button>
        </S.ButtonContainer>
      </Dialog.Base>
    </Dialog.Provider>
  );
};
