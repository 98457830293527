import styled from 'styled-components';
import * as Accordion from '@radix-ui/react-accordion';
import { UIElements } from '@ansvar-sakerhet/ui';

const FlexRow = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin: 0 8px;
  transition: transform 300ms;
`;

const Trigger = styled(Accordion.Trigger)`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  border: none;
  border-bottom: 1px solid #cbcbcb;
  background-color: transparent;
  &[data-state='open'] {
    ${AnimatedIcon} {
      transform: rotate(180deg);
    }
  }
`;

export const S = {
  ...UIElements,
  AnimatedIcon,
  Trigger,
  FlexRow,
};
