import React, { useMemo, useState } from 'react';
import { S } from './CustomerUsersList.styles';
import { CustomerUsersTable } from './component/CustomerUsersTable';
import { Loading } from '../../../../../../../../../components/Loading';
import { useGetAllCustomerUsersOnClient } from '../../../../../../../../../traits/api/customerUsers/useGetAllCustomerUsersOnClient';
import { AddCustomerUserModal } from './component/AddCustomerUserModal';
import { Pagination } from '../../../../../../../../../components/Pagination';

type Props = {
  clientId: string;
};

export const CustomerUsersList = ({ clientId }: Props) => {
  const [page, setPage] = useState(0);
  const entitiesPerPage = 10;
  const pagination = useMemo(
    () => ({
      skip: entitiesPerPage * page,
      take: entitiesPerPage,
    }),
    [page]
  );

  const {
    data: customerUsers,
    isLoading,
    count,
  } = useGetAllCustomerUsersOnClient(
    clientId,
    pagination.take,
    pagination.skip
  );
  const [modalOpen, setModalOpen] = useState(false);

  if (isLoading || !customerUsers) {
    return <Loading />;
  }

  return (
    <>
      <S.Flex $justify="end" $align="center">
        <S.Button onClick={() => setModalOpen(true)}>
          <S.Icon src="Plus" /> Skapa ny kund-användare
        </S.Button>
      </S.Flex>
      <CustomerUsersTable customerUsers={customerUsers} />
      <S.Margin $top="m" />
      <Pagination
        numberOfPages={Math.ceil(count / entitiesPerPage)}
        page={page}
        setPage={setPage}
      />
      <AddCustomerUserModal
        chosenClientId={clientId}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};
