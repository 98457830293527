import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import MDEditor, { ExecuteState, commands } from '@uiw/react-md-editor';
import { usePostFile } from '../../../../traits/api/images/usePostFile';
import { toast } from '../../../../components/Toast';
import { UploadFileResponse } from '@ansvar-sakerhet/api-client';

import { S } from './MarkDownEditor.styles';

type Props = {
  value: string;
  setNewImages: Dispatch<SetStateAction<UploadFileResponse[]>>;
  onChange: (value: string) => void;
  onFocus: () => void;
};

export const MarkDownEditor: React.FC<Props> = (props) => {
  const [selection, setSelection] = useState({ start: 0, end: 0 });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const uploadFile = usePostFile();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files) return;
      const file = event.target.files[0];
      const res = await uploadFile.mutateAsync(file);
      props.setNewImages((prev) => [...prev, res]);

      const url = res.url;
      if (url) {
        const str = `![image](${url})`;
        const newValue =
          props.value.substring(0, selection.start) +
          str +
          props.value.substring(selection.end);

        props.onChange(newValue);
      }
    } catch (err) {
      toast.error('Något gick fel när bilden skulle laddas upp');
    } finally {
      if (inputRef.current) inputRef.current.value = '';
    }
  };

  const image = {
    name: 'image',
    keyCommand: 'image',
    buttonProps: { 'aria-label': 'Insert image' },
    icon: (
      <svg width="12" height="12" viewBox="0 0 20 20">
        <path
          fill="currentColor"
          d="M15 9c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-7H1c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h18c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm-1 13l-6-5-2 2-4-5-4 8V4h16v11z"
        />
      </svg>
    ),
    execute: (state: ExecuteState) => {
      setSelection(state.selection);
      inputRef.current?.click();
    },
  };

  const customCommands = [
    commands.bold,
    commands.italic,
    commands.group(
      [
        commands.title1,
        commands.title2,
        commands.title3,
        commands.title4,
        commands.title5,
        commands.title6,
      ],
      {
        name: 'title',
        groupName: 'title',
        buttonProps: { 'aria-label': 'Insert title' },
      }
    ),
    commands.link,
    commands.unorderedListCommand,
    commands.orderedListCommand,
    image,
  ];

  return (
    <>
      <MDEditor
        height={350}
        onFocus={props.onFocus}
        commands={customCommands}
        value={props.value}
        textareaProps={{
          placeholder: 'Skriv svaret här...',
        }}
        preview="live"
        onChange={(value) => {
          if (value !== undefined) props.onChange(value);
        }}
      />

      <S.Input type="file" ref={inputRef} onChange={handleFileChange} />
    </>
  );
};
