import { Dialog as Base } from './Dialog';
import { CancelButton } from './components/CancelButton';
import { Description } from './components/Description';
import { Title } from './components/Title';
import { Button } from './components/Button';
import { Provider } from './components/Provider';
import { Trigger } from './components/Trigger';

export const Dialog = {
  Base,
  CancelButton,
  Description,
  Title,
  Button,
  Provider,
  Trigger,
};
