import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { faqKeys } from './faq.key';
import { FindAllFaqsResponse } from '@ansvar-sakerhet/api-client';

export function useGetFaq(page: number) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getFaq = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    const res = await api.faqsFindAll('', take, skip);

    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const getNextPageParam = (
    lastPage: FindAllFaqsResponse[],
    pages: FindAllFaqsResponse[][]
  ) => {
    if (lastPage.length < 20) return undefined;
    return pages.length + 1;
  };

  const query = useQuery({
    queryKey: faqKeys.findMany({ page }),
    queryFn: getFaq,
    keepPreviousData: true,
    getNextPageParam,
  });

  return {
    ...query,
    count,
  };
}
