import React, { useState } from 'react';
import { useGetFaq } from '../../../../traits/api/faq/useGetFaq';
import { Pagination } from '../../../../components/Pagination';
import { FaqListItem } from '../../components/FaqListItem';
import { Header } from '../../components/Header';

import { S } from './FaqOverview.styles';

export const FaqOverview: React.FC = () => {
  const [page, setPage] = useState(0);

  const faq = useGetFaq(page);

  return (
    <S.FaqOverview>
      <S.FaqInner>
        <div>
          <Header />

          <ul>
            {faq.data?.map((item) => (
              <FaqListItem
                key={item.faqId}
                faqId={item.faqId || ''}
                faqQuestion={item.question}
              />
            ))}
          </ul>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          numberOfPages={Math.ceil(faq.count / 20)}
        />
      </S.FaqInner>
    </S.FaqOverview>
  );
};
