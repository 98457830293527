import React from 'react';
import { useStatisticsTableData } from '../../hooks/useStatisticsTableData';
import { FindAllControlStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { useTable } from 'react-table';

import { S } from './StatisticsTable.styles';

type Props = {
  statistics: FindAllControlStatisticsResponse;
};

export const StatisticsTable: React.FC<Props> = (props) => {
  const data = useStatisticsTableData(props.statistics);
  const table = useTable(data);

  return (
    <S.TWrapper>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.TBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <S.TBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.TBodyCell>
                  );
                })}
              </S.TBodyRow>
            );
          })}
        </S.TBody>
      </S.Table>
    </S.TWrapper>
  );
};
