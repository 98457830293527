import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Clients = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ClientDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Container = styled.div`
  max-width: 850px;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

export const S = {
  ...UIElements,
  Container,
  Clients,
  ClientDescription,
  Button,
};
