import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateNewsDto, CreateNewsResponse } from '@ansvar-sakerhet/api-client';
import { useApi } from '../useApi';
import { newsKeys } from './news.key';
import { toast } from '../../../components/Toast';

type Options = {
  onCreateNewsSuccess?: (data: CreateNewsResponse) => void;
  onCreateNewsError?: (error: unknown) => void;
};

export function useCreateNews(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const createNews = async (data: CreateNewsDto) => {
    const api = await getApi();

    const res = await api.newsCreate({
      header: data.header,
      content: data.content,
    });

    return res.data;
  };

  const onSuccess = (data: CreateNewsResponse) => {
    toast.success('Frågan har lagts till');
    queryClient.invalidateQueries({ queryKey: newsKeys.all });
    options?.onCreateNewsSuccess?.(data);
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel, frågan gick inte att lägga till i FAQ');

    options?.onCreateNewsError?.(error);
  };

  const mutation = useMutation(createNews, {
    onSuccess,
    onError,
  });

  return mutation;
}
