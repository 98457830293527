import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const FaqEditor = styled.div`
  display: grid;
  width: 100%;
  min-height: calc(100vh - 68px);
  place-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const FaqInner = styled.section`
  width: 850px;
  min-height: calc(100vh - 154px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Footer = styled.section`
  display: flex;
  gap: 24px;
  flex-grow: 1;
`;

export const S = {
  ...UIElements,
  FaqEditor,
  FaqInner,
  Footer,
  Body,
};
