import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  background-color: #fbfbfc;
  font-size: 18px;
  &:focus {
    border: 1px solid #023e52;
  }
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

export const S = {
  ...UIElements,
  Wrapper,
  Input,
  Label,
};
