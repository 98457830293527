import { useMemo } from 'react';
import { CustomerUser } from '@ansvar-sakerhet/api-client';

export const useCustomerUsersTableData = (customerUsers: CustomerUser[]) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'firstName',
      },
      {
        Header: 'Efternamn',
        accessor: 'lastName',
      },
      {
        Header: 'Externt id',
        accessor: 'externalId',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return customerUsers.map((user) => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        externalId: user.externalId,
      };
    });
  }, [customerUsers]);

  return { columns, data };
};
