import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const Container = styled.div`
  & > * {
    margin-top: 1rem;
  }
`;

export const S = {
  ...UIElements,
  Container,
};
