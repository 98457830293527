import { useMemo } from 'react';
import { FindAllCompanyStatisticsResponse } from '@ansvar-sakerhet/api-client';

export type SortDeviations =
  | 'none'
  | 'id_06_card'
  | 'safe_construction'
  | 'no_id'
  | 'no_safe_equipment'
  | 'not_logged_in'
  | 'no_a1';

export const useDeviationsTableData = (
  statistics: FindAllCompanyStatisticsResponse[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Företag',
        accessor: 'companyName',
      },
      {
        Header: 'Orgnr',
        accessor: 'companyRegistrationNumber',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return statistics.map((deviation) => {
      return {
        companyName: deviation.company.name,
        companyRegistrationNumber: deviation.company.registrationNumber,
        companyNationality: deviation.company.nationality,
        numberOfCheckedInPersonnel: deviation.numberOfCheckedInPersonnel,
        numberOfAdditionalPersonnel:
          deviation.numberOfPersonnel - deviation.numberOfCheckedInPersonnel,
        numberOfControlledPersonnel: deviation.numberOfControlledPersonnel,
        numberOfNoSafePersonnel:
          deviation.numberOfPersonnelWithoutSafeConstructionTraining,
        numberOfNoId: deviation.numberOfPersonnelWithInvalidIdentification,
        numerOfId06Card: deviation.numberOfDeviatingId06Cards,
        numberOfPersonnelWithoutValidA1Certificate:
          deviation.numberOfPersonnelWithoutValidA1Certificate,
        numberOfWithoutSafteyEquipment:
          deviation.numberOfPersonnelWithoutSafetyEquipment,
      } as const;
    });
  }, [statistics]);

  return { columns, data };
};
