import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  CreateFaqDtoFaqCategoryEnum,
  FindOneFaqResponse,
} from '@ansvar-sakerhet/api-client';
import { useEffect } from 'react';

export const editFaqForm = z.object({
  faqCategory: z.nativeEnum(CreateFaqDtoFaqCategoryEnum),
  question: z.string().min(1, 'Fråga måste anges'),
  answer: z.string().min(1, 'Svar måste anges'),
});

export type editFaqFormValues = z.infer<typeof editFaqForm>;

export const useEditFaqForm = (faq: FindOneFaqResponse | undefined) => {
  const methods = useForm<editFaqFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(editFaqForm),
    defaultValues: {
      faqCategory: faq?.faqCategory,
      question: faq?.question,
      answer: faq?.answer,
    },
  });

  useEffect(() => {
    if (faq) {
      methods.setValue('faqCategory', faq.faqCategory);
      methods.setValue('question', faq.question);
      methods.setValue('answer', faq.answer);
    }
  }, [faq]);

  return methods;
};
