import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { newsKeys } from './news.key';

export function useGetOneNews(newsId: string) {
  const getApi = useApi();

  const getNews = async () => {
    const api = await getApi();

    const res = await api.newsFindOne(newsId);
    return res.data;
  };

  const query = useQuery({
    queryKey: newsKeys.findOne(newsId),
    queryFn: getNews,
  });

  return query;
}
