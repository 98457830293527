import React, { useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useGetPersonnel } from '../../../../../../traits/api/workRegisters/useGetPersonnel';
import { useEditPersonnelForm } from '../../hooks/useEditPersonnelForm';
import { Input } from '../Input';
import { RadioInput } from '../RadioInput';
import { Controller } from 'react-hook-form';
import { useControl } from '../../../../components/ControlLayout';
import { useGetPersonnelFiles } from '../../../../../../traits/api/workRegisters/useGetPersonnelFiles';
import { useGetPersonnelID06Competences } from '../../../../../../traits/api/workRegisters/useGetPersonnelID06Competences';
import { Loading } from '../../../../../../components/Loading';
import { Error } from '../../../../../../components/Error';
import { toast } from '../../../../../../components/Toast';
import { useDeletePersonnelFile } from '../../../../../../traits/api/workRegisters/useDeleteFile';
import { useUpsertQuestionnaire } from '../../../../../../traits/api/workRegisters/useUpsertQuestionnaire';
import { useUpdateFileComment } from '../../../../../../traits/api/workRegisters/useUpdateFileComment';
import { useUpdateID06Comment } from '../../../../../../traits/api/workRegisters/useUpdateID06Comment';

import { S } from './EditPersonnelModal.styles';
import { UpsertPersonnelQuestionnaireDtoTypeOfIdEnum } from '@ansvar-sakerhet/api-client';

type Props = {
  personnelId: string;
  onCancel: () => void;
};

export const EditPersonnelModal: React.FC<Props> = (props) => {
  const { data } = useControl();
  const personnel = useGetPersonnel(props.personnelId, data.controlId);
  const files = useGetPersonnelFiles(props.personnelId, data.controlId);
  const deleteFile = useDeletePersonnelFile(props.personnelId, data.controlId);

  const competences = useGetPersonnelID06Competences(
    props.personnelId,
    data.controlId
  );

  const updateQuestionnaire = useUpsertQuestionnaire(
    props.personnelId,
    data.controlId
  );

  const updateFileComment = useUpdateFileComment(
    props.personnelId,
    data.controlId
  );

  const updateID06Comment = useUpdateID06Comment(
    props.personnelId,
    data.controlId
  );

  const { handleSubmit, setValue, watch, register, control, getValues, reset } =
    useEditPersonnelForm();

  const activeFiles = files.data?.filter(
    (file) => !watch('deletedFiles')?.includes(file.personnelFileId)
  );

  const refetchData = () => {
    personnel.refetch();
    files.refetch();
    competences.refetch();
  };

  const onErrors = () => {
    toast.error('Formuläret är inte korrekt ifyllt');
  };

  const handleSave = async () => {
    const {
      id06Comment,
      hasIdCard,
      hasSafetyEquipment,
      canShowValidA1Certificate,
      canShowValidWorkPermit,
      hasSignsOfIntoxication,
      questionnaireComment,
      deletedFiles,
      files,
      typeOfId,
    } = watch();

    if (deletedFiles?.length) {
      await Promise.all(
        deletedFiles.map((fileId) =>
          deleteFile.mutateAsync({ personnelFileId: fileId })
        )
      );
    }

    try {
      if (files?.length) {
        await Promise.all(
          files.map((file) =>
            updateFileComment.mutateAsync({
              personnelFileId: file.personnelFileId,
              data: {
                comment: file.comment,
              },
            })
          )
        );
      }

      if (id06Comment !== personnel.data?.id06Card?.comment) {
        await updateID06Comment.mutateAsync({
          comment: id06Comment,
        });
      }

      await updateQuestionnaire.mutateAsync({
        hasIdCard,
        hasSafetyEquipment,
        canShowValidA1Certificate,
        canShowValidWorkPermit,
        hasSignsOfIntoxication,
        typeOfId,
        comment: questionnaireComment,
      });

      toast.success('Ändringarna har sparats');
      props.onCancel();
    } catch (e) {
      toast.error('Något gick fel, gick inte att spara ändringarna');
    }
  };

  useEffect(() => {
    if (!personnel.data) return;

    const { questionnaire, id06Card } = personnel.data;

    const filesComment = files.data?.map((file) => ({
      personnelFileId: file.personnelFileId,
      comment: file.comment ?? '',
    }));

    reset({
      deletedFiles: [],
      files: filesComment ?? [],
      id06Comment: id06Card?.comment ?? undefined,
      hasIdCard: questionnaire?.hasIdCard,
      hasSafetyEquipment: questionnaire?.hasSafetyEquipment,
      canShowValidA1Certificate: questionnaire?.canShowValidA1Certificate,
      canShowValidWorkPermit: questionnaire?.canShowValidWorkPermit,
      hasSignsOfIntoxication: questionnaire?.hasSignsOfIntoxication,
      questionnaireComment: questionnaire?.comment ?? undefined,
      typeOfId: questionnaire?.typeOfId ?? undefined,
    });
  }, [personnel.data, files.data, competences.data]);

  return (
    <Dialog.Portal>
      <S.Overlay>
        <S.Content>
          <S.ContentHeader>
            <Dialog.Title asChild>
              <S.H4>
                Redigera{' '}
                {personnel.data?.firstName + ' ' + personnel.data?.lastName}
              </S.H4>
            </Dialog.Title>

            <S.CloseButton asChild onClick={props.onCancel}>
              <S.Icon src="Close" width={20} height={20} />
            </S.CloseButton>
          </S.ContentHeader>
          {(personnel.isLoading ||
            files.isLoading ||
            competences.isLoading) && <Loading />}

          {(personnel.isError || files.isError || competences.isError) && (
            <Error refetch={refetchData} />
          )}

          {personnel.isSuccess && files.isSuccess && competences.isSuccess && (
            <form onSubmit={handleSubmit(handleSave, onErrors)}>
              {personnel.data?.id06Card && (
                <S.FieldSet>
                  <S.H5>ID06</S.H5>

                  <S.FlexView>
                    <Input label="Anteckning" {...register('id06Comment')} />
                  </S.FlexView>
                </S.FieldSet>
              )}

              <S.FieldSet>
                <S.H5>Frågeformulär</S.H5>

                <RadioInput
                  state={watch('hasIdCard')}
                  question="Finns ID handling?"
                  control={control}
                  name="hasIdCard"
                  options={[
                    { label: 'Ja', value: 'Yes' },
                    { label: 'Nej', value: 'No' },
                    { label: 'Oklart', value: 'Unclear' },
                  ]}
                />

                <RadioInput
                  state={watch('typeOfId')}
                  question="Typ av ID handling"
                  control={control}
                  name="typeOfId"
                  options={[
                    {
                      label: 'Pass',
                      value:
                        UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.Passport,
                    },
                    {
                      label: 'Nationellt',
                      value:
                        UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.NationalId,
                    },
                    {
                      label: 'Körkort',
                      value:
                        UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.DriverLicense,
                    },
                    {
                      label: 'SKV Id',
                      value: UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.Skvid,
                    },
                  ]}
                />

                <RadioInput
                  state={watch('hasSafetyEquipment')}
                  question="Är personlig säkerhetsutrustning ok?"
                  control={control}
                  name="hasSafetyEquipment"
                  options={[
                    { label: 'Ja', value: 'Yes' },
                    { label: 'Nej', value: 'No' },
                  ]}
                />

                <RadioInput
                  state={watch('canShowValidA1Certificate')}
                  question="Kan uppvisa giltigt A1-intyg?"
                  control={control}
                  name="canShowValidA1Certificate"
                  options={[
                    {
                      label: 'Ja',
                      value: 'Yes',
                    },
                    {
                      label: 'Nej',
                      value: 'No',
                    },
                    {
                      label: 'Oklart',
                      value: 'Unclear',
                    },
                    {
                      label: 'Behövs ej',
                      value: 'NotApplicable',
                    },
                  ]}
                />

                <RadioInput
                  state={watch('canShowValidWorkPermit')}
                  question="Kan uppvisa giltigt arbetstillstånd?"
                  control={control}
                  name="canShowValidWorkPermit"
                  options={[
                    {
                      label: 'Ja',
                      value: 'Yes',
                    },
                    {
                      label: 'Nej',
                      value: 'No',
                    },
                    {
                      label: 'Oklart',
                      value: 'Unclear',
                    },
                    {
                      label: 'Behövs ej',
                      value: 'NotApplicable',
                    },
                  ]}
                />

                <RadioInput
                  state={watch('hasSignsOfIntoxication')}
                  question="Finns tecken på onykterhet/drogpåverkan?"
                  control={control}
                  name="hasSignsOfIntoxication"
                  options={[
                    {
                      label: 'Ja',
                      value: 'Yes',
                    },
                    {
                      label: 'Nej',
                      value: 'No',
                    },
                    {
                      label: 'Oklart',
                      value: 'Unclear',
                    },
                  ]}
                />

                <Input
                  label="Kommentar"
                  {...register('questionnaireComment')}
                />
              </S.FieldSet>

              {activeFiles && activeFiles.length > 0 && (
                <S.FieldSet>
                  <S.H5>Bilder</S.H5>

                  {activeFiles?.map((file) => (
                    <Controller
                      key={file.personnelFileId}
                      name="files"
                      control={control}
                      render={({ field }) => (
                        <S.ImageContainer>
                          <S.RelativeContainer>
                            <S.SmallText>Yrkesarbetare</S.SmallText>

                            <S.Image src={file.file.url} />

                            <S.DeleteButton
                              id={file.personnelFileId}
                              onClick={(e) => {
                                e.preventDefault();
                                setValue('deletedFiles', [
                                  ...getValues('deletedFiles'),
                                  e.currentTarget.id,
                                ]);
                                setValue(
                                  'files',
                                  getValues('files').filter(
                                    (value) =>
                                      value.personnelFileId !==
                                      e.currentTarget.id
                                  )
                                );
                              }}
                            >
                              <S.Icon
                                src="Close"
                                color="white"
                                width={22}
                                height={22}
                              />
                            </S.DeleteButton>
                          </S.RelativeContainer>

                          <S.TextInputWrapper>
                            <S.B3>Kommentar</S.B3>
                            <S.TextInput
                              value={
                                field.value?.find(
                                  (value) =>
                                    value.personnelFileId ===
                                    file.personnelFileId
                                )?.comment ?? ''
                              }
                              onChange={(e) => {
                                setValue(
                                  'files',
                                  field.value.map((value) =>
                                    value.personnelFileId ===
                                    file.personnelFileId
                                      ? {
                                          ...value,
                                          comment: e.target.value,
                                        }
                                      : value
                                  )
                                );
                              }}
                            />
                          </S.TextInputWrapper>
                        </S.ImageContainer>
                      )}
                    />
                  ))}
                </S.FieldSet>
              )}

              <S.ContentFooter>
                <Dialog.Close asChild onClick={props.onCancel}>
                  <S.Button $outlined>Avbryt</S.Button>
                </Dialog.Close>

                <S.Button type="submit">Spara</S.Button>
              </S.ContentFooter>
            </form>
          )}
        </S.Content>
      </S.Overlay>
    </Dialog.Portal>
  );
};
