import React from 'react';
import { FindAllControlFilesResponse } from '@ansvar-sakerhet/api-client';
import { useResultFormContext } from '../../../../components/ControlLayout/ControlLayout';

import { S } from './Photos.styles';

type Props = {
  data: FindAllControlFilesResponse[] | undefined;
};

type PhotoProps = {
  file: FindAllControlFilesResponse;
};

const Photo: React.FC<PhotoProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { getValues, setValue } = useResultFormContext();

  return (
    <S.RelativeWrapper
      key={props.file.controlFileId}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      {!isOpen && (
        <>
          <S.Image src={props.file.file.url} alt={props.file.type} />
          <S.DeleteButton
            id={props.file.controlFileId}
            onClick={(e) => {
              setValue('deletedFiles', [
                ...getValues('deletedFiles'),
                e.currentTarget.id,
              ]);
            }}
          >
            <S.Icon src="Close" color="white" width={22} height={22} />
          </S.DeleteButton>
          <S.B2>{props.file.comment}</S.B2>
        </>
      )}

      {isOpen && (
        <S.ImageModal
          src={props.file.file.url}
          alt={props.file.type}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      )}
    </S.RelativeWrapper>
  );
};

export const Photos: React.FC<Props> = (props) => {
  return (
    <div>
      <S.Label>Foton</S.Label>
      <S.FlexRow>
        {props.data?.map((file) => {
          return <Photo file={file} key={file.fileId} />;
        })}
      </S.FlexRow>
    </div>
  );
};
