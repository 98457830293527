import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { controlKeys } from './controls.key';

export type SortControls =
  | 'clientName_ASC'
  | 'projectName_ASC'
  | 'date_ASC'
  | 'date_DESC'
  | 'inspectionId_ASC';

export function useGetControls(
  page: number,
  sort: SortControls,
  isCompleted: boolean
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getControls = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    const res = await api.controlsFindAll(take, skip, sort, isCompleted);
    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: controlKeys.findMany({ page, sort, isCompleted }),
    queryFn: getControls,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
