import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import * as Dialog from '@radix-ui/react-dialog';

const Trigger = styled(Dialog.Trigger)`
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  padding: 0px;
  background: none;
  cursor: pointer;
`;

export const S = {
  ...UIElements,
  Trigger,
};
