import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { controlKeys } from './controls.key';

export function useGetControlFiles(inspectionId: string, controlId: string) {
  const getApi = useApi();

  const getFiles = async () => {
    const api = await getApi();

    const res = await api.controlsFindOneFiles(controlId);
    return res.data;
  };

  const query = useQuery({
    queryKey: controlKeys.findFiles(inspectionId),
    queryFn: getFiles,
  });

  return query;
}
