import { useMemo } from 'react';
import {
  BackgroundControlWithFilesRiskEnum,
  FindAllCompaniesResponse,
} from '@ansvar-sakerhet/api-client';

export const useCompaniesTableData = (
  companies: FindAllCompaniesResponse[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
      },
      {
        Header: 'Org nr',
        accessor: 'registrationNumber',
      },
      {
        Header: 'Bortvalt',
        accessor: 'optedOut',
      },
      {
        Header: 'Riskbedömning',
        accessor: 'latestBackgroundControlStatus',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return companies.map((company) => {
      return {
        name: company.name,
        registrationNumber: company.registrationNumber,
        optedOut:
          optedOutTranslation(getLatestBackgroundControl(company)?.optedOut) ??
          '-',
        latestBackgroundControlStatus:
          AcceptanceTranslation[getLatestBackgroundControl(company)?.risk] ??
          '-',
        company: company,
      } as const;
    });
  }, [companies]);

  return { columns, data };
};

function getLatestBackgroundControl(company: FindAllCompaniesResponse) {
  return company.backgroundControls.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}

const optedOutTranslation = (optedOut: boolean) => (optedOut ? 'Ja' : 'Nej');

const AcceptanceTranslation: Record<
  BackgroundControlWithFilesRiskEnum,
  string
> = {
  NoRisk: 'Inga risker identifierade.',
  LesserRisk: 'Avvikelser identifierade, OK att samarbeta.',
  MediumHighRisk: 'Avvikelser identifierade, åtgärder föreslås.',
  HighRisk:
    'Väsentliga risker. Avrådan från att anlita företaget eller åtgärda avvikelser.',
};
