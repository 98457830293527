import React from 'react';
import { S } from './WorkRegisterTable.styles';
import { FindAllPersonnelResponse } from '@ansvar-sakerhet/api-client';
import { useExpanded, useTable } from 'react-table';
import { useWorkRegisterTableData } from './hooks/useWorkRegisterTableData';
import { Pagination } from '../../../../../../components/Pagination';
import { useControl } from '../../../../components/ControlLayout';
import { useWorkRegisterUpload } from '../../../ContactInfo/hooks/useWorkRegisterUpload';
import { useDownloadCSV } from '../../../ContactInfo/hooks/useDownloadCSV';
import { WorkRegisterTableRow } from './components/WorkRegisterTableRow';

type Props = {
  data: FindAllPersonnelResponse[];
  page: number;
  setPage: (page: number) => void;
  numberOfPages: number;
  loading: boolean;
  showExtra: boolean;
};

export const WorkRegisterTable: React.FC<Props> = (props) => {
  const data = useWorkRegisterTableData(props.data);
  const table = useTable(data, useExpanded);
  const { data: control } = useControl();
  const workRegisterUpload = useWorkRegisterUpload();
  const csv = useDownloadCSV();

  const [hoverEPL, setHoverEPL] = React.useState(false);
  const [hoverBolt, setHoverBolt] = React.useState(false);

  return (
    <S.TableContainer>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => {
            return (
              <S.THeadRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => {
                  if (
                    !props.showExtra &&
                    (column.id === 'id06' || column.id === 'workRegister')
                  ) {
                    return null;
                  }
                  return (
                    <S.THeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps().key}
                    >
                      {column.render('Header')}
                    </S.THeadCell>
                  );
                })}
              </S.THeadRow>
            );
          })}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            return (
              <WorkRegisterTableRow
                key={row.id}
                row={row}
                table={table}
                control={control}
                showExtra={props.showExtra}
                setHoverEPL={setHoverEPL}
                hoverEPL={hoverEPL}
                setHoverBolt={setHoverBolt}
                hoverBolt={hoverBolt}
              />
            );
          })}
        </S.TBody>
      </S.Table>
      <S.Button
        $outlined
        onClick={() => workRegisterUpload.handleUploadClick()}
      >
        Ladda upp personalliggare ({control.workRegister.numUploads})
      </S.Button>
      <S.Button
        onClick={() => {
          csv.download().then((file) => {
            const blob = new Blob([file], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'personnel.csv';
            link.href = url;
            link.click();
          });
        }}
      >
        Ladda ner csv för id06
      </S.Button>
      <S.Input
        type="file"
        ref={workRegisterUpload.inputRef}
        onChange={workRegisterUpload.handleFileChange}
      />
      <S.PaginationContainer>
        <Pagination
          page={props.page}
          setPage={props.setPage}
          numberOfPages={props.numberOfPages}
        />
      </S.PaginationContainer>
    </S.TableContainer>
  );
};
