import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { controlKeys } from './controls.key';

type Options = {
  onDeleteControlSuccess?: () => void;
  onDeleteControlError?: (error: unknown) => void;
};

export function useDeleteGdprData(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const deleteGdprData = async (controlId: string) => {
    const api = await getApi();

    const res = await api.controlsDeleteGdpr(controlId);
    return res.data;
  };

  const onSuccess = () => {
    toast.success('Data raderad');
    queryClient.invalidateQueries({ queryKey: controlKeys.all });
    options?.onDeleteControlSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Error, data gick inte att radera');
    options?.onDeleteControlError?.(error);
  };

  const mutation = useMutation(deleteGdprData, {
    onSuccess,
    onError,
  });

  return mutation;
}
