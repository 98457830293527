import styled from 'styled-components';

const MainContainer = styled.main`
  flex: 1;
  position: relative;
  min-width: 1444px;
`;

export const S = {
  MainContainer,
};
