import { FindAllClientsResponse } from '@ansvar-sakerhet/api-client';
import React from 'react';
import { S } from './ClientsTable.style';
import { useTable } from 'react-table';
import { useClientsTableData } from './hooks/useClientsTableData';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../../../../components/Pagination';

type Props = {
  clients: FindAllClientsResponse[];
  page: number;
  setPage: (page: number) => void;
  numberOfPages: number;
};

export const ClientsTable = ({
  clients,
  page,
  setPage,
  numberOfPages,
}: Props) => {
  const navigate = useNavigate();
  const data = useClientsTableData(clients);
  const table = useTable(data);

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.AltTHeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow
                {...row.getRowProps()}
                onClick={() => navigate(`/kunder/${row.original.clientId}`)}
                key={row.getRowProps().key}
              >
                {row.cells.map((cell) => {
                  return (
                    <S.AltTBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
      <S.PaginationContainer>
        <Pagination
          page={page}
          setPage={setPage}
          numberOfPages={numberOfPages}
        />
      </S.PaginationContainer>
    </S.TableContainer>
  );
};
