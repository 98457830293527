import React from 'react';
import { S } from './ControlsLayout.styles';
import { useUser } from '../../../../traits/hooks/useUser';

type Props = React.PropsWithChildren<{
  isCompleted: boolean;
  setIsCompleted: (isCompleted: boolean) => void;
}>;

export const ControlsLayout: React.FC<Props> = (props) => {
  const { user } = useUser();

  const setIsNotCompleted = () => {
    props.setIsCompleted(false);
  };

  const setIsCompleted = () => {
    props.setIsCompleted(true);
  };

  return (
    <React.Fragment>
      <S.ControlsHeaderContainer>
        <S.ControlsHeaderInner>
          <S.ControlsInfoContainer>
            <S.H2 $weight={600}>Välkommen {user.name}!</S.H2>
          </S.ControlsInfoContainer>

          <S.ControlsNavigationContainer>
            <S.NavLink
              $isActive={!props.isCompleted}
              onClick={setIsNotCompleted}
            >
              Pågående kontroller
            </S.NavLink>

            <S.NavLink $isActive={props.isCompleted} onClick={setIsCompleted}>
              Avslutade kontroller
            </S.NavLink>
          </S.ControlsNavigationContainer>
        </S.ControlsHeaderInner>
      </S.ControlsHeaderContainer>

      <S.ControlsBodyContainer>
        <S.ControlsBodyInner>{props.children}</S.ControlsBodyInner>
      </S.ControlsBodyContainer>
    </React.Fragment>
  );
};
