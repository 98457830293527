import React from 'react';
import { S } from './ControlsTable.styles';
import { FindAllControlsResponse } from '@ansvar-sakerhet/api-client';
import { useTable } from 'react-table';
import { useControlsTableData } from './hooks/useControlsTableData';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../../../../components/Pagination';
import { ControlsTableDeleteButton } from '../ControlsTableDeleteButton';

type Props = {
  data: FindAllControlsResponse[];
  page: number;
  setPage: (page: number) => void;
  numberOfPages: number;
};

export const ControlsTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const data = useControlsTableData(props.data);
  const table = useTable(data);

  const navigateToControl = (inspectionId: string) => {
    navigate(`${inspectionId}`);
  };

  return (
    <S.TableContainer>
      {table.data.map((row, index) => (
        <S.isRead key={row.inspectionId} $isRead={row.isRead} $index={index} />
      ))}

      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.TBodyRow
                {...row.getRowProps()}
                key={row.getRowProps().key}
                onClick={() => navigateToControl(row.original.inspectionId)}
              >
                {row.cells.map((cell) => {
                  return (
                    <S.TBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.TBodyCell>
                  );
                })}

                {!row.original.actions ? (
                  <ControlsTableDeleteButton
                    controlId={row.original.controlId}
                  />
                ) : (
                  <S.DeleteDataCell>
                    <S.B2 $color="error" $align="center" $weight={500}>
                      Data raderad
                    </S.B2>
                  </S.DeleteDataCell>
                )}
              </S.TBodyRow>
            );
          })}
        </S.TBody>
      </S.Table>

      <S.PaginationContainer>
        <Pagination
          page={props.page}
          setPage={props.setPage}
          numberOfPages={props.numberOfPages}
        />
      </S.PaginationContainer>
    </S.TableContainer>
  );
};
