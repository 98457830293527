import React from 'react';

import { S } from './Input.styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, ...rest } = props;
  return (
    <S.Wrapper>
      <S.Label>{label}</S.Label>
      <S.Input {...rest} ref={ref} />
    </S.Wrapper>
  );
});
