import React, { useState } from 'react';
import { S } from './Login.styles';
import { useAuth } from '../../../../traits/hooks/useAuth';
import { TemporaryLogoWithText } from '../../../../components/TemporaryLogoWithText';
import { useAppConfigContext } from '../../../../config/hooks/useAppConfig';
import { useLongPress } from 'use-long-press';

export const Login: React.FC = () => {
  const { login } = useAuth();
  const {
    setDevelopmentConfig,
    setProductionConfig,
    setStagingConfig,
    activeConfig,
  } = useAppConfigContext();

  const [showOptions, setShowOptions] = useState(false);
  const bind = useLongPress(() => setShowOptions((prev) => !prev), {
    threshold: 3000,
  });

  return (
    <S.LoginContainer>
      <S.BackgroundContainer>
        <S.BlockContainer>
          <S.H3 $align="center" $color="white">
            Välkommen till
          </S.H3>

          <TemporaryLogoWithText />

          <S.H4 $align="center" $color="white" $lineHeight={32}>
            det digitala verktyget för arbetsplatskontroller
          </S.H4>
        </S.BlockContainer>
      </S.BackgroundContainer>

      <S.ContentContainer>
        <S.H4 $align="center">Välkommen till Ansvar Säkerhet</S.H4>
        <S.Button onClick={login} {...bind()}>
          Logga in
        </S.Button>

        {showOptions && (
          <S.Flex $flexDir="column" $align="center" $gap={16}>
            <S.B3 $align="center">Current config: {activeConfig}</S.B3>
            <S.Flex $gap={16}>
              <button onClick={setProductionConfig}>Production</button>
              <button onClick={setStagingConfig}>Staging</button>
              <button onClick={setDevelopmentConfig}>Development</button>
            </S.Flex>
          </S.Flex>
        )}
      </S.ContentContainer>
    </S.LoginContainer>
  );
};
