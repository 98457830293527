import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const DeleteButton = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  opacity: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const S = {
  ...UIElements,
  DeleteButton,
};
