import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { UpdateClientDto } from '@ansvar-sakerhet/api-client';
import { toast } from '../../../components/Toast';

export const useUpdateClient = (clientId: string) => {
  const getApi = useApi();
  const mutationFn = async (data: UpdateClientDto) => {
    const api = await getApi();
    const res = await api.clientsUpdate(clientId, data);

    return res.data;
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess: () => toast.success('Klient uppdaterad'),
    onError: () =>
      toast.error('Ett fel uppstod när klienten skulle uppdateras'),
  });

  return mutation;
};
