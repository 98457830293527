import React from 'react';

import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { S } from './RadioInput.styles';

interface Props<TFormValues extends FieldValues> {
  question: string;
  state?: string;
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  onChange?: () => void;
  options: {
    label: string;
    value: string;
  }[];
}

export function RadioInput<TFormValues extends FieldValues>(
  props: Props<TFormValues>
) {
  return (
    <S.FlexView>
      <S.H6>{props.question}</S.H6>

      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <S.RadioRowContainer>
            {props.options.map((option, index) => (
              <S.RadioRowInner key={index}>
                <S.Label>{option.label}</S.Label>
                <S.Input
                  type="radio"
                  {...field}
                  onChange={(ev) => {
                    field.onChange(ev);
                    props.onChange?.();
                  }}
                  value={option.value}
                  checked={props.state === option.value}
                />
              </S.RadioRowInner>
            ))}
          </S.RadioRowContainer>
        )}
      />
    </S.FlexView>
  );
}
