import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox = styled.input`
  border: 2px solid white;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  appearance: none;
  outline: 1px solid #023e52;
  background-color: white;

  &:checked {
    background-color: #023e52;
  }
`;

export const S = {
  ...UIElements,
  CheckBoxWrapper,
  Checkbox,
};
