import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateWorkRegisterResponse } from '@ansvar-sakerhet/api-client';
import { isAxiosError } from 'axios';
import { useApi } from '../useApi';
import { controlKeys } from '../controls/controls.key';
import { toast } from '../../../components/Toast';

type Options = {
  onPostWorkRegisterSuccess?: (data: CreateWorkRegisterResponse) => void;
  onPostWorkRegisterError?: (error: unknown) => void;
};

export function usePostWorkRegister(controlId: string, options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const postWorkRegister = async ({ file }: { file: File }) => {
    const api = await getApi();

    const formData = new FormData();
    formData.append('file', file);

    const res = await api.workRegistersCreate(controlId, {
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res.data;
  };

  const onSuccess = (data: CreateWorkRegisterResponse) => {
    toast.success('Personalliggaren uppladdad');
    queryClient.invalidateQueries({ queryKey: controlKeys.all });
    options?.onPostWorkRegisterSuccess?.(data);
  };

  const onError = (error: unknown) => {
    if (isAxiosError(error)) {
      toast.error('Något gick fel, personalliggaren gick inte att spara');
    } else {
      toast.error('Något gick fel, personalliggaren gick inte att spara');
    }

    options?.onPostWorkRegisterError?.(error);
  };

  const mutation = useMutation(postWorkRegister, {
    onSuccess,
    onError,
  });

  return mutation;
}
