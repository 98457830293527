import React, { useState } from 'react';
import { DeleteDialog } from '../DeleteDialog';
import { Dialog } from '../../../../components/Dialog';

import { S } from './DeleteNews.styles';

type Props = {
  faqId: string;
};

export const DeleteNews: React.FC<Props> = (props) => {
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <Dialog.Provider isOpen={openAlert}>
      <Dialog.Trigger>
        <S.DeleteButton
          onClick={(e) => {
            e.stopPropagation();
            setOpenAlert(true);
          }}
        >
          <S.Icon src="Trash" width={24} height={24} color="secondary" />

          <S.B2 $top="xs" $color="secondary" $weight={500}>
            Radera nyhet
          </S.B2>
        </S.DeleteButton>
      </Dialog.Trigger>

      <DeleteDialog onClose={() => setOpenAlert(false)} faqId={props.faqId} />
    </Dialog.Provider>
  );
};
