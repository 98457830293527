import React from 'react';
import { useState } from 'react';
import { CreateBackgroundControlModal } from '../../../../../../../../CreateBackgroundControlModal';
import { S } from './CreateBGControlButton.styles';

export const CreateBackgroundControlButton = ({
  frameCompanyId,
}: {
  frameCompanyId: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <S.Button onClick={() => setOpen(true)}>
        <S.Icon src="Plus" /> Skapa bakgrundskontroll
      </S.Button>

      <CreateBackgroundControlModal
        open={open}
        frameCompanyId={frameCompanyId}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};
