import { FindAllClientsResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';

export const useClientsTableData = (clients: FindAllClientsResponse[]) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return clients.map((client) => {
      return {
        name: client.name,
        clientId: client.clientId,
      };
    });
  }, [clients]);

  return { columns, data };
};
