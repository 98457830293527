import React from 'react';
import { S } from './WorkRegisterSortDropdown.styles';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { SortPersonnel } from '../../../../../../traits/api/workRegisters/useGetAllPersonnel';

type Props = {
  sort: SortPersonnel;
  setSort: (value: SortPersonnel) => void;
};

export const WorkRegisterSortDropdown: React.FC<Props> = (props) => {
  const sortingOptions = [
    {
      label: 'Förnamn A-Ö',
      value: 'firstName_ASC',
    },
    {
      label: 'Förnamn Ö-A',
      value: 'firstName_DESC',
    },
    {
      label: 'Efternamn A-Ö',
      value: 'lastName_ASC',
    },
    {
      label: 'Efternamn Ö-A',
      value: 'lastName_DESC',
    },
    {
      label: 'Företag A-Ö',
      value: 'companyName_ASC',
    },
    {
      label: 'Företag Ö-A',
      value: 'companyName_DESC',
    },
    {
      label: 'Special',
      value: 'special',
    },
    {
      label: 'Saknar safe construction',
      value: 'no_safe',
    },
  ] as const;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <S.SortButton aria-label="Customise options">
          <S.H6 color="secondary">Sortera</S.H6>
          <S.Icon src="Sort" color="secondary" />
        </S.SortButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <S.DropDown sideOffset={5}>
          {sortingOptions.map((item) => (
            <S.DropDownItem
              key={item.value}
              onClick={() => props.setSort(item.value)}
            >
              {props.sort === item.value && (
                <S.Icon
                  src="CheckCircle"
                  width={20}
                  height={20}
                  color="secondary"
                />
              )}

              <S.DropDownItemText color="secondary">
                {item.label}
              </S.DropDownItemText>
            </S.DropDownItem>
          ))}
        </S.DropDown>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
