import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import { S as DeleteQuestionStyles } from '../../components/DeleteNews/DeleteNews.styles';
const FaqListItem = styled.li`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 300;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryLight}99;

    ${DeleteQuestionStyles.DeleteButton} {
      opacity: 1;
    }
  }
`;

export const S = {
  ...UIElements,
  FaqListItem,
};
