import styled from 'styled-components';

const LayoutContainer = styled.div`
  padding-top: 68px;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.lightGray};
  overflow: scroll;
  min-width: fit-content;
`;

export const S = {
  LayoutContainer,
};
