import React from 'react';
import { useTable } from 'react-table';
import { Controller } from 'react-hook-form';
import { useCompaniesFormContext } from '../../../../components/ControlLayout';
import { useForeignWorkplaceTable } from '../../hooks/useForeignWorkplaceTable';
import { FindAllCompaniesResponse } from '@ansvar-sakerhet/api-client';

import { S } from './ForeignWorkplaceTable.styles';

type Props = {
  companies: FindAllCompaniesResponse[];
};

export const ForeignWorkplaceTable: React.FC<Props> = (props) => {
  const data = useForeignWorkplaceTable(props.companies);
  const table = useTable(data);

  const { control } = useCompaniesFormContext();

  return (
    <S.TWrapper>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.TBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'isPreQualified') {
                    return (
                      <S.TBodyCell key={cell.getCellProps().key} $padding="0">
                        <Controller
                          name={'companies'}
                          control={control}
                          render={({ field }) => (
                            <S.CheckBoxWrapper>
                              <S.Checkbox
                                checked={
                                  field.value.find(
                                    (item) =>
                                      item.companyId === row.original.companyId
                                  )?.isPreQualified || false
                                }
                                onChange={(e) => {
                                  field.onChange(
                                    field.value.map((item) => {
                                      if (
                                        item.companyId !==
                                        row.original.companyId
                                      ) {
                                        return item;
                                      }

                                      return {
                                        ...item,
                                        isPreQualified: e.target.checked,
                                      };
                                    })
                                  );
                                }}
                                type="checkbox"
                              />
                            </S.CheckBoxWrapper>
                          )}
                        />
                      </S.TBodyCell>
                    );
                  }

                  if (cell.column.id === 'companyNationality') {
                    return (
                      <S.TBodyCell key={cell.getCellProps().key} $padding="0">
                        <Controller
                          name={'companies'}
                          control={control}
                          render={({ field }) => (
                            <S.CheckBoxWrapper>
                              <S.Checkbox
                                checked={
                                  field.value.find(
                                    (item) =>
                                      item.companyId === row.original.companyId
                                  )?.nationality === 'SE' || false
                                }
                                onChange={(e) => {
                                  field.onChange(
                                    field.value.map((item) => {
                                      if (
                                        item.companyId !==
                                        row.original.companyId
                                      ) {
                                        return item;
                                      }

                                      return {
                                        ...item,
                                        nationality: e.target.checked
                                          ? 'SE'
                                          : item.nationality,
                                      };
                                    })
                                  );
                                }}
                                type="checkbox"
                              />
                            </S.CheckBoxWrapper>
                          )}
                        />
                      </S.TBodyCell>
                    );
                  }

                  if (cell.column.id === 'hasReportingObligation') {
                    return (
                      <S.TBodyCell key={cell.getCellProps().key} $padding="0">
                        <Controller
                          name={'companies'}
                          control={control}
                          render={({ field }) => (
                            <S.CheckBoxWrapper>
                              <S.Checkbox
                                checked={
                                  field.value.find(
                                    (item) =>
                                      item.companyId === row.original.companyId
                                  )?.hasReportingObligation || false
                                }
                                onChange={(e) => {
                                  field.onChange(
                                    field.value.map((item) => {
                                      if (
                                        item.companyId !==
                                        row.original.companyId
                                      ) {
                                        return item;
                                      }

                                      return {
                                        ...item,
                                        hasReportingObligation:
                                          e.target.checked,
                                      };
                                    })
                                  );
                                }}
                                type="checkbox"
                              />
                            </S.CheckBoxWrapper>
                          )}
                        />
                      </S.TBodyCell>
                    );
                  }

                  if (cell.column.id === 'isReportingObligationFulfilled') {
                    return (
                      <S.TBodyCell key={cell.getCellProps().key} $padding="0">
                        <Controller
                          name={'companies'}
                          control={control}
                          render={({ field }) => (
                            <S.CheckBoxWrapper>
                              <S.Checkbox
                                checked={
                                  field.value.find(
                                    (item) =>
                                      item.companyId === row.original.companyId
                                  )?.isReportingObligationFulfilled || false
                                }
                                onChange={(e) => {
                                  field.onChange(
                                    field.value.map((item) => {
                                      if (
                                        item.companyId !==
                                        row.original.companyId
                                      ) {
                                        return item;
                                      }

                                      return {
                                        ...item,
                                        isReportingObligationFulfilled:
                                          e.target.checked,
                                      };
                                    })
                                  );
                                }}
                                type="checkbox"
                              />
                            </S.CheckBoxWrapper>
                          )}
                        />
                      </S.TBodyCell>
                    );
                  }
                  return (
                    <S.TBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.TBodyCell>
                  );
                })}
              </S.TBodyRow>
            );
          })}
        </S.TBody>
      </S.Table>
    </S.TWrapper>
  );
};
