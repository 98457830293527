import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { RadioInput } from '../RadioInput';
import { toast } from '../../../../../../components/Toast';
import { S } from './CreateBackgroundControlModal.styles';
import {
  CreateBackgroundControlFormValues,
  useCreateBackgroundControlForm,
} from '../../hooks/useCreateBackgroundControlForm';
import { useCreateBackgroundControl } from '../../../../../../traits/api/backgroundControls/useCreateBackgroundControl';
import { useReportUpload } from './hooks/useReportUpload';
import { BackgroundControlWithFilesRiskEnum } from '@ansvar-sakerhet/api-client';
import { useControl } from '../../../../components/ControlLayout';

type Props = {
  companyRegistrationNumber: string;
  open: boolean;
  onCancel: () => void;
};

export const CreateBackgroundControlModal: React.FC<Props> = (props) => {
  const { data: dbControl } = useControl();
  const { handleSubmit, watch, register, control, setValue, getValues } =
    useCreateBackgroundControlForm();
  const { mutateAsync: createBackgroundControl } = useCreateBackgroundControl();
  const { handleUploadClick, inputRef, handleFileChange } = useReportUpload();

  const onErrors = () => {
    toast.error('Formuläret är inte korrekt ifyllt');
  };

  const handleSave = async (data: CreateBackgroundControlFormValues) => {
    try {
      await createBackgroundControl({
        fileId: data.fileId,
        risk: data.backgroundControlStatus,
        optedOut: data.optedOut === 'yes',
        companyRegistrationNumber: props.companyRegistrationNumber,
        projectId: dbControl.projectId,
      });
      toast.success('Ändringarna har sparats');
      props.onCancel();
    } catch (e) {
      toast.error('Något gick fel, gick inte att spara ändringarna');
    }
  };

  if (!props.open) {
    return <></>;
  }

  return (
    <Dialog.Portal>
      <S.Overlay>
        <S.Content>
          <S.ContentHeader>
            <Dialog.Title asChild>
              <S.H4>Ladda upp bakgrundskontroll</S.H4>
            </Dialog.Title>

            <S.CloseButton asChild onClick={props.onCancel}>
              <S.Icon src="Close" width={20} height={20} />
            </S.CloseButton>
          </S.ContentHeader>

          <form onSubmit={handleSubmit(handleSave, onErrors)}>
            <S.FieldSet>
              <S.H5>Frågeformulär</S.H5>

              <RadioInput
                {...register('backgroundControlStatus')}
                control={control}
                state={watch('backgroundControlStatus')}
                question="Resultat för bakgrundskontroll"
                name="backgroundControlStatus"
                options={[
                  {
                    label: 'Inga risker identifierade',
                    value: BackgroundControlWithFilesRiskEnum.NoRisk,
                  },
                  {
                    label: 'Mindre väsentliga risker',
                    value: BackgroundControlWithFilesRiskEnum.LesserRisk,
                  },
                  {
                    label: 'Medel-hög risk',
                    value: BackgroundControlWithFilesRiskEnum.MediumHighRisk,
                  },
                  {
                    label: 'Hög risk',
                    value: BackgroundControlWithFilesRiskEnum.HighRisk,
                  },
                ]}
              />

              <RadioInput
                {...register('optedOut')}
                control={control}
                state={watch('optedOut')}
                question="Bortvald"
                name="optedOut"
                options={[
                  {
                    label: 'Nej',
                    value: 'no',
                  },
                  {
                    label: 'Ja',
                    value: 'yes',
                  },
                ]}
              />

              <S.FlexView>
                <S.H6>Rapport</S.H6>
                <S.Button type="button" onClick={handleUploadClick}>
                  Ladda upp rapport
                </S.Button>
                {getValues().fileId && 'Fil är uppladdad'}
                <S.HiddenInput
                  type="file"
                  ref={inputRef}
                  onChange={async (ev) => {
                    handleFileChange(ev).then((fileId) =>
                      setValue('fileId', fileId ?? '')
                    );
                  }}
                  accept=".pdf"
                />
              </S.FlexView>
            </S.FieldSet>

            <S.ContentFooter>
              <Dialog.Close asChild onClick={props.onCancel}>
                <S.Button $outlined>Avbryt</S.Button>
              </Dialog.Close>

              <S.Button type="submit">Spara</S.Button>
            </S.ContentFooter>
          </form>
        </S.Content>
      </S.Overlay>
    </Dialog.Portal>
  );
};
