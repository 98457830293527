import { useMutation, useQueryClient } from '@tanstack/react-query';
import { controlKeys } from './controls.key';
import { useApi } from '../useApi';

type Options = {
  onStartReportSuccess?: () => void;
  onStartReportError?: (error: unknown) => void;
};

export function useStartReport(inspectionId: string, options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const startReport = async (data: { controlId: string }) => {
    const api = await getApi();
    const res = await api.controlsStartReport(data.controlId);
    return res.data;
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: controlKeys.findOne(inspectionId),
    });
    options?.onStartReportSuccess?.();
  };

  const onError = (error: unknown) => {
    options?.onStartReportError?.(error);
  };

  const mutation = useMutation(startReport, {
    onSuccess,
    onError,
  });

  return mutation;
}
