import { S } from '../RedListedCompanies.styled';
import React, { useState } from 'react';
import { AddRedListedModal } from './AddRedListedModal';

export const Title: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <S.HeaderContainer>
        <S.H3>Rödlistade bolag</S.H3>
        <S.Button onClick={() => setModalOpen(true)}>
          <S.Icon src="Plus" /> Lägg till rödlistat bolag
        </S.Button>
      </S.HeaderContainer>
      <AddRedListedModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};
