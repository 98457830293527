import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { faqKeys } from './faq.key';

export function useGetQuestion(faqId: string) {
  const getApi = useApi();

  const getQuestion = async () => {
    const api = await getApi();

    const res = await api.faqsFindOne(faqId);
    return res.data;
  };

  const query = useQuery({
    queryKey: faqKeys.findOne(faqId),
    queryFn: getQuestion,
  });

  return query;
}
