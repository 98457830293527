import React from 'react';
import {
  addCustomerUserFormValues,
  useAddCustomerUserForm,
} from './hooks/useAddCustomerUserForm';
import { S } from './AddCustomerUserForm.styles';
import { Button } from '@ansvar-sakerhet/ui/src/buttons/Button';
import { CreateCustomerUserDto } from '@ansvar-sakerhet/api-client';
import { useCreateCustomerUser } from '../../../../../../../../../../../traits/api/customerUsers/useCreateCustomerUser';
import { Input } from '../../../../../../../../../../../components/Input';

export const AddCustomerUserForm = ({
  chosenClientId,
  onCancel,
}: {
  chosenClientId: string;
  onCancel: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useAddCustomerUserForm({
    defaultClientId: chosenClientId,
  });
  const { mutateAsync } = useCreateCustomerUser();

  const onSubmit = async (data: addCustomerUserFormValues) => {
    const formattedData: CreateCustomerUserDto = {
      ...data,
      clientIds: [data.clientId],
    };
    await mutateAsync(formattedData);
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Container>
        <Input label="E-post" error={errors.email} {...register('email')} />
        <Input
          label="Lösenord"
          type="password"
          error={errors.password}
          {...register('password')}
        />
        <Input
          label="Förnamn"
          error={errors.firstName}
          {...register('firstName')}
        />
        <Input
          label="Efternamn"
          error={errors.lastName}
          {...register('lastName')}
        />

        <S.Flex $justify="between" $gap={16} $flexDir="row">
          <Button $outlined type="button" onClick={onCancel}>
            Avbryt
          </Button>
          <Button type="submit">Spara</Button>
        </S.Flex>
      </S.Container>
    </form>
  );
};
