import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const SortButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const DropDown = styled(DropdownMenu.Content)`
  border-radius: 3px;
  padding: 10px 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--light-grey, #cbcbcb);
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(27, 27, 27, 0.15);
`;

const DropDownItem = styled(DropdownMenu.Item)`
  display: grid;
  height: 24px;
  grid-template-columns: 22px 1fr;
  grid-column-gap: 6px;
  align-items: center;
  cursor: pointer;
`;

const DropDownItemText = styled(UIElements.B2)`
  grid-area: 1/ 2;
`;

export const S = {
  ...UIElements,
  SortButton,
  DropDown,
  DropDownItem,
  DropDownItemText,
};
