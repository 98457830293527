import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import * as Accordion from '@radix-ui/react-accordion';

const ResultContainer = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
`;

const ResultInner = styled(Accordion.Root)<{ paddingbottom?: number }>`
  width: 850px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: ${(props) => props.paddingbottom || 0}px;
`;

const Body = styled(Accordion.Item)`
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 1px 6px 0px rgba(27, 27, 27, 0.05);
`;

const Header = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #cbcbcb;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

const BodyInner = styled(Accordion.Content)`
  padding: 24px 24px 29px 24px;
  display: grid;
  gap: 16px;
`;

const Footer = styled.section`
  display: flex;
  gap: 24px;
  flex-grow: 1;
`;

export const S = {
  ...UIElements,
  ResultContainer,
  ResultInner,
  Body,
  Footer,
  Header,
  BodyInner,
  FlexRow,
};
