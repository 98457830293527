import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input } from '../../../../components/Input';
import { MarkDownEditor } from '../../components/MarkDownEditor';
import { CancelNews } from '../../components/CancelNews';
import { toast } from '../../../../components/Toast';

import { S } from './NewsEditor.styles';
import { useGetOneNews } from '../../../../traits/api/news/useGetOneNews';
import { useUpdateNews } from '../../../../traits/api/news/useUpdateNews';

export const NewsEditor: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const newsId = location.pathname.split('/').pop() || '';
  const news = useGetOneNews(newsId);
  // const deleteFaqFile = useDeleteFaqFile();
  // const createFaqFile = useCreateFaqFile();
  const updateFaq = useUpdateNews(newsId, { onUseUpdateNewsSuccess });
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  // const [images, setImages] = useState<UploadFileResponse[]>([]);

  useEffect(() => {
    if (news.data) {
      setHeader(news.data.header);
      setContent(news.data.content);
    }
  }, [news.data]);

  // const findDeletedImages = () => {
  //   // const oldImageStrings = faq.data?.images.map((image) => ({
  //   //   url: image.file.url,
  //   //   id: image.faqFileId,
  //   // }));
  //   const newImageStrings = answer.match(/!\[.*\]\(.*\)/g);
  //   const deletedImages = oldImageStrings?.filter(
  //     (image) => !newImageStrings?.includes(image.url)
  //   );

  //   return deletedImages;
  // };

  const handleSave = async () => {
    try {
      await updateFaq.mutateAsync({ header, content });
      // const deletedImages = findDeletedImages();

      // if (images?.length) {
      //   await Promise.all(
      //     images.map((image) =>
      //       createFaqFile.mutateAsync({ faqId, imageId: image.fileId })
      //     )
      //   );
      // }

      // if (deletedImages?.length) {
      //   await Promise.all(
      //     deletedImages.map((image) => deleteFaqFile.mutateAsync(image.id))
      //   );
      // }
    } catch (err) {
      toast.error('Något gick fel när frågan skulle uppdateras');
    }
  };

  function onUseUpdateNewsSuccess() {
    navigate('/news');
  }

  return (
    <S.FaqEditor data-color-mode="light">
      <S.FaqInner>
        <S.Body>
          <S.H4>Redigera</S.H4>

          <Input
            label="Header"
            placeholder="Header"
            value={header}
            onFocus={() => setHasBeenEdited(true)}
            onChange={(e) => setHeader(e.target.value)}
          />

          <MarkDownEditor
            setNewImages={() => 'TODO: New image'}
            value={content}
            onChange={(value) => setContent(value)}
            onFocus={() => setHasBeenEdited(true)}
          />
        </S.Body>

        <S.Footer>
          <CancelNews hasBeenEdited={hasBeenEdited} />

          <S.Button disabled={!hasBeenEdited} onClick={handleSave}>
            Spara
          </S.Button>
        </S.Footer>
      </S.FaqInner>
    </S.FaqEditor>
  );
};
