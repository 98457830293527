import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { customerUserKeys } from './customerUsers.key';
import { useState } from 'react';

export function useGetAllCustomerUsersOnClient(
  clientId: string,
  take?: number,
  skip?: number
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getCustomerUsers = async () => {
    const api = await getApi();

    const res = await api.customerUserFindAllOnClient(clientId, take, skip);
    setCount(Number(res.headers['x-total-count']));

    return res.data;
  };

  const query = useQuery({
    queryKey: customerUserKeys.findAllOnClient(clientId, take, skip),
    queryFn: getCustomerUsers,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
