import { useApi } from '../useApi';

export function downloadCSV(workRegisterId: string) {
  const getApi = useApi();

  const downloadCSV = async (): Promise<string> => {
    const api = await getApi();
    const res = await api.personnelDownload(workRegisterId);
    return res.data + '';
  };

  return { downloadCSV };
}
