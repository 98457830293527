import { useMemo } from 'react';
import { FindAllPersonnelResponse } from '@ansvar-sakerhet/api-client';

export const useWorkRegisterTableData = (
  personnel: FindAllPersonnelResponse[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
      },
      {
        Header: 'Identitetsnr',
        accessor: 'identityNumber',
      },
      {
        Header: 'Företag',
        accessor: 'companyName',
      },
      {
        Header: 'Org.nr',
        accessor: 'orgNumber',
      },
      {
        Header: 'ID06',
        accessor: 'id06',
      },
      {
        Header: 'Personalliggare',
        accessor: 'workRegister',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return personnel.map((person) => {
      return {
        personnelId: person.personnelId,
        name: `${person.firstName} ${person.lastName}`,
        identityNumber: person.personalCodeNumber || '-',
        companyName: person.company.name,
        orgNumber: person.company.registrationNumber,
        id06: !person.isScanned
          ? 'Saknas'
          : person.isID06CardInactive
          ? 'Inaktivt'
          : person.isID06CardDisabled
          ? 'Avstängt'
          : person.isID06CardInvalid
          ? 'Ogiltigt'
          : person.isID06CardOther
          ? 'Annat'
          : person.isID06CardMissingCompetence
          ? 'Annat'
          : person.hasID06CardComment
          ? 'Annat'
          : 'Aktivt',
        workRegister: person.isID06CardDeviating
          ? 'Avvikelse'
          : person.isQuestionnaireDeviating
          ? 'Avvikelse'
          : !person.arrivedAt
          ? 'Avvikelse'
          : 'Inga avvikelser',
        person: person,
      } as const;
    });
  }, [personnel]);

  return { columns, data };
};
