import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const SingleClient = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Container = styled.div`
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

export const S = {
  ...UIElements,
  SingleClient,
  Container,
};
