import React from 'react';

import { S } from './Rules.styles';

type Props = {
  rule: string;
};

export const Rules: React.FC<Props> = (props) => {
  return (
    <S.RulesContainer>
      <S.IconContainer>
        <S.Icon src="Rules" />
      </S.IconContainer>

      <S.H6>Regelverk</S.H6>

      <S.Italic>{props.rule}</S.Italic>
    </S.RulesContainer>
  );
};
