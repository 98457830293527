import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { controlKeys } from './controls.key';

export function useGetCompanies(inspectionId: string) {
  const getApi = useApi();

  const getCompanies = async () => {
    const api = await getApi();

    const res = await api.companiesFindByInspectionId(inspectionId);
    return res.data;
  };

  const query = useQuery({
    queryKey: controlKeys.findCompanies(inspectionId),
    queryFn: getCompanies,
    keepPreviousData: true,
  });

  return query;
}
