import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetControl } from '../../../../../traits/api/controls/useGetControl';
import { useEffect } from 'react';
import { nullsToUndefined } from '../../../../../traits/utils/nullsToUndefined';

export const freeTextSchema = z.object({
  assignmentDescription: z.string().optional(),
});

export type FreeTextFormValues = z.infer<typeof freeTextSchema>;

export const useFreeTextForm = (inspectionId: string) => {
  const control = useGetControl(inspectionId);

  const methods = useForm<FreeTextFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(freeTextSchema),
  });

  useEffect(() => {
    if (!control.data) return;

    const { assignmentDescription } = control.data;

    methods.reset(nullsToUndefined({ assignmentDescription }));
  }, [control.isSuccess]);

  return methods;
};
