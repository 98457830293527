import React from 'react';
import { S } from './HeaderDropdown.styles';
import { useUser } from '../../traits/hooks/useUser';
import { useAuth } from '../../traits/hooks/useAuth';
import { MailTo } from '../Mailto';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const HeaderDropdown: React.FC<Props> = (props) => {
  const { logout } = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const { state } = useParams();

  const onNavigateToControls = () => {
    navigate(`/kontroller/${state}`);
    props.onClose();
  };

  const onNavigateToFaq = () => {
    navigate(`/faq`);
    props.onClose();
  };

  const onNavigateToNews = () => {
    navigate(`/news`);
    props.onClose();
  };

  const onNavigateToCustomer = () => {
    navigate(`/kunder`);
    props.onClose();
  };

  const onNavigateToRedListedCompany = () => {
    navigate(`/rodlistade-bolag`);
    props.onClose();
  };

  return (
    <React.Fragment>
      <S.ProfileDropdownBackground
        onClick={props.onClose}
        $isOpen={props.isOpen}
      />

      {props.isOpen && (
        <S.ProfileDropdownContainer>
          <S.AccountContainer>
            <S.H6>Konto</S.H6>

            <S.Margin $spacing="s" />

            <S.B2>{user.name}</S.B2>

            <S.Margin $spacing="xs" />

            <S.B3 $color="darkGray">{user.email}</S.B3>
          </S.AccountContainer>

          <S.Divider />

          <S.MailContainer>
            <S.H6 $left="m">Mailmallar</S.H6>

            <S.Margin $spacing="s" />

            <MailTo
              email="arbetsmiljoverket@av.se"
              body="Hej! Har nedanstående bolag tagit emot utsänd arbetskraft? 
            Om ja, återkoppla gärna med filer från utstationeringsregister."
            >
              Arbetsmiljöverket
            </MailTo>

            <S.Margin $spacing="xs" />

            <MailTo
              email="migrationsverket@migrationsverket.se"
              body="Hej! Har nedanstående personer uppehålls- och/eller arbetstillstånd i Sverige?
              Hur länge i så fall och är det någon/några som inte ger någon träff i ert system?"
            >
              Migrationsverket
            </MailTo>
          </S.MailContainer>

          <S.Divider />

          <S.FaqContainer onClick={onNavigateToFaq}>
            <S.H6 $color="secondary">Gå till FAQ</S.H6>
          </S.FaqContainer>

          <S.FaqContainer onClick={onNavigateToNews}>
            <S.H6 $color="secondary">Gå till nyheter</S.H6>
          </S.FaqContainer>

          <S.FaqContainer onClick={onNavigateToControls}>
            <S.H6 $color="secondary">Gå till kontroller</S.H6>
          </S.FaqContainer>

          <S.FaqContainer onClick={onNavigateToCustomer}>
            <S.H6 $color="secondary">Gå till kunder</S.H6>
          </S.FaqContainer>

          <S.FaqContainer onClick={onNavigateToRedListedCompany}>
            <S.H6 $color="secondary">Gå till rödlistade bolag</S.H6>
          </S.FaqContainer>

          <S.Divider />

          <S.LogoutContainer onClick={logout}>
            <S.LogoutInner>
              <S.Icon src="Logout" width={22} color="secondary" />

              <S.B2 $top="xxs" $color="secondary" $weight={500}>
                Logga ut
              </S.B2>
            </S.LogoutInner>
          </S.LogoutContainer>
        </S.ProfileDropdownContainer>
      )}
    </React.Fragment>
  );
};
