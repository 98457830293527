import { UpsertPersonnelQuestionnaireDtoTypeOfIdEnum } from '@ansvar-sakerhet/api-client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const editPersonnelSchema = z.object({
  id06Comment: z.string().optional(),

  hasIdCard: z.enum(['Yes', 'No', 'Unclear']).optional(),
  hasSafetyEquipment: z.enum(['Yes', 'No']).optional(),
  canShowValidA1Certificate: z
    .enum(['Yes', 'No', 'Unclear', 'NotApplicable'])
    .optional(),
  canShowValidWorkPermit: z
    .enum(['Yes', 'No', 'Unclear', 'NotApplicable'])
    .optional(),
  hasSignsOfIntoxication: z.enum(['Yes', 'No', 'Unclear']).optional(),
  questionnaireComment: z.string().optional(),
  typeOfId: z
    .enum([
      UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.Passport,
      UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.NationalId,
      UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.DriverLicense,
      UpsertPersonnelQuestionnaireDtoTypeOfIdEnum.Skvid,
    ])
    .optional(),

  files: z.array(
    z.object({
      personnelFileId: z.string(),
      comment: z.string().optional(),
    })
  ),
  deletedFiles: z.array(z.string()),
});

export type EditPersonnelFormValues = z.infer<typeof editPersonnelSchema>;

export const useEditPersonnelForm = () => {
  const methods = useForm<EditPersonnelFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(editPersonnelSchema),
  });

  return methods;
};
