import { useMutation, useQueryClient } from '@tanstack/react-query';
import { workRegisterKeys } from './workRegisters.key';
import {
  UpsertPersonnelQuestionnaireDto,
  UpsertPersonnelQuestionnaireResponse,
} from '@ansvar-sakerhet/api-client';
import { useApi } from '../useApi';
import toast from 'react-hot-toast';

type Options = {
  onUpsertQuestionnaireSuccess?: (
    data: UpsertPersonnelQuestionnaireResponse
  ) => void;
  onUpsertQuestionnaireError?: (error: unknown) => void;
};

export function useUpsertQuestionnaire(
  personnelId: string,
  controlId: string,
  options?: Options
) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const upsertQuestionnaire = async (data: UpsertPersonnelQuestionnaireDto) => {
    const api = await getApi();

    const res = await api.personnelUpsertQuestionnaire(personnelId, data);
    return res.data;
  };

  const onSuccess = (data: UpsertPersonnelQuestionnaireResponse) => {
    queryClient.invalidateQueries({
      queryKey: workRegisterKeys.personnelFindOne(controlId, personnelId),
    });

    options?.onUpsertQuestionnaireSuccess?.(data);
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel, gick inte att spara');

    options?.onUpsertQuestionnaireError?.(error);
  };

  const mutation = useMutation(upsertQuestionnaire, {
    onSuccess,
    onError,
  });

  return mutation;
}
