import React from 'react';
import { useNavigate } from 'react-router-dom';

import { S } from './Header.styles';

export const Header: React.FC = () => {
  const navigate = useNavigate();

  const navigateToCreateFaq = () => {
    navigate('ny');
  };

  return (
    <S.Header>
      <S.H4>FAQ frågor</S.H4>
      <S.AddButton>
        <S.Icon src="Add" />
        <S.H6 $color="secondary" onClick={navigateToCreateFaq}>
          Lägg till ny fråga
        </S.H6>
      </S.AddButton>
    </S.Header>
  );
};
