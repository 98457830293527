import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { workRegisterKeys } from './workRegisters.key';

export function useGetPersonnelID06Competences(
  personnelId: string,
  controlId: string
) {
  const getApi = useApi();

  const getPersonnelID06Competences = async () => {
    const api = await getApi();

    const res = await api.personnelFindAllId06CardCompetences(personnelId);
    return res.data;
  };

  const query = useQuery({
    queryKey: workRegisterKeys.findAllPersonnelID06CardCompetences(
      controlId,
      personnelId
    ),
    queryFn: getPersonnelID06Competences,
  });

  return query;
}
