import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const ControlHeaderContainer = styled.div`
  display: flex;
  position: fixed;
  background: white;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 100;
  border-bottom: 0.8px solid ${({ theme }) => theme.colors.gray};
`;

const ControlHeaderInner = styled.div`
  max-width: 1444px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 160px;
  width: 100%;
`;

const ControlBackButtonWrapper = styled.div`
  position: absolute;
  left: 20px;
`;

const ControlBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 160px - 68px);
  width: 100%;
  margin-top: 180px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ControlBodyInner = styled.div`
  max-width: 1444px;
  min-height: calc(100vh - 160px - 68px);
  width: 100%;
  padding: 24px 72px;
`;

const ControlInfoContainer = styled.div`
  padding: 24px 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 400px;
  cursor: pointer;
`;

const ControlActionsContainer = styled.div`
  padding: 24px 72px;
  display: flex;
  min-width: 700px;
  gap: 16px;
`;

const ControlNavigationContainer = styled.div`
  padding: 16px 72px 0px;
  display: flex;
  gap: 16px;
`;

const NavLink = styled.div<{
  $isActive: boolean;
  $isDisabled?: boolean;
}>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.black : theme.colors.darkGray};

  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    margin-top: 16px;
    background-color: ${({ theme }) => theme.colors.secondary};
    visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  }
`;

export const S = {
  ...UIElements,
  ControlHeaderContainer,
  ControlHeaderInner,
  ControlBackButtonWrapper,
  ControlBodyContainer,
  ControlBodyInner,
  ControlInfoContainer,
  ControlActionsContainer,
  ControlNavigationContainer,
  NavLink,
};
