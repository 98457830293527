import { CreateNewsDto, CreateNewsResponse } from '@ansvar-sakerhet/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { newsKeys } from './news.key';

type Options = {
  onUseUpdateNewsSuccess?: (data: CreateNewsResponse) => void;
  onUseUpdateNewsError?: (error: unknown) => void;
};

export function useUpdateNews(newsId: string, options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const updateNews = async (data: CreateNewsDto) => {
    const api = await getApi();

    const res = await api.newsUpdate(newsId, data);
    return res.data;
  };

  const onSuccess = (data: CreateNewsResponse) => {
    toast.success('Nyheten har uppdaterats');
    queryClient.invalidateQueries({
      queryKey: newsKeys.findOne(newsId),
    });

    options?.onUseUpdateNewsSuccess?.(data);
  };

  const onError = (error: unknown) => {
    options?.onUseUpdateNewsError?.(error);
  };

  const mutation = useMutation(updateNews, {
    onSuccess,
    onError,
  });

  return mutation;
}
