import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { workRegisterKeys } from './workRegisters.key';
import { toast } from '../../../components/Toast';

export function useGetPersonnel(personnelId: string, controlId: string) {
  const getApi = useApi();

  const getPersonnel = async () => {
    const api = await getApi();

    const res = await api.personnelFindOne(personnelId);
    return res.data;
  };

  const onError = () => {
    toast.error('Något gick fel, personal gick inta att hämta');
  };

  const query = useQuery({
    queryKey: workRegisterKeys.personnelFindOne(controlId, personnelId),
    queryFn: getPersonnel,
    onError,
  });

  return query;
}
