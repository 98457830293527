const all = ['customer-users'] as const;

const findAll = (take?: number, skip?: number) => [...all, take, skip];

const findAllOnClient = (clientId: string, take?: number, skip?: number) => [
  ...all,
  'on',
  clientId,
  take,
  skip,
];

export const customerUserKeys = { all, findAll, findAllOnClient };
