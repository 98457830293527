import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useControl,
  useFreeTextFormContext,
} from '../../components/ControlLayout/ControlLayout';

import { S } from './FreeTextSummary.styles';

export const FreeTextSummary: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useControl();
  const { state } = useParams();
  const { register, trigger, setValue } = useFreeTextFormContext();

  const navigateToSummary = async () => {
    const isValid = await trigger();
    if (!isValid) return;
    navigate(`/kontroller/${state}/${data.inspectionId}/bedomning`);
  };

  const navigateToFlyleaf = async () => {
    navigate(`/kontroller/${state}/${data.inspectionId}/forsattsblad`);
  };

  return (
    <S.FreeTextSummaryContainer>
      <S.FreeTextSummaryInner type="single" defaultValue="1" paddingbottom={24}>
        <S.H4>Uppdragsbeskrivning</S.H4>

        <S.Body value="1">
          <S.Header>
            <S.Icon src="Edit" />
            <S.H6>Beskrivning</S.H6>
          </S.Header>

          <S.BodyInner>
            <S.Textarea
              placeholder="Skriv här..."
              {...register('assignmentDescription')}
            />
            <S.Button
              onClick={() => {
                setValue(
                  'assignmentDescription',
                  `En oannonserad arbetsplatskontroll genomfördes ${
                    data.date
                      ? new Date(data.date).toLocaleDateString() +
                        ' kl ' +
                        new Date(data.date)
                          .toTimeString()
                          .split('GMT')[0]
                          .trim()
                      : ''
                  } och avslutades ${
                    data.completedAt
                      ? new Date(data.completedAt).toLocaleDateString() +
                        ' kl ' +
                        new Date(data.completedAt)
                          .toTimeString()
                          .split('GMT')[0]
                          .trim()
                      : 'AVSLUTNINGSTID SAKNAS'
                  } på projekt ${data.project.name.trim()}, adress ${data.address.trim()} ${data.city.name.trim()}, på uppdrag av ${
                    data.client.name
                  }. Syftet med kontrollen är att säkerställa att projekt på plats som genomförs av berörd entreprenör och dess underentreprenörer sker i enlighet med lagar och ingånget avtal. Huvudentreprenör är ${data.mainContractor.trim()}.`
                );
              }}
            >
              Generera Text
            </S.Button>
          </S.BodyInner>
        </S.Body>

        <S.Footer>
          <S.Button $outlined onClick={navigateToFlyleaf}>
            Föregående
          </S.Button>

          <S.Button onClick={navigateToSummary}>Nästa</S.Button>
        </S.Footer>
      </S.FreeTextSummaryInner>
    </S.FreeTextSummaryContainer>
  );
};
