import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const DeleteDataCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  opacity: 0;
  min-width: 210px;
`;

export const S = {
  ...UIElements,

  DeleteDataCell,
};
