import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { nullsToUndefined } from '../../../../../traits/utils/nullsToUndefined';
import { useGetCompanies } from '../../../../../traits/api/controls/useGetCompanies';

export const companiesSchema = z.object({
  companies: z.array(
    z.object({
      companyId: z.string(),
      controlId: z.string(),
      name: z.string(),
      registrationNumber: z.string(),
      nationality: z.string().optional().nullable(),
      isPreQualified: z.boolean(),
      hasReportingObligation: z.boolean(),
      isReportingObligationFulfilled: z.boolean(),
    })
  ),
});

export type companiesFormValues = z.infer<typeof companiesSchema>;

export const useCompaniesForm = (inspectionId: string) => {
  const companies = useGetCompanies(inspectionId);

  const methods = useForm<companiesFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(companiesSchema),
    defaultValues: {
      companies: [],
    },
  });

  useEffect(() => {
    if (!companies.data?.length) return;
    methods.reset({ companies: companies.data.map(nullsToUndefined) });
  }, [companies.isSuccess]);

  return methods;
};
