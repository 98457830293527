import { useMutation, useQueryClient } from '@tanstack/react-query';
import { workRegisterKeys } from './workRegisters.key';
import { useApi } from '../useApi';
import toast from 'react-hot-toast';
import {
  UpdatePersonnelFileDto,
  UpdatePersonnelFileResponse,
} from '@ansvar-sakerhet/api-client';

type Options = {
  onUpdateCommentSuccess?: (data: UpdatePersonnelFileResponse) => void;
  onUpdateCommentError?: (error: unknown) => void;
};

export function useUpdateFileComment(
  controlId: string,
  personnelId: string,
  options?: Options
) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const updateFileComment = async ({
    personnelFileId,
    data,
  }: {
    personnelFileId: string;
    data: UpdatePersonnelFileDto;
  }) => {
    const api = await getApi();

    const res = await api.personnelFilesUpdateComment(personnelFileId, data);
    return res.data;
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: workRegisterKeys.personnelFindOneFiles(controlId, personnelId),
    });
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel, gick inte att spara');

    options?.onUpdateCommentError?.(error);
  };

  const mutation = useMutation(updateFileComment, {
    onSuccess,
    onError,
  });

  return mutation;
}
