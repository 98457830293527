import React, { useState } from 'react';

import { S } from './NewsOverview.styles';
import { Header } from '../../components/Header';
import { Pagination } from '../../../../components/Pagination';
import { useGetNews } from '../../../../traits/api/news/useGetNews';
import { NewsItem } from '../../components/ListItem';

export const NewsOverview: React.FC = () => {
  const [page, setPage] = useState(0);

  const news = useGetNews(page);

  return (
    <S.FaqOverview>
      <S.FaqInner>
        <div>
          <Header />

          <ul>
            {news.data?.map((item) => (
              <NewsItem
                key={item.newsId}
                faqId={item.newsId || ''}
                faqQuestion={item.header}
              />
            ))}
          </ul>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          numberOfPages={Math.ceil(news.count / 20)}
        />
      </S.FaqInner>
    </S.FaqOverview>
  );
};
