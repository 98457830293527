import React from 'react';
import { S } from './Single.style';
import { SingleClientLayout } from './components/SingleClientLayout';

export const Single: React.FC = () => {
  return (
    <S.Container>
      <SingleClientLayout />
    </S.Container>
  );
};
