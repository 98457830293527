import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { clientKeys } from './clients.key';

export function useGetClient(clientId: string) {
  const getApi = useApi();

  const getClient = async () => {
    const api = await getApi();

    const res = await api.clientsFindOne(clientId);
    return res.data;
  };

  const query = useQuery({
    queryKey: clientKeys.findOne(clientId),
    queryFn: getClient,
  });

  return query;
}
