import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 80px 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Input = styled.input`
  display: none;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px - 68px - 48px);
  justify-content: space-between;
`;

const ControlInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

const Header = styled(UIElements.H5)`
  width: 140px;
`;

const Footer = styled.section`
  display: flex;
  justify-content: flex-end;
  padding: 32px 0 10px 0;
  border-top: 1px solid #cbcbcb;
`;

export const S = {
  ...UIElements,
  EmptyContainer,
  TextContainer,
  Input,
  ControlContainer,
  ControlInnerContainer,
  Row,
  Header,
  Footer,
};
