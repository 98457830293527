import React from 'react';
import { ResultFormValues } from '../../../../components/ControlLayout/hooks/useResultForm';
import { Controller } from 'react-hook-form';
import { useResultFormContext } from '../../../../components/ControlLayout/ControlLayout';
import QuillEditor from 'react-quill';

import { S } from './Textarea.styles';

type Props = {
  label: string;
  placeholder: string;
  showEditor?: boolean;
  name: keyof ResultFormValues;
};

export const Textarea: React.FC<Props> = (props) => {
  const { control } = useResultFormContext();
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'clean',
  ];

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <div>
          <S.Label>{props.label}</S.Label>
          {props.showEditor ? (
            <QuillEditor
              // className={styles.editor}
              formats={formats}
              theme="snow"
              value={field.value as string}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          ) : (
            <S.Textarea {...field} placeholder={props.placeholder} />
          )}
        </div>
      )}
    />
  );
};
