import React, { useState } from 'react';
import { S } from './CompaniesTable.styles';
import { FindAllCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { useExpanded, useTable } from 'react-table';
import { useCompaniesTableData } from './hooks/useCompaniesTableData';
import { CompaniesTableSubRow } from '../CompaniesTableSubRow';

type Props = {
  data: FindAllCompaniesResponse[];
  page: number;
  setPage: (page: number) => void;
  loading: boolean;
};

export const CompaniesTable: React.FC<Props> = (props) => {
  const data = useCompaniesTableData(props.data);
  const table = useTable(data, useExpanded);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const onRowClick = (rowId: string) => {
    const expanded = !!expandedRows.find((id) => rowId === id);
    if (expanded) {
      const expandedWithoutRow = expandedRows.filter((id) => id !== rowId);
      return setExpandedRows(expandedWithoutRow);
    }
    return setExpandedRows([...expandedRows, rowId]);
  };

  return (
    <S.TableContainer>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => {
            return (
              <S.THeadRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <S.THeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps().key}
                    >
                      {column.render('Header')}
                    </S.THeadCell>
                  );
                })}
              </S.THeadRow>
            );
          })}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);
            const isExpanded = !!expandedRows.find((id) => id === row.id);

            return (
              <React.Fragment key={row.getRowProps().key}>
                <S.TBodyRow
                  {...row.getRowProps()}
                  onClick={() => onRowClick(row.id)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <S.TBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </S.TBodyCell>
                    );
                  })}

                  <S.TriggerWrapper>
                    <S.Trigger $isExpanded={isExpanded}>
                      <S.AnimatedIcon src="ChevronDown" />
                    </S.Trigger>
                  </S.TriggerWrapper>
                </S.TBodyRow>
                {isExpanded && (
                  <tr>
                    <td colSpan={table.columns.length + 1}>
                      <CompaniesTableSubRow company={row.original.company} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </S.TBody>
      </S.Table>
    </S.TableContainer>
  );
};
