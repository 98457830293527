import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { controlKeys } from './controls.key';

type Options = {
  onDeleteFileSuccess?: () => void;
  onDeleteFileError?: (error: unknown) => void;
};

export function useDeleteFile(inspectionId: string, options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const deleteFile = async ({ controlFileId }: { controlFileId: string }) => {
    const api = await getApi();

    const res = await api.controlFilesDelete(controlFileId);
    return res.data;
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: controlKeys.findFiles(inspectionId),
    });
    options?.onDeleteFileSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Error, bilden gick inte att radera');

    options?.onDeleteFileError?.(error);
  };

  const mutation = useMutation(deleteFile, {
    onSuccess,
    onError,
  });

  return mutation;
}
