import React, { useState, MouseEvent } from 'react';

import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { useDeleteGdprData } from '../../../../../../traits/api/controls/useDeleteGdprData';

import { S } from './DeleteDialog.styles';

type Props = {
  controlId: string;
  onClose: () => void;
};

export const DeleteDialog: React.FC<Props> = (props) => {
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  const GDPRData = useDeleteGdprData({
    onDeleteControlSuccess: () => {
      setHasBeenDeleted(true);
    },
  });

  const onCancel = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setHasBeenDeleted(false);
    props.onClose();
  };

  return (
    <AlertDialog.Portal>
      <S.Overlay>
        <S.Wrapper>
          <S.CancelButton onClick={onCancel}>
            <S.Icon src="Close" width={24} height={24} />
          </S.CancelButton>

          {!hasBeenDeleted ? (
            <S.ContentContainer>
              <img src={Images.Delete} width={295} height={215} />

              <AlertDialog.Title asChild>
                <S.H5>Är du säker på att du vill radera all känslig data?</S.H5>
              </AlertDialog.Title>

              <AlertDialog.Description asChild>
                <S.H6>Datan kommer inte kunna återställas.</S.H6>
              </AlertDialog.Description>

              <S.ButtonContainer>
                <AlertDialog.Cancel asChild>
                  <S.Button $outlined onClick={onCancel}>
                    Avbryt
                  </S.Button>
                </AlertDialog.Cancel>

                <AlertDialog.Action asChild>
                  <S.Button
                    disabled={GDPRData.isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      GDPRData.mutate(props.controlId);
                    }}
                  >
                    Ja, radera
                  </S.Button>
                </AlertDialog.Action>
              </S.ButtonContainer>
            </S.ContentContainer>
          ) : (
            <S.ContentContainer>
              <img src={Images.Confirmation} width={276} height={225} />

              <AlertDialog.Title asChild>
                <S.H5>All känslig data har raderats!</S.H5>
              </AlertDialog.Title>

              <S.ButtonContainer>
                <AlertDialog.Cancel asChild>
                  <S.Button onClick={onCancel}>Stäng</S.Button>
                </AlertDialog.Cancel>
              </S.ButtonContainer>
            </S.ContentContainer>
          )}
        </S.Wrapper>
      </S.Overlay>
    </AlertDialog.Portal>
  );
};
