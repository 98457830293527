import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { faqKeys } from './faq.key';

type Options = {
  onDeleteQuestionSuccess?: () => void;
  onDeleteQuestionError?: (error: unknown) => void;
};

export function useDeleteQuestion(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const deleteQuestion = async (faqId: string) => {
    const api = await getApi();

    const res = await api.faqsDelete(faqId);
    return res.data;
  };

  const onSuccess = () => {
    toast.success('Frågan har raderats');
    queryClient.invalidateQueries({ queryKey: faqKeys.all });
    options?.onDeleteQuestionSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Error, frågan gick inte att radera');
    options?.onDeleteQuestionError?.(error);
  };

  const mutation = useMutation(deleteQuestion, {
    onError,
    onSuccess,
  });

  return mutation;
}
