import React from 'react';
import { S } from './ControlsSortDropdown.styles';
import { SortControls } from '../../../../../../traits/api/controls/useGetControls';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

type Props = {
  sort: SortControls;
  setSort: (value: SortControls) => void;
};

export const ControlsSortDropdown: React.FC<Props> = (props) => {
  const sortingOptions = [
    {
      label: 'Uppdragsgivare A-Ö',
      value: 'clientName_ASC',
    },
    {
      label: 'Projekt A-Ö',
      value: 'projectName_ASC',
    },
    {
      label: 'Datum senaste',
      value: 'date_DESC',
    },
    {
      label: 'Datum äldst',
      value: 'date_ASC',
    },
    {
      label: 'Kontrollnr',
      value: 'inspectionId_ASC',
    },
  ] as const;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <S.SortButton aria-label="Customise options">
          <S.H6 color="secondary">Sortera</S.H6>
          <S.Icon src="Sort" color="secondary" />
        </S.SortButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <S.DropDown sideOffset={5}>
          {sortingOptions.map((item) => (
            <S.DropDownItem
              key={item.value}
              onClick={() => props.setSort(item.value)}
            >
              {props.sort === item.value && (
                <S.Icon
                  src="CheckCircle"
                  width={20}
                  height={20}
                  color="secondary"
                />
              )}

              <S.DropDownItemText color="secondary">
                {item.label}
              </S.DropDownItemText>
            </S.DropDownItem>
          ))}
        </S.DropDown>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
