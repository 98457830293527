import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const RulesContainer = styled.div`
  padding: 9px 24px 15px 9px;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-template-rows: 24 1fr;
  grid-column-gap: 9px;
  grid-row-gap: 3px;
  align-items: center;
  border-radius: 4px;
  background-color: #023e5233;
`;

const IconContainer = styled.span`
  grid-area: 1 / 1;
  align-self: end;
  justify-self: center;
`;

const Italic = styled.p`
  color: #000;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 21px;
  grid-area: 2 / 2;
`;

export const S = {
  ...UIElements,
  RulesContainer,
  Italic,
  IconContainer,
};
