import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  bottom: 142px;
  left: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

const ImageModal = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const RelativeWrapper = styled.div`
  position: relative;
  display: block;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 19px;
`;

const Image = styled.img`
  width: 123px;
  height: 153px;
  border-radius: 2px;
  overflow: hidden;
  object-fit: cover;
`;

export const S = {
  ...UIElements,
  DeleteButton,
  FlexRow,
  RelativeWrapper,
  Image,
  ImageModal,
};
