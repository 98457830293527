import React from 'react';
import { S } from './DeviationsTableSortDropdown.style';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { SortDeviations } from '../DeviationsTable/hooks/useDeviationsTableData';

type Props = {
  sort: SortDeviations;
  setSort: (value: SortDeviations) => void;
};

export const DeviationsSortDropdown: React.FC<Props> = (props) => {
  const sortingOptions = [
    {
      label: 'Inget filter',
      value: 'none',
    },
    {
      label: 'Antal kontrollerade med ID06-kort- avvikelser',
      value: 'id_06_card',
    },
    {
      label: 'Antal kontrollerade individer utan Safe Construction Training',
      value: 'safe_construction',
    },
    {
      label: 'Antal kontrollerade individer som saknade giltig ID-handling',
      value: 'no_id',
    },
    {
      label:
        'Antal kontrollerade individer med bristande personlig säkerhetsutrustning',
      value: 'no_safe_equipment',
    },
    {
      label: 'Antal ej inloggade',
      value: 'not_logged_in',
    },
    {
      label: 'Antal ej anmälda till utstationeringsregistret',
      value: 'no_a1',
    },
  ] as const;

  return (
    <S.Root>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <S.SortButton aria-label="Customise options">
            <S.H6 color="secondary">Sortera</S.H6>
            <S.Icon src="Sort" color="secondary" />
          </S.SortButton>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <S.DropDown sideOffset={5}>
            {sortingOptions.map((item) => (
              <S.DropDownItem
                key={item.value}
                onClick={() => props.setSort(item.value)}
              >
                {props.sort === item.value && (
                  <S.Icon
                    src="CheckCircle"
                    width={20}
                    height={20}
                    color="secondary"
                  />
                )}

                <S.DropDownItemText color="secondary">
                  {item.label}
                </S.DropDownItemText>
              </S.DropDownItem>
            ))}
          </S.DropDown>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </S.Root>
  );
};
