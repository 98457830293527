import React, { useState } from 'react';
import { useControl } from '../../components/ControlLayout';
import { Loading } from '../../../../components/Loading';
import { Error } from '../../../../components/Error';
import { CompaniesTable } from './components/CompaniesTable';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { useNavigate, useParams } from 'react-router-dom';
import { S } from './CompaniesData.style';
import { useCompanies } from '../../components/ControlLayout/ControlLayout';

export const CompaniesData: React.FC = () => {
  const navigate = useNavigate();
  const control = useControl();
  const companies = useCompanies();

  const [page, setPage] = useState(1);

  const { state } = useParams();

  const navigateToCoverPage = () => {
    window.scrollTo(0, 0);
    navigate(`/kontroller/${state}/${control.data.inspectionId}/forsattsblad`);
  };

  if (companies.isLoading) {
    return <Loading />;
  }

  return (
    <S.Container>
      {companies.data && companies.data?.length > 0 ? (
        <S.TableContainer>
          {companies.isLoading && <Loading />}

          {companies.isError && <Error refetch={companies.refetch} />}

          {companies.isSuccess && (
            <React.Fragment>
              <S.Margin $spacing="xs" />

              <S.B3 $color="darkGray">{companies.data.length} st</S.B3>

              <CompaniesTable
                data={companies.data}
                loading={companies.isLoading}
                page={page}
                setPage={setPage}
              />
            </React.Fragment>
          )}
        </S.TableContainer>
      ) : (
        <S.EmptyContainer>
          <img
            src={Images.Empty}
            width={279}
            height={204}
            alt="no content available"
          />

          <S.TextContainer>
            <S.H5>Här var det tomt!</S.H5>

            <S.B2>
              Det kan bero på att datan har raderats i ett tidigare skede
            </S.B2>
          </S.TextContainer>
        </S.EmptyContainer>
      )}

      <S.Footer>
        <S.Button $width={289} onClick={navigateToCoverPage}>
          Nästa
        </S.Button>
      </S.Footer>
    </S.Container>
  );
};
