import React, { useState } from 'react';
import { useGetCompanyStatistics } from '../../../../traits/api/controls/useGetCompanyStatistics';
import { useControl } from '../../components/ControlLayout';
import { Loading } from '../../../../components/Loading';
import { Error } from '../../../../components/Error';
import { DeviationsTable } from './components/DeviationsTable';

import { S } from './PersonnelDeviations.styles';
import { SortDeviations } from './components/DeviationsTable/hooks/useDeviationsTableData';
import { DeviationsSortDropdown } from './components/DeviationsTableSort/DeviationsTableSortDropdown';

export const PersonnelDeviations: React.FC = () => {
  const [page, setPage] = useState(0);
  const control = useControl();
  const [sort, setSort] = useState<SortDeviations>('none');
  const statistics = useGetCompanyStatistics(
    control.data?.inspectionId,
    control.data?.controlId,
    page,
    sort
  );

  React.useEffect(() => {
    const onScroll = () => {
      localStorage.setItem('Dev', window.scrollY + '');
    };

    let scroll = Number(localStorage.getItem('Dev'));
    if (!scroll) {
      scroll = 0;
    }
    new Promise((r) => setTimeout(r, 200)).then(() => {
      window.scrollTo({
        top: scroll,
        left: 0,
      });
    });

    window.removeEventListener('s croll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <S.PersonnelDeviationsContainer>
      <S.TableContainer>
        {statistics.isLoading && <Loading />}

        {statistics.isError && <Error refetch={statistics.refetch} />}

        {statistics.isSuccess && (
          <React.Fragment>
            <S.Margin $spacing="xs" />

            <S.B3 $color="darkGray">{statistics.count} st</S.B3>
            <DeviationsSortDropdown
              sort={sort}
              setSort={(e) => {
                setSort(e);
              }}
            />
            <DeviationsTable
              data={statistics.data}
              page={page}
              setPage={setPage}
              numberOfPages={Math.ceil(statistics.count / 20)}
            />
          </React.Fragment>
        )}
      </S.TableContainer>

      <S.Footer />
    </S.PersonnelDeviationsContainer>
  );
};
