import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Input = styled.input`
  display: none;
`;

export const S = {
  ...UIElements,
  Input,
};
