import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetControl } from '../../../../../traits/api/controls/useGetControl';
import { nullsToUndefined } from '../../../../../traits/utils/nullsToUndefined';

export const summarySchema = z.object({
  shellProtectionProbability: z.number().optional(),
  shellProtectionConsequence: z.number().optional(),

  workEnvironmentProbability: z.number().optional(),
  workEnvironmentConsequence: z.number().optional(),

  workRegisterProbability: z.number().optional(),
  workRegisterConsequence: z.number().optional(),

  preApprovedSubcontractorProbability: z.number().optional(),
  preApprovedSubcontractorConsequence: z.number().optional(),

  workPermitProbability: z.number().optional(),
  workPermitConsequence: z.number().optional(),

  id06DeviationsProbability: z.number().optional(),
  id06DeviationsConsequence: z.number().optional(),

  identificationProbability: z.number().optional(),
  identificationConsequence: z.number().optional(),

  secondmentRegulationsProbability: z.number().optional(),
  secondmentRegulationsConsequence: z.number().optional(),

  intoxicationProbability: z.number().optional(),
  intoxicationConsequence: z.number().optional(),

  safetyEquipmentProbability: z.number().optional(),
  safetyEquipmentConsequence: z.number().optional(),

  otherProbability: z.number().optional(),
  otherConsequence: z.number().optional(),
});

export type SummaryFormValues = z.infer<typeof summarySchema>;

export const useSummaryForm = (inspectionId: string) => {
  const control = useGetControl(inspectionId);

  const methods = useForm<SummaryFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(summarySchema),
  });

  useEffect(() => {
    if (!control.data) return;

    const {
      shellProtectionConsequence,
      shellProtectionProbability,
      workEnvironmentConsequence,
      workEnvironmentProbability,
      workRegisterConsequence,
      workRegisterProbability,
      preApprovedSubcontractorConsequence,
      preApprovedSubcontractorProbability,
      workPermitConsequence,
      workPermitProbability,
      id06DeviationsConsequence,
      id06DeviationsProbability,
      identificationConsequence,
      identificationProbability,
      secondmentRegulationsConsequence,
      secondmentRegulationsProbability,
      intoxicationConsequence,
      intoxicationProbability,
      safetyEquipmentConsequence,
      safetyEquipmentProbability,
      otherConsequence,
      otherProbability,
    } = control.data;

    methods.reset(
      nullsToUndefined({
        shellProtectionConsequence,
        shellProtectionProbability,
        workEnvironmentConsequence,
        workEnvironmentProbability,
        workRegisterConsequence,
        workRegisterProbability,
        preApprovedSubcontractorConsequence,
        preApprovedSubcontractorProbability,
        workPermitConsequence,
        workPermitProbability,
        id06DeviationsConsequence,
        id06DeviationsProbability,
        identificationConsequence,
        identificationProbability,
        secondmentRegulationsConsequence,
        secondmentRegulationsProbability,
        intoxicationConsequence,
        intoxicationProbability,
        safetyEquipmentConsequence,
        safetyEquipmentProbability,
        otherConsequence,
        otherProbability,
      })
    );
  }, [control.isSuccess]);

  return methods;
};
