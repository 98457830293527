import { FindAllControlStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';

export const useStatisticsTableData = (
  statistics: FindAllControlStatisticsResponse
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Gällande individers efterlevnad av krav, lagar & föreskrifter',
        accessor: 'rules',
      },
      {
        Header: 'Antal',
        accessor: 'count',
      },
      {
        Header: '% för denna arbetsplats-kontroll',
        accessor: 'currentWorkplace',
      },
      {
        Header: '% jämfört mot tidigare kontroller på arbetsplatsen',
        accessor: 'previousControlWorkplace',
      },
      {
        Header: '% jämfört mot tidigare kontroller för uppdragsgivare',
        accessor: 'previousControlClient',
      },
      {
        Header: '% jämfört mot landet',
        accessor: 'country',
      },
    ] as const;
  }, []);

  const addPercentageSymbol = (value?: number | null) => {
    if (value === null || value === undefined) {
      return '-';
    }

    return `${value}%`;
  };

  const data = useMemo(() => {
    if (!statistics.control) return [];
    return [
      {
        rules: 'Antal dokumenterade individer i EPL vid påbörjad kontroll',
        count: statistics.control.numberOfCheckedInPersonnel ?? '-',
        currentWorkplace: '-',
        previousControlWorkplace: '-',
        previousControlClient: '-',
        country: '-',
      },
      {
        rules: 'Antal dokumenterade individer i EPL vid avslutad kontroll',
        count: statistics.control.numberOfCheckedInPersonnelEnd ?? '-',
        currentWorkplace: '-',
        previousControlWorkplace: '-',
        previousControlClient: '-',
        country: '-',
      },
      {
        rules: 'Antal kontrollerade',
        count: statistics.control.numberOfControlledPersonnel ?? '-',
        currentWorkplace: '-',
        previousControlWorkplace: '-',
        previousControlClient: '-',
        country: '-',
      },
      {
        rules: 'Antal kontrollerade och inloggade i EPL',
        count:
          statistics.control.numberOfCheckedInAndControlledPersonnel ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfCheckedInAndControlledPersonnel
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfCheckedInAndControlledPersonnel
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfCheckedInAndControlledPersonnel
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfCheckedInAndControlledPersonnel
        ),
      },
      {
        rules: 'Antal kontrollerade, ej inloggade individer',
        count: statistics.control.numberOfAdditionalPersonnel ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfAdditionalPersonnel
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfAdditionalPersonnel
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfAdditionalPersonnel
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfAdditionalPersonnel
        ),
      },
      {
        rules: 'Antal inloggade enligt EPL, ej funna',
        count: statistics.control.numberOfNotControlledPersonnel ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfNotControlledPersonnel
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfNotControlledPersonnel
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfNotControlledPersonnel
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfNotControlledPersonnel
        ),
      },
      {
        rules: 'Antal kontrollerade med ID06-kort- avvikelser',
        count: statistics.control.numberOfPersonnelWithIdCardDeviations ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfPersonnelWithIdCardDeviations
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithIdCardDeviations
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithIdCardDeviations
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithIdCardDeviations
        ),
      },
      {
        rules: 'Antal kontrollerade individer utan Safe Construction Training',
        count:
          statistics.control.numberOfPersonnelWithoutSafeConstructionTraining ??
          '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control
            .percentageOfPersonnelWithoutSafeConstructionTraining
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project
            .percentageOfPersonnelWithoutSafeConstructionTraining
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutSafeConstructionTraining
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutSafeConstructionTraining
        ),
      },
      {
        rules: 'Antal kontrollerade individer som saknade giltig ID-handling',
        count:
          statistics.control.numberOfPersonnelWithInvalidIdentification ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfPersonnelWithInvalidIdentification
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithInvalidIdentification
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithInvalidIdentification
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithInvalidIdentification
        ),
      },
      {
        rules:
          'Antal kontrollerade individer med bristande personlig säkerhetsutrustning',
        count:
          statistics.control.numberOfPersonnelWithoutSafetyEquipment ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfPersonnelWithoutSafetyEquipment
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithoutSafetyEquipment
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutSafetyEquipment
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutSafetyEquipment
        ),
      },
      {
        rules: 'Antal kontrollerade individer som saknar AT/UT/PUT/AT-UND',
        count: statistics.control.numberOfPersonnelWithoutWorkPermit ?? '-',
        currentWorkplace: addPercentageSymbol(
          statistics.control.percentageOfPersonnelWithoutWorkPermit
        ),
        previousControlWorkplace: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithoutWorkPermit
        ),
        previousControlClient: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutWorkPermit
        ),
        country: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutWorkPermit
        ),
      },
    ];
  }, [statistics]);

  return { columns, data };
};
