import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Link = styled.a`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 4px 17px;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  font-family: 'Helvetica Neue';

  &:hover {
    background-color: #023e5210;
  }
`;

export const S = {
  ...UIElements,
  Link,
};
