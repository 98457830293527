import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { ProtectedRoute } from './special-routes/ProtectedRoute';
import { LayoutRoute } from './special-routes/LayoutRoute';
import { Login } from '../../features/login/screens/Login';
import { NotFound } from '../../features/exceptions/screens/NotFound';
import { NotAllowed } from '../../features/exceptions/screens/NotAllowed';
import { Controls } from '../../features/controls/screens/Controls';
import { ControlLayout } from '../../features/controls/components/ControlLayout';
import { ContactInfo } from '../../features/controls/screens/ContactInfo';
import { WorkRegister } from '../../features/controls/screens/WorkRegister';
import { FlyLeaf } from '../../features/controls/screens/FlyLeaf';
import { DataSummary } from '../../features/controls/screens/DataSummary';
import { ResultAndActions } from '../../features/controls/screens/ResultAndActions';
import { PersonnelDeviations } from '../../features/controls/screens/PersonnelDeviations';
import { FreeTextSummary } from '../../features/controls/screens/FreeTextSummary';
import { FaqOverview } from '../../features/faq/screens/FaqOverview';
import { FaqEditor } from '../../features/faq/screens/FaqEditor';
import { CreateFaq } from '../../features/faq/screens/CreateFaq';
import { CompaniesData } from '../../features/controls/screens/CompaniesData/CompaniesData';
import { NewsOverview } from '../../features/news/screens/NewsOverview';
import { NewsEditor } from '../../features/news/screens/NewsEditor';
import { CreateNews } from '../../features/news/screens/CreateNews';
import { Clients } from '../../features/clients/screens/overview';
import { Single } from '../../features/clients/screens/single';
import { RedListedCompanies } from '../../features/redListedCompanies';

export const Router: React.FC = () => {
  const { user, isTeamLead } = useAuth();

  return (
    <Routes>
      {!user && <Route path="/" element={<Login />} />}

      {user && !isTeamLead && (
        <Route path="/inte-tillåtet" element={<NotAllowed />} />
      )}

      {user && (
        <Route element={<ProtectedRoute />}>
          <Route element={<LayoutRoute />}>
            <Route index element={<Navigate to="kontroller/open" replace />} />

            <Route path="kontroller/:state">
              <Route index element={<Controls />} />

              <Route path=":inspectionId" element={<ControlLayout />}>
                <Route
                  index
                  element={<Navigate to="kontaktuppgifter" replace />}
                />

                <Route path="kontaktuppgifter" element={<ContactInfo />} />

                <Route path="persondata" element={<WorkRegister />} />

                <Route path="underentreprenorer" element={<CompaniesData />} />

                <Route
                  path="uppdragsbeskrivning"
                  element={<FreeTextSummary />}
                />

                <Route path="forsattsblad" element={<FlyLeaf />} />

                <Route path="bedomning" element={<DataSummary />} />

                <Route path="resultat" element={<ResultAndActions />} />

                <Route path="avvikelser" element={<PersonnelDeviations />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />

            <Route path="faq">
              <Route index element={<FaqOverview />} />

              <Route path=":faqId" element={<FaqEditor />} />

              <Route path="ny" element={<CreateFaq />} />
            </Route>

            <Route path="news">
              <Route index element={<NewsOverview />} />

              <Route path=":news" element={<NewsEditor />} />

              <Route path="ny" element={<CreateNews />} />
            </Route>

            <Route path="kunder">
              <Route index element={<Clients />} />

              <Route path=":clientId" element={<Single />} />
              {/* 
              <Route path="ny" element={<CreateNews />} /> */}
            </Route>

            <Route path="rodlistade-bolag">
              <Route index element={<RedListedCompanies />} />
            </Route>
          </Route>
        </Route>
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
