import React, { MouseEvent } from 'react';
import { Dialog } from '../../../../components/Dialog';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { useDeleteQuestion } from '../../../../traits/api/faq/useDeleteQuestion';

import { S } from './DeleteDialog.styles';

type Props = {
  faqId: string;
  onClose: () => void;
};

export const DeleteDialog: React.FC<Props> = (props) => {
  const DeleteQuestion = useDeleteQuestion();

  const onCancel = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    props.onClose();
  };

  return (
    <Dialog.Base onClose={props.onClose}>
      <img src={Images.Delete} width={295} height={215} />

      <Dialog.Title>
        <S.H5>Är du säker på att du vill radera frågan?</S.H5>
      </Dialog.Title>

      <Dialog.Description>
        <S.H6>Frågan kommer inte kunna återställas.</S.H6>
      </Dialog.Description>

      <S.ButtonContainer>
        <Dialog.CancelButton>
          <S.Button $outlined onClick={onCancel}>
            Avbryt
          </S.Button>
        </Dialog.CancelButton>

        <Dialog.Button>
          <S.Button
            disabled={DeleteQuestion.isLoading}
            onClick={(e) => {
              e.stopPropagation();
              DeleteQuestion.mutate(props.faqId);
            }}
          >
            Ja, radera
          </S.Button>
        </Dialog.Button>
      </S.ButtonContainer>
    </Dialog.Base>
  );
};
