import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import * as Accordion from '@radix-ui/react-accordion';

const FlyLeafContainer = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
`;

const FlyLeafInner = styled(Accordion.Root)`
  width: 850px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 72px;
`;

const Body = styled(Accordion.Item)`
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 1px 6px 0px rgba(27, 27, 27, 0.05);
`;

const Header = styled(Accordion.Header)`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #cbcbcb;
`;

const BodyInner = styled(Accordion.Content)`
  padding: 16px 24px 29px;
  display: grid;
  gap: 16px;
`;

const Footer = styled.section`
  display: flex;
  gap: 24px;
  flex-grow: 1;
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

export const S = {
  ...UIElements,
  FlyLeafContainer,
  FlyLeafInner,
  Body,
  Footer,
  Header,
  BodyInner,
  Label,
  Wrapper,
};
