import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';

import { S } from './DateTimePicker.styles';
import { FlyleafFormValues } from '../../../../components/ControlLayout/hooks/useFlyleafForm';

type Props = {
  label: string;
  name: 'date' | 'completedAt';
  control: Control<FlyleafFormValues>;
};

export function DateTimePicker(props: Props) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <S.Wrapper>
          <S.Label>{props.label}</S.Label>
          <DesktopDateTimePicker
            format="MM/dd/yyyy HH:mm"
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
          />
        </S.Wrapper>
      )}
    />
  );
}
