import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';

type Options = {
  onDeleteFaqFileSuccess?: () => void;
  onDeleteFaqFileError?: (error: unknown) => void;
};

export function useDeleteFaqFile(options?: Options) {
  const getApi = useApi();

  const deleteFaqFile = async (faqFileId: string) => {
    const api = await getApi();

    const res = await api.faqFilesDelete(faqFileId);
    return res.data;
  };

  const onSuccess = () => {
    options?.onDeleteFaqFileSuccess?.();
  };

  const onError = (error: unknown) => {
    options?.onDeleteFaqFileError?.(error);
  };

  const mutation = useMutation(deleteFaqFile, {
    onError,
    onSuccess,
  });

  return mutation;
}
