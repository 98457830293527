import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreateControlResponse,
  UpdateControlDto,
} from '@ansvar-sakerhet/api-client';
import { controlKeys } from './controls.key';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';

type Options = {
  onPutControlSuccess?: (data: CreateControlResponse) => void;
  onPutControlError?: (error: unknown) => void;
};

export function usePutControl(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const putControl = async (data: UpdateControlDto & { controlId: string }) => {
    const api = await getApi();
    const { controlId, ...body } = data;
    const res = await api.controlsUpdate(controlId, body);
    return res.data;
  };

  const onSuccess = (data: CreateControlResponse) => {
    queryClient.invalidateQueries({ queryKey: controlKeys.all });
    options?.onPutControlSuccess?.(data);
  };

  const onError = (error: unknown) => {
    toast.error('Kunde inte spara utkast');
    options?.onPutControlError?.(error);
  };

  const mutation = useMutation(putControl, {
    onSuccess,
    onError,
  });

  return mutation;
}
