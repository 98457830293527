import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { CreateRedListedCompany } from '@ansvar-sakerhet/api-client';
import { toast } from '../../../components/Toast';
import { AxiosError } from 'axios';

export function useCreateRedListedCompany() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: CreateRedListedCompany) => {
    const api = await getApi();

    const res = await api.redListedCompaniesCreateRedListedCompany(data);
    await queryClient.invalidateQueries();

    return res.data;
  };

  const query = useMutation({
    mutationFn,
    onSuccess: () => toast.success('Rödlistat företag skapat'),
    onError: (e) => toast.error(extractError(e)),
  });

  return {
    ...query,
  };
}

function extractError(e: unknown): string {
  if (e instanceof AxiosError) return e.response?.data?.message;

  if (typeof e === 'string') return e;

  return 'Lyckades inte skapa rödlistat bolag';
}
