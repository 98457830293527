import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

type Props = React.PropsWithChildren;

export const Description: React.FC<Props> = (props) => {
  return (
    <AlertDialog.Description asChild>{props.children}</AlertDialog.Description>
  );
};
