import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetControl } from '../../../../../traits/api/controls/useGetControl';
import { useEffect } from 'react';
import { nullsToUndefined } from '../../../../../traits/utils/nullsToUndefined';

export const contactDataSchema = z.object({
  creatorName: z.string().optional(),
  mainContractor: z.string().optional(),
  siteManager: z.string().optional(),
  isCompleted: z.boolean().optional(),
  mainContractorCompanyId: z.string().optional(),
});

export type ContactDetailsValues = z.infer<typeof contactDataSchema>;

export const useContactDetailsForm = (inspectionId: string) => {
  const control = useGetControl(inspectionId);

  const methods = useForm<ContactDetailsValues>({
    mode: 'onTouched',
    resolver: zodResolver(contactDataSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (!control.data) return;

    const {
      mainContractor,
      creatorName,
      siteManager,
      isCompleted,
      mainContractorCompanyId,
    } = control.data;

    methods.reset(
      nullsToUndefined({
        creatorName: creatorName,
        mainContractor: mainContractor,
        siteManager: siteManager,
        isCompleted: isCompleted,
        mainContractorCompanyId: mainContractorCompanyId,
      })
    );
  }, [control.isSuccess]);

  return methods;
};
