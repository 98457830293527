import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import * as Accordion from '@radix-ui/react-accordion';

const DataSummaryContainer = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
`;

const DataSummaryInner = styled(Accordion.Root)<{ paddingbottom?: number }>`
  width: 850px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: ${(props) => props.paddingbottom || 0}px;
`;

const Body = styled(Accordion.Item)`
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 1px 6px 0px rgba(27, 27, 27, 0.05);
`;

const Header = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid #cbcbcb;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

const BodyInner = styled(Accordion.Content)`
  padding: 24px 24px 29px;
  display: grid;
  gap: 16px;
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin: 0 8px;
  transition: transform 300ms;
`;

const Trigger = styled(Accordion.Trigger)`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  border: none;
  border-bottom: 1px solid #cbcbcb;
  background-color: transparent;
  &[data-state='open'] {
    ${AnimatedIcon} {
      transform: rotate(180deg);
    }
  }
`;

const Footer = styled.section`
  display: flex;
  gap: 24px;
  flex-grow: 1;
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

export const S = {
  ...UIElements,
  DataSummaryContainer,
  DataSummaryInner,
  Body,
  Footer,
  Header,
  BodyInner,
  Label,
  Wrapper,
  AnimatedIcon,
  Trigger,
  FlexRow,
};
