import React from 'react';
import { DeleteQuestion } from '../DeleteQuestion';
import { useNavigate } from 'react-router-dom';

import { S } from './FaqListItem.styles';

type Props = {
  faqId: string;
  faqQuestion: string;
};

export const FaqListItem: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const navigateToFaqEditor = (faqId: string) => {
    navigate(`${faqId}`);
  };
  return (
    <S.FaqListItem onClick={() => navigateToFaqEditor(props.faqId)}>
      {props.faqQuestion}
      <DeleteQuestion faqId={props.faqId} />
    </S.FaqListItem>
  );
};
