import {
  BackgroundControlFileWithFile,
  BackgroundControlWithFiles,
} from '@ansvar-sakerhet/api-client';

export const findLatestReport = (
  backgroundControl: BackgroundControlWithFiles
): BackgroundControlFileWithFile | undefined => {
  return backgroundControl.reportFiles.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  })[0];
};
