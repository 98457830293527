import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';
import { newsKeys } from './news.key';

type Options = {
  onDeleteQuestionSuccess?: () => void;
  onDeleteQuestionError?: (error: unknown) => void;
};

export function useDeleteNews(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const deleteNews = async (newsId: string) => {
    const api = await getApi();

    const res = await api.newsDelete(newsId);
    return res.data;
  };

  const onSuccess = () => {
    toast.success('Nyheten har raderats');
    queryClient.invalidateQueries({ queryKey: newsKeys.all });
    options?.onDeleteQuestionSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Error, nyheten gick inte att radera');
    options?.onDeleteQuestionError?.(error);
  };

  const mutation = useMutation(deleteNews, {
    onError,
    onSuccess,
  });

  return mutation;
}
