import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import { S as ControlsTableDeleteButtonStyles } from '../ControlsTableDeleteButton/ControlsTableDeleteButton.styles';

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
`;

const Table = styled.table`
  width: calc(100% + 32px);
  transform: translateX(-16px);

  tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  tr:first-child td {
    border-top-style: solid;
  }
  tr td:first-child {
    border-left-style: solid;
  }
`;

const THead = styled.thead``;

const THeadRow = styled.tr`
  height: 42px;
`;

const THeadCell = styled.th`
  padding: 16px;
  padding-bottom: 4px;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

const TBody = styled.tbody``;

const TBodyRow = styled.tr`
  position: relative;
  cursor: pointer;
  height: 58px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryLight}99;

    ${ControlsTableDeleteButtonStyles.DeleteDataCell} {
      opacity: 1;
    }
  }
`;

const TBodyCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
`;

const isRead = styled.div<{
  $isRead: boolean;
  $index: number;
}>`
  position: absolute;
  left: -42px;
  top: ${({ $index }) => $index * 58 + 68}px;
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background-color: ${({ theme, $isRead }) =>
    $isRead ? theme.colors.warning : theme.colors.success};
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const DeleteDataCell = styled.td`
  vertical-align: middle;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  min-width: 210px;
`;

export const S = {
  ...UIElements,
  TableContainer,
  Table,
  THead,
  THeadRow,
  THeadCell,
  TBody,
  TBodyRow,
  TBodyCell,
  isRead,
  PaginationContainer,
  DeleteDataCell,
};
