import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useApi } from '../useApi';
import { workRegisterKeys } from './workRegisters.key';

export type SortPersonnel =
  | 'firstName_ASC'
  | 'firstName_DESC'
  | 'lastName_ASC'
  | 'lastName_DESC'
  | 'companyName_ASC'
  | 'companyName_DESC'
  | 'special'
  | 'no_safe';

export type FilterPersonnel =
  | 'none'
  | 'NO_EPL'
  | 'ID06_CARD'
  | 'ID_CARD'
  | 'NO_SAFE'
  | 'AT_UT'
  | 'SAFTEY'
  | 'A1_CERTIFICATE';

export function useGetAllPersonnel(
  controlId: string,
  workRegisterId: string | undefined,
  page: number,
  sort: SortPersonnel = 'firstName_ASC',
  filter: FilterPersonnel = 'none'
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getAllPersonnel = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    if (!workRegisterId) return Promise.resolve([]);

    const res = await api.personnelFindAll(
      workRegisterId,
      take,
      skip,
      sort,
      filter
    );

    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };
  const query = useQuery({
    queryKey: workRegisterKeys.personnelFindMany(
      controlId,
      {
        page,
        sort,
      },
      filter
    ),
    queryFn: getAllPersonnel,
  });

  return {
    ...query,
    count,
  };
}
