import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { CreateBackgroundControlDto } from '@ansvar-sakerhet/api-client';
import { toast } from '../../../components/Toast';
import { AxiosError } from 'axios';

export function useCreateBackgroundControl() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: CreateBackgroundControlDto) => {
    const api = await getApi();

    const res = await api.backgroundControlsCreate(data);
    await queryClient.invalidateQueries();

    return res.data;
  };

  const query = useMutation({
    mutationFn,
    onSuccess: () => toast.success('Bakgrundskontroll skapad'),
    onError: (e) => toast.error(extractError(e)),
  });

  return {
    ...query,
  };
}

function extractError(e: unknown) {
  if (e instanceof AxiosError) {
    return e.response?.data?.message;
  }
  return 'Okänt fel';
}
