import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Organisationsnummer from 'organisationsnummer';
import { Client } from '@ansvar-sakerhet/api-client';

export const customerInfoForm = z.object({
  registrationNumber: z
    .string()
    .refine((val) => Organisationsnummer.valid(val), {
      message: 'Org.nr är inte giltigt',
    }),
});

export type customerInfoFormValues = z.infer<typeof customerInfoForm>;

export const useCustomerInfoForm = (client: Client) => {
  const methods = useForm<customerInfoFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(customerInfoForm),
    defaultValues: {
      registrationNumber: client.registrationNumber ?? '',
    },
  });

  return methods;
};
