import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const SearchInput = styled.input`
  padding: 5px;
  border-radeius: 5px;
  border: none;
  font-size: 16px;
  outline: none;
  width: 100%;
  background-color: transparent;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0 2px 8px;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 5px;
`;

export const S = { ...UIElements, SearchInput, SearchContainer };
