import React from 'react';
import { S } from './DeviationsTableSubRow.styles';

type Props = {
  companyNationality: string | null;
  numberOfCheckedInPersonnel: number;
  numberOfAdditionalPersonnel: number;
  numberOfControlledPersonnel: number;
  numberOfNoSafePersonnel: number;
  numberOfNoId: number;
  numerOfId06Card: number;
  numberOfWithoutSafteyEquipment: number;
  numberOfPersonnelWithoutValidA1Certificate: number;
};

export const DeviationsTableSubRow: React.FC<Props> = (props) => {
  return (
    <S.DeviationsTableSubRowContainer>
      <S.DeviationsTableSubRowInner>
        <S.H6>Land</S.H6>
        <S.B3>{props.companyNationality ?? '-'}</S.B3>

        <S.H6>Antal inloggade</S.H6>
        <S.B3>{props.numberOfCheckedInPersonnel}</S.B3>

        <S.H6>Antal kontrollerade</S.H6>
        <S.B3>{props.numberOfControlledPersonnel}</S.B3>

        <S.H6>Antal ej inloggade</S.H6>
        <S.B3>{props.numberOfAdditionalPersonnel}</S.B3>

        <S.H6>Antal kontrollerade med ID06-kort- avvikelser</S.H6>
        <S.B3>{props.numerOfId06Card}</S.B3>

        <S.H6>
          Antal kontrollerade individer utan Safe Construction Training{' '}
        </S.H6>
        <S.B3>{props.numberOfNoSafePersonnel}</S.B3>

        <S.H6>
          Antal kontrollerade individer som saknade giltig ID-handling
        </S.H6>
        <S.B3>{props.numberOfNoId}</S.B3>

        <S.H6>
          Antal kontrollerade individer med bristande personlig
          säkerhetsutrustning{' '}
        </S.H6>
        <S.B3>{props.numberOfWithoutSafteyEquipment}</S.B3>
        <S.H6>Saknar A1-intyg </S.H6>
        <S.B3>{props.numberOfPersonnelWithoutValidA1Certificate}</S.B3>
      </S.DeviationsTableSubRowInner>
    </S.DeviationsTableSubRowContainer>
  );
};
