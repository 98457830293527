import React, { useState } from 'react';
import { S } from './WorkRegisterTableSubRow.styles';
import { useControl } from '../../../../components/ControlLayout';
import { useGetPersonnel } from '../../../../../../traits/api/workRegisters/useGetPersonnel';
import { useGetPersonnelFiles } from '../../../../../../traits/api/workRegisters/useGetPersonnelFiles';
import { useGetPersonnelID06Competences } from '../../../../../../traits/api/workRegisters/useGetPersonnelID06Competences';
import { Spinner } from '../../../../../../components/Spinner';
import { EditPersonnelModal } from '../EditPersonnelModal';
import { format } from 'date-fns';
import { DialogTrigger } from '../DialogTrigger';
import * as Dialog from '@radix-ui/react-dialog';

type Props = {
  personnelId: string;
};

export const WorkRegisterTableSubRow: React.FC<Props> = (props) => {
  const { data } = useControl();
  const [isOpen, setIsOpen] = useState(false);
  const personnel = useGetPersonnel(props.personnelId, data.controlId);
  const files = useGetPersonnelFiles(props.personnelId, data.controlId);
  const competences = useGetPersonnelID06Competences(
    props.personnelId,
    data.controlId
  );

  const refetchAll = () => {
    personnel.refetch();
    files.refetch();
    competences.refetch();
  };

  const questionnaireAnswerMap = {
    Yes: 'Ja',
    No: 'Nej',
    Unclear: 'Oklart',
    NotApplicable: 'Ej tillämpbart',
    '-': '-',
    Passport: 'Pass',
    NationalId: 'Nationellt ID',
    DriverLicense: 'Körkort',
    SKVID: 'Skatteverkets id-kort',
  };

  if (personnel.isLoading) {
    return (
      <S.LoadingContainer>
        <Spinner width={18} color="darkGray" />
      </S.LoadingContainer>
    );
  }

  if (personnel.isError) {
    return (
      <S.ErrorContainer>
        <S.B2 $color="error">Gick inte att hämta persondata.</S.B2>

        <S.Button
          $height={38}
          $width={120}
          $fontSize={14}
          $outlined
          onClick={refetchAll}
        >
          Försök igen
        </S.Button>
      </S.ErrorContainer>
    );
  }

  return (
    <S.WorkRegisterTableSubRowContainer>
      <S.PersonnelContainer>
        <S.H6>Ankomst</S.H6>
        <S.B3>
          {personnel.data.arrivedAt
            ? format(
                new Date(personnel.data.arrivedAt),
                "yyyy/MM/dd 'kl.'HH:mm"
              )
            : '-'}
        </S.B3>

        <S.H6>Status</S.H6>
        <S.B3>{personnel.data.id06ReasonText ?? '-'}</S.B3>

        <S.H6>Status Kod</S.H6>
        <S.B3>{personnel.data.id06Card?.reasonCode ?? '-'}</S.B3>

        <S.H6>Nationalitet</S.H6>
        <S.B3>{personnel.data.id06Card?.cardHolderNationality ?? '-'}</S.B3>

        <S.H6>ID06 giltighet</S.H6>
        <S.B3>
          {personnel.data.id06Card?.expirationDate
            ? format(
                new Date(personnel.data.id06Card?.expirationDate),
                'yyyy/MM/dd'
              )
            : '-'}
        </S.B3>

        <S.H6>Utbildningar</S.H6>
        <S.B3>
          {competences.data?.length
            ? competences.data.map((company) => company.name).join(', ')
            : '-'}
        </S.B3>

        <S.H6>Anteckningar</S.H6>
        <S.B3>{personnel.data.id06Card?.comment ?? '-'}</S.B3>

        <S.H6>Foton</S.H6>

        {files.data?.length ? (
          <S.ImageContainer>
            {files.data.map((file) => (
              <>
                <S.Image
                  key={file.fileId}
                  src={file.file.url}
                  alt={file.file.uniqueName}
                />
                <S.B4>{file.comment}</S.B4>
              </>
            ))}
          </S.ImageContainer>
        ) : (
          <S.B3>-</S.B3>
        )}
        <S.H6>Kontrollerad</S.H6>
        <S.B3>
          {personnel.data.scannedAt
            ? format(
                new Date(personnel.data.scannedAt),
                "yyyy/MM/dd 'kl.'HH:mm"
              )
            : '-'}
        </S.B3>

        <S.H6>Kontrollerad av</S.H6>
        <S.B3>{personnel.data.scannedEmail}</S.B3>
      </S.PersonnelContainer>

      <S.Divider />

      <S.QuestionnaireContainer>
        <span>
          <S.H6 $bottom="s">Finns ID handling?</S.H6>
          <S.B3>
            {
              questionnaireAnswerMap[
                personnel.data.questionnaire?.hasIdCard ?? '-'
              ]
            }
          </S.B3>
        </span>

        <span>
          <S.H6 $bottom="s">Typ av ID handling</S.H6>
          <S.B3>
            {
              questionnaireAnswerMap[
                personnel.data.questionnaire?.typeOfId ?? '-'
              ]
            }
          </S.B3>
        </span>

        <span>
          <S.H6 $bottom="s">Är skyddsutrustningen godkänd?</S.H6>
          <S.B3>
            {
              questionnaireAnswerMap[
                personnel.data.questionnaire?.hasSafetyEquipment ?? '-'
              ]
            }
          </S.B3>
        </span>

        <span>
          <S.H6 $bottom="s">Finns A1-intyg? (vid utländskt bolag)</S.H6>
          <S.B3>
            {
              questionnaireAnswerMap[
                personnel.data.questionnaire?.canShowValidA1Certificate ?? '-'
              ]
            }
          </S.B3>
        </span>

        <span>
          <S.H6 $bottom="s">Finns arbetstillstånd?</S.H6>
          <S.B3>
            {
              questionnaireAnswerMap[
                personnel.data.questionnaire?.canShowValidWorkPermit ?? '-'
              ]
            }
          </S.B3>
        </span>

        <span>
          <S.H6 $bottom="s">Finns tecken på onykterhet/drogpåverkan?</S.H6>
          <S.B3>
            {
              questionnaireAnswerMap[
                personnel.data.questionnaire?.hasSignsOfIntoxication ?? '-'
              ]
            }
          </S.B3>
        </span>

        <span>
          <S.H6 $bottom="s">Kommentar</S.H6>
          <S.B3>{personnel.data.questionnaire?.comment ?? '-'}</S.B3>
        </span>
      </S.QuestionnaireContainer>

      <S.EditButtonContainer>
        <Dialog.Root open={isOpen}>
          <DialogTrigger onClick={() => setIsOpen(true)} />
          {isOpen && (
            <EditPersonnelModal
              personnelId={personnel.data.personnelId}
              onCancel={() => setIsOpen(false)}
            />
          )}
        </Dialog.Root>
      </S.EditButtonContainer>
    </S.WorkRegisterTableSubRowContainer>
  );
};
