import React from 'react';

import { S } from './Mailto.styles';

type Props = {
  email: string;
  subject?: string;
  body?: string;
  children: React.ReactNode;
};

export const MailTo: React.FC<Props> = (props) => {
  return (
    <S.Link
      href={`mailto:${props.email}?subject=${
        encodeURIComponent(props.subject || '') || ''
      }&body=${encodeURIComponent(props.body || '') || ''}`}
    >
      {props.children}
    </S.Link>
  );
};
