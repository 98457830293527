import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
`;

const Table = styled.table`
  width: calc(100% + 32px);
  transform: translateX(-16px);

  tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  tr:first-child td {
    border-top-style: solid;
  }
  tr td:first-child {
    border-left-style: solid;
  }
`;

const THead = styled.thead``;

const THeadRow = styled.tr`
  height: 42px;
`;

const THeadCell = styled.th`
  padding: 16px;
  padding-bottom: 4px;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

const TBody = styled.tbody``;

const TBodyRow = styled.tr`
  position: relative;
  cursor: pointer;
  height: 58px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryLight}99;
  }
`;

const TBodyCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin: 0 8px;
  transition: transform 300ms;
`;

const TriggerWrapper = styled.td`
  vertical-align: middle;
`;

const Trigger = styled.button<{ $isExpanded: boolean }>`
  all: unset;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${AnimatedIcon} {
    transform: rotate(
      ${({ $isExpanded }) => ($isExpanded ? '180deg' : '0deg')}
    );
  }
`;

export const S = {
  ...UIElements,
  TableContainer,
  Table,
  THead,
  THeadRow,
  THeadCell,
  TBody,
  TBodyRow,
  TBodyCell,
  AnimatedIcon,
  TriggerWrapper,
  Trigger,
};
