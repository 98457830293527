import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { workRegisterKeys } from './workRegisters.key';

export function useGetPersonnelFiles(personnelId: string, controlId: string) {
  const getApi = useApi();

  const getPersonnelFiles = async () => {
    const api = await getApi();

    const res = await api.personnelFindOneFiles(personnelId);
    return res.data;
  };

  const query = useQuery({
    queryKey: workRegisterKeys.personnelFindOneFiles(controlId, personnelId),
    queryFn: getPersonnelFiles,
  });

  return query;
}
