import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-bottom: 16px;
`;

const Input = styled.input`
  border: 4px solid white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  appearance: none;
  outline: 1.5px solid #023e52;
  cursor: pointer;

  &:checked {
    background-color: #023e52;
  }
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 400;
`;

const FlexView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 0;
`;

const RadioRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const RadioRowInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
`;

export const S = {
  ...UIElements,
  Wrapper,
  Input,
  Label,
  FlexView,
  RadioRowContainer,
  RadioRowInner,
};
