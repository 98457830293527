import React from 'react';
import { Client } from '@ansvar-sakerhet/api-client';
import { Input } from '../../../../../../../../../../components/Input';
import {
  customerInfoFormValues,
  useCustomerInfoForm,
} from './hooks/useCustomerInfoForm';
import { Button } from '@ansvar-sakerhet/ui/src/buttons/Button';
import { S } from './CustomerInfoForm.styles';
import { useUpdateClient } from '../../../../../../../../../../traits/api/clients/useUpdateClient';

type Props = { client: Client };

export const CustomerInfoForm = ({ client }: Props) => {
  const updateClient = useUpdateClient(client.clientId);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useCustomerInfoForm(client);
  const onSubmit = (data: customerInfoFormValues) => updateClient.mutate(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Org.nr"
        error={errors.registrationNumber}
        {...register('registrationNumber')}
      />
      <S.Margin $top="m" />
      <Button type="submit">Spara</Button>
    </form>
  );
};
