import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const LoadingContainer = styled.div`
  padding: 16px;
`;

const ErrorContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WorkRegisterTableSubRowContainer = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
`;

const Divider = styled.div`
  width: 20px;
  min-height: 200px;
  height: 100%;
`;

const PersonnelContainer = styled.div`
  display: grid;
  padding: 16px;
  grid-template-columns: 1fr 4fr;
  gap: 20px;
  width: 100%;
  margin-bottom: auto;
`;

const QuestionnaireContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    left: -16px;
    top: 16px;
    bottom: 16px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;

const EditButtonContainer = styled.div`
  padding: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 8px 0;
  width: 100%;
  max-width: 328px;
`;

const Image = styled.img`
  width: 150px;
  height: auto;
  overflow: hidden;
  object-fit: scale;
  border-radius: 2px;
`;

export const S = {
  ...UIElements,
  LoadingContainer,
  ErrorContainer,
  WorkRegisterTableSubRowContainer,
  Divider,
  PersonnelContainer,
  QuestionnaireContainer,
  ImageContainer,
  Image,
  EditButtonContainer,
};
