import { useEffect } from 'react';
import { useStartReport } from '../../../../../traits/api/controls/useStartReport';
import { useGetControl } from '../../../../../traits/api/controls/useGetControl';

export const useMarkReportAsStarted = (inspectionId: string) => {
  const startReport = useStartReport(inspectionId);
  const control = useGetControl(inspectionId);

  useEffect(() => {
    if (!control.data) return;
    if (control.data.reportStatedAt) return;
    startReport.mutate({ controlId: control.data.controlId });
  }, [control.isSuccess]);
};
