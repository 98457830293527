import React from 'react';

import { S } from './AccordionHeader.styles';

type Props = {
  title: string;
};

export const AccordionHeader: React.FC<Props> = (props) => {
  return (
    <S.Trigger>
      <S.FlexRow>
        <S.Icon src="Edit" />
        <S.H6>{props.title}</S.H6>
      </S.FlexRow>

      <S.AnimatedIcon src="ChevronDown" />
    </S.Trigger>
  );
};
