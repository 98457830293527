const all = ['clients'] as const;

const findOne = (clientId: string) => {
  return [...all, clientId] as const;
};

const findAll = (props?: {
  search?: string | undefined;
  take?: number | undefined;
  skip?: number | undefined;
}) => [...all, props?.search, props?.take, props?.skip];

export const clientKeys = {
  all,
  findOne,
  findAll,
};
