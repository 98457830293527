import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateFaqDto, CreateFaqResponse } from '@ansvar-sakerhet/api-client';
import { useApi } from '../useApi';
import { faqKeys } from './faq.key';
import { toast } from '../../../components/Toast';

type Options = {
  onCreateQuestionSuccess?: (data: CreateFaqResponse) => void;
  onCreateQuestionError?: (error: unknown) => void;
};

export function useCreateQuestion(options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const createQuestion = async (data: CreateFaqDto) => {
    const api = await getApi();

    const res = await api.faqsCreate({
      answer: data.answer,
      question: data.question,
      faqCategory: data.faqCategory,
    });

    return res.data;
  };

  const onSuccess = (data: CreateFaqResponse) => {
    toast.success('Frågan har lagts till');
    queryClient.invalidateQueries({ queryKey: faqKeys.all });
    options?.onCreateQuestionSuccess?.(data);
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel, frågan gick inte att lägga till i FAQ');

    options?.onCreateQuestionError?.(error);
  };

  const mutation = useMutation(createQuestion, {
    onSuccess,
    onError,
  });

  return mutation;
}
