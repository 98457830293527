import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const DeviationsTableSubRowContainer = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
`;

const DeviationsTableSubRowInner = styled.div`
  display: grid;
  padding: 16px;
  grid-template-columns: 1fr 4fr;
  gap: 20px;
  width: 100%;
  margin-bottom: auto;
`;

export const S = {
  ...UIElements,
  DeviationsTableSubRowContainer,
  DeviationsTableSubRowInner,
};
