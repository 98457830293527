import React from 'react';
import { useTable } from 'react-table';
import { useDeviationTableData } from '../../hooks/useDeviationTableData';
import { useSummaryFormContext } from '../../../../components/ControlLayout/ControlLayout';
import { Controller } from 'react-hook-form';

import { S } from './DeviationTable.styles';

export const DeviationTable: React.FC = () => {
  const data = useDeviationTableData();
  const table = useTable(data);

  const { control } = useSummaryFormContext();

  const restrainInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const latestValue = e.target.value[e.target.value.length - 1];

    if (isNaN(Number(latestValue))) {
      return e.target.value.length > 1
        ? e.target.value.slice(0, -1)
        : undefined;
    }

    if (Number(latestValue) > 5) {
      return 5;
    }
    if (Number(latestValue) <= 0) {
      return 1;
    }
    return Number(latestValue);
  };

  return (
    <S.TWrapper>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.TBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  if (
                    cell.column.id === 'probability' ||
                    cell.column.id === 'consequence'
                  ) {
                    return (
                      <S.TBodyCell key={cell.getCellProps().key} $padding="0">
                        <Controller
                          name={cell.value}
                          control={control}
                          render={({ field }) => (
                            <S.TInput
                              {...field}
                              onChange={(e) => {
                                field.onChange(restrainInputValue(e));
                              }}
                              type="number"
                              min="1"
                              max="5"
                              placeholder="Fyll i värde"
                            />
                          )}
                        />
                      </S.TBodyCell>
                    );
                  }
                  return (
                    <S.TBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.TBodyCell>
                  );
                })}
              </S.TBodyRow>
            );
          })}
        </S.TBody>
      </S.Table>
    </S.TWrapper>
  );
};
