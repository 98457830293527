import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { controlKeys } from './controls.key';
import { SortDeviations } from '../../../features/controls/screens/PersonnelDeviations/components/DeviationsTable/hooks/useDeviationsTableData';

export function useGetCompanyStatistics(
  inspectionId: string,
  controlId: string,
  page: number,
  sort: SortDeviations = 'none'
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getControl = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    const res = await api.companyStatisticsFindAll(controlId, sort, take, skip);

    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: controlKeys.findCompanyStatistics(inspectionId, { page, sort }),
    queryFn: getControl,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
