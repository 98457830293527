import React from 'react';
import { S } from './ContactInfo.styles';
import { useWorkRegisterUpload } from './hooks/useWorkRegisterUpload';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { Input } from '../../../../components/Input';
import { Select } from '../../../../components/Select';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useControl,
  useContactDetailsContext,
  useFlyleafFormContext,
} from '../../components/ControlLayout';
import { useMainContractorCompanyOptions } from './hooks/useMainContractorCompanyOptions';
import { DateTimePicker } from '../FlyLeaf/components/DateTimePicker';

export const ContactInfo: React.FC = () => {
  const navigate = useNavigate();
  const workRegisterUpload = useWorkRegisterUpload();
  const { data } = useControl();
  const { register, setValue, getValues } = useContactDetailsContext();
  const { state } = useParams();
  const mainContractorCompanyOptions = useMainContractorCompanyOptions(
    data.companies
  );
  const [isCompleted, setIsCompleted] = React.useState(
    getValues().isCompleted || false
  );

  React.useEffect(() => {
    const onScroll = () => {
      localStorage.setItem('CI', window.scrollY + '');
    };

    let scroll = Number(localStorage.getItem('CI'));
    if (!scroll) {
      scroll = 0;
    }
    new Promise((r) => setTimeout(r, 200)).then(() => {
      window.scrollTo({
        top: scroll,
        left: 0,
      });
    });

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const {
    register: flyLeafRegister,
    trigger,
    control,
    formState: { errors },
  } = useFlyleafFormContext();

  const navigateToPersonalData = async () => {
    const isValid = await trigger();
    if (!isValid) return;
    navigate(`/kontroller/${state}/${data.inspectionId}/persondata`);
  };

  register('isCompleted');
  const setDone = () => {
    const n = !getValues().isCompleted;
    setValue('isCompleted', n);
    setIsCompleted(n);
  };
  return (
    <React.Fragment>
      {data.gdprDataDeletedAt || data?.workRegister ? (
        <S.ControlContainer>
          <S.ControlInnerContainer>
            <S.Row>
              <Input label="Projektledare" {...register('creatorName')} />
            </S.Row>
            <S.Row>
              <Input label="Huvudentrepenör" {...register('mainContractor')} />
            </S.Row>

            <S.Row>
              <Select
                label="Huvudentreprenör"
                {...register('mainContractorCompanyId')}
              >
                {mainContractorCompanyOptions.map(({ label, id }) => (
                  <option key={id} value={id}>
                    {label}
                  </option>
                ))}
              </Select>
            </S.Row>

            <S.Row>
              <Input label="Platschef" {...register('siteManager')} />
            </S.Row>

            <S.Row>
              <S.Button $outlined={isCompleted} onClick={setDone}>
                {isCompleted ? 'Inte klar' : 'Klar'}
              </S.Button>
            </S.Row>

            <DateTimePicker name="date" control={control} label="Datum" />

            <DateTimePicker
              name="completedAt"
              control={control}
              label="Kontroll färdig"
            />

            <Input
              label="Projekt"
              {...flyLeafRegister('project')}
              disabled
              placeholder="Projekt"
            />

            <Input
              label="Uppdragsgivare"
              {...flyLeafRegister('client')}
              disabled
              placeholder="Uppdragsgivare"
            />

            <Input
              label="Inspektionsbolag"
              {...flyLeafRegister('inspectionCompany')}
              placeholder="Inspektionsbolag"
            />

            <Input
              label="Inspektionsansvarig"
              {...flyLeafRegister('inspectionResponsible')}
              placeholder="Inspektionsansvarig"
            />

            <Input
              label="Telefonnummer"
              type="tel"
              {...flyLeafRegister('phoneNumber')}
              placeholder="Telefonnummer"
              error={errors.phoneNumber}
            />

            <Input
              label="Kontrollanter/inspektörer"
              {...flyLeafRegister('inspectors')}
              placeholder="Kontrollanter/inspektörer"
            />
          </S.ControlInnerContainer>

          <S.Footer>
            <S.Button $width={289} onClick={navigateToPersonalData}>
              Nästa
            </S.Button>
          </S.Footer>
        </S.ControlContainer>
      ) : (
        <S.EmptyContainer>
          <img
            src={Images.Empty}
            width={279}
            height={204}
            alt="no content available"
          />

          <S.TextContainer>
            <S.H5>Personalliggare saknas</S.H5>
            <S.B2>
              För att kunna skapa en rapport behöver personalliggaren laddas
              upp.
            </S.B2>
          </S.TextContainer>

          <S.Button onClick={workRegisterUpload.handleUploadClick}>
            Ladda upp personalliggare
          </S.Button>

          <S.Input
            type="file"
            ref={workRegisterUpload.inputRef}
            onChange={workRegisterUpload.handleFileChange}
          />
        </S.EmptyContainer>
      )}
    </React.Fragment>
  );
};
