import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetControl } from '../../../../../traits/api/controls/useGetControl';
import { useEffect } from 'react';
import { nullsToUndefined } from '../../../../../traits/utils/nullsToUndefined';

const actionsNeededEnumArray = ['yes', 'no'] as const;

export const resultSchema = z.object({
  summaryAndRecommendations: z.string().optional(),

  shellProtectionResult: z.string().optional(),
  shellProtectionActions: z.string().optional(),
  shellProtectionActionsNeeded: z.enum(actionsNeededEnumArray),

  workEnvironmentResult: z.string().optional(),
  workEnvironmentActions: z.string().optional(),
  workEnvironmentActionsNeeded: z.enum(actionsNeededEnumArray),

  workRegisterResult: z.string().optional(),
  workRegisterActions: z.string().optional(),
  workRegisterActionsNeeded: z.enum(actionsNeededEnumArray),

  preApprovedSubcontractorResult: z.string().optional(),
  preApprovedSubcontractorActions: z.string().optional(),
  preApprovedSubcontractorActionsNeeded: z.enum(actionsNeededEnumArray),

  workPermitResult: z.string().optional(),
  workPermitActions: z.string().optional(),
  workPermitActionsNeeded: z.enum(actionsNeededEnumArray),

  id06DeviationsResult: z.string().optional(),
  id06DeviationsActions: z.string().optional(),
  id06DeviationsActionsNeeded: z.enum(actionsNeededEnumArray),

  identificationResult: z.string().optional(),
  identificationActions: z.string().optional(),
  identificationActionsNeeded: z.enum(actionsNeededEnumArray),

  secondmentRegulationsResult: z.string().optional(),
  secondmentRegulationsActions: z.string().optional(),
  secondmentRegulationsActionsNeeded: z.enum(actionsNeededEnumArray),

  intoxicationResult: z.string().optional(),
  intoxicationActions: z.string().optional(),
  intoxicationActionsNeeded: z.enum(actionsNeededEnumArray),

  safetyEquipmentResult: z.string().optional(),
  safetyEquipmentActions: z.string().optional(),
  safetyEquipmentActionsNeeded: z.enum(actionsNeededEnumArray),

  otherResult: z.string().optional(),
  otherActions: z.string().optional(),
  otherActionsNeeded: z.enum(actionsNeededEnumArray),

  deletedFiles: z.array(z.string()),
});

export type ResultFormValues = z.infer<typeof resultSchema>;

export const useResultForm = (inspectionId: string) => {
  const control = useGetControl(inspectionId as string);

  const methods = useForm<ResultFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(resultSchema),
  });

  useEffect(() => {
    if (!control.data) return;

    const {
      shellProtectionActionsNeeded,
      workPermitActionsNeeded,
      workEnvironmentActionsNeeded,
      workRegisterActionsNeeded,
      preApprovedSubcontractorActionsNeeded,
      id06DeviationsActionsNeeded,
      identificationActionsNeeded,
      secondmentRegulationsActionsNeeded,
      intoxicationActionsNeeded,
      safetyEquipmentActionsNeeded,
      otherActionsNeeded,
      summaryAndRecommendations,
      shellProtectionResult,
      shellProtectionActions,
      workEnvironmentResult,
      workEnvironmentActions,
      workRegisterResult,
      workRegisterActions,
      preApprovedSubcontractorResult,
      preApprovedSubcontractorActions,
      workPermitResult,
      workPermitActions,
      id06DeviationsResult,
      id06DeviationsActions,
      identificationResult,
      identificationActions,
      secondmentRegulationsResult,
      secondmentRegulationsActions,
      intoxicationResult,
      intoxicationActions,
      safetyEquipmentResult,
      safetyEquipmentActions,
      otherResult,
      otherActions,
    } = control.data;

    methods.reset(
      nullsToUndefined({
        summaryAndRecommendations,
        shellProtectionResult,
        shellProtectionActions,
        shellProtectionActionsNeeded: toActionsNeededEnumValue(
          shellProtectionActionsNeeded
        ),

        workEnvironmentResult,
        workEnvironmentActions,
        workEnvironmentActionsNeeded: toActionsNeededEnumValue(
          workEnvironmentActionsNeeded
        ),

        workRegisterResult,
        workRegisterActions,
        workRegisterActionsNeeded: toActionsNeededEnumValue(
          workRegisterActionsNeeded
        ),

        preApprovedSubcontractorResult,
        preApprovedSubcontractorActions,
        preApprovedSubcontractorActionsNeeded: toActionsNeededEnumValue(
          preApprovedSubcontractorActionsNeeded
        ),

        workPermitResult,
        workPermitActions,
        workPermitActionsNeeded: toActionsNeededEnumValue(
          workPermitActionsNeeded
        ),

        id06DeviationsResult,
        id06DeviationsActions,
        id06DeviationsActionsNeeded: toActionsNeededEnumValue(
          id06DeviationsActionsNeeded
        ),

        identificationResult,
        identificationActions,
        identificationActionsNeeded: toActionsNeededEnumValue(
          identificationActionsNeeded
        ),

        secondmentRegulationsResult,
        secondmentRegulationsActions,
        secondmentRegulationsActionsNeeded: toActionsNeededEnumValue(
          secondmentRegulationsActionsNeeded
        ),

        intoxicationResult,
        intoxicationActions,
        intoxicationActionsNeeded: toActionsNeededEnumValue(
          intoxicationActionsNeeded
        ),

        safetyEquipmentResult,
        safetyEquipmentActions,
        safetyEquipmentActionsNeeded: toActionsNeededEnumValue(
          safetyEquipmentActionsNeeded
        ),

        otherResult,
        otherActions,
        otherActionsNeeded: toActionsNeededEnumValue(otherActionsNeeded),

        deletedFiles: [],
      })
    );
  }, [control.isSuccess]);

  const getApiFriendlyValues = () => {
    const values = methods.getValues();

    const apiFriendlyValues = {
      ...values,
      shellProtectionActionsNeeded: actionsNeededEnumValueToBoolean(
        values.shellProtectionActionsNeeded
      ),
      workEnvironmentActionsNeeded: actionsNeededEnumValueToBoolean(
        values.workEnvironmentActionsNeeded
      ),
      workRegisterActionsNeeded: actionsNeededEnumValueToBoolean(
        values.workRegisterActionsNeeded
      ),
      preApprovedSubcontractorActionsNeeded: actionsNeededEnumValueToBoolean(
        values.preApprovedSubcontractorActionsNeeded
      ),
      workPermitActionsNeeded: actionsNeededEnumValueToBoolean(
        values.workPermitActionsNeeded
      ),
      id06DeviationsActionsNeeded: actionsNeededEnumValueToBoolean(
        values.id06DeviationsActionsNeeded
      ),
      identificationActionsNeeded: actionsNeededEnumValueToBoolean(
        values.identificationActionsNeeded
      ),
      secondmentRegulationsActionsNeeded: actionsNeededEnumValueToBoolean(
        values.secondmentRegulationsActionsNeeded
      ),
      intoxicationActionsNeeded: actionsNeededEnumValueToBoolean(
        values.intoxicationActionsNeeded
      ),
      safetyEquipmentActionsNeeded: actionsNeededEnumValueToBoolean(
        values.safetyEquipmentActionsNeeded
      ),
      otherActionsNeeded: actionsNeededEnumValueToBoolean(
        values.otherActionsNeeded
      ),
    };

    return apiFriendlyValues;
  };

  return { ...methods, getApiFriendlyValues };
};

function toActionsNeededEnumValue(
  actionsNeeded: boolean | null
): (typeof actionsNeededEnumArray)[number] {
  if (!actionsNeeded) return 'no';

  return actionsNeeded ? 'yes' : 'no';
}

function actionsNeededEnumValueToBoolean(
  value: (typeof actionsNeededEnumArray)[number]
) {
  if (value === 'no') {
    return false;
  }
  return true;
}
