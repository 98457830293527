import { useMemo } from 'react';
import { Company } from '@ansvar-sakerhet/api-client';

export const useMainContractorCompanyOptions = (companies: Company[]) => {
  const options = useMemo(() => {
    const companiesOptions = companies.map(({ name, companyId }) => ({
      id: companyId,
      label: name,
    }));

    return companiesOptions;
  }, [companies]);

  return options;
};
