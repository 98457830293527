import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const TInput = styled.input`
  width: 100%;
  padding: 12px 16px;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  font-weight: 300;
`;

export const S = {
  ...UIElements,
  TInput,
};
