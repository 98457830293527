import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

export const S = {
  ...UIElements,

  Label,
  Wrapper,
};
