import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { clientKeys } from './clients.key';
import { useState } from 'react';

type Props = {
  search?: string | undefined;
  take?: number | undefined;
  skip?: number | undefined;
};

export function useGetAllClients(props?: Props) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getClients = async () => {
    const api = await getApi();

    const res = await api.clientsFindAll(
      props?.search,
      props?.take,
      props?.skip
    );
    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: clientKeys.findAll(props),
    queryFn: getClients,
    keepPreviousData: true,
  });

  return { ...query, count };
}
