import { SortDeviations } from '../../../features/controls/screens/PersonnelDeviations/components/DeviationsTable/hooks/useDeviationsTableData';
import type { SortControls } from './useGetControls';

const all = ['controls'] as const;

const findMany = (filters?: {
  page: number;
  isCompleted: boolean;
  sort: SortControls;
}) => {
  return [...all, { ...filters }] as const;
};

const findOne = (inspectionId: string) => {
  return [...all, inspectionId] as const;
};

const findFiles = (inspectionId: string) => {
  return [...findOne(inspectionId), 'files'] as const;
};

const findCompanies = (inspectionId: string) => {
  return [...findOne(inspectionId), 'companies'] as const;
};

const findCompanyStatistics = (
  inspectionId: string,
  filters: { page: number; sort: SortDeviations }
) => {
  return [
    ...findOne(inspectionId),
    'company-statistics',
    { ...filters },
  ] as const;
};

const findStatistics = (inspectionId: string) => {
  return [...findOne(inspectionId), 'statistics'] as const;
};

export const controlKeys = {
  all,
  findMany,
  findOne,
  findFiles,
  findCompanies,
  findCompanyStatistics,
  findStatistics,
};
