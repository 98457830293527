import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useControl,
  useSummaryFormContext,
} from '../../components/ControlLayout';
import { StatisticsTable } from './components/StatisticsTable';
import { DeviationTable } from './components/DeviationTable';
import { EvaluationTable } from './components/EvaluationTable';
import { useGetStatistics } from '../../../../traits/api/controls/useGetStatistics';

import { S } from './DataSummary.styles';
import { Loading } from '../../../../components/Loading';
import { useGetCompanies } from '../../../../traits/api/controls/useGetCompanies';
import { ForeignWorkplaceTable } from './components/ForeignWorkplacesTable';

export const DataSummary: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useControl();

  const controlStatistics = useGetStatistics(data.inspectionId, data.controlId);
  const companies = useGetCompanies(data.inspectionId);

  const { trigger } = useSummaryFormContext();
  const { state } = useParams();

  const navigateToResult = async () => {
    const isValid = await trigger();
    if (!isValid) return;
    navigate(`/kontroller/${state}/${data.inspectionId}/resultat`);
  };

  const navigateToDescription = async () => {
    navigate(`/kontroller/${state}/${data.inspectionId}/uppdragsbeskrivning`);
  };

  const companiesData = companies.data?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  React.useEffect(() => {
    const onScroll = () => {
      localStorage.setItem('DataSummary', window.scrollY + '');
    };

    let scroll = Number(localStorage.getItem('DataSummary'));
    if (!scroll) {
      scroll = 0;
    }
    new Promise((r) => setTimeout(r, 200)).then(() => {
      window.scrollTo({
        top: scroll,
        left: 0,
      });
    });

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <S.DataSummaryContainer>
      <S.DataSummaryInner type="single" defaultValue="1" paddingbottom={24}>
        <S.H4>Resultat och samlad bedömning</S.H4>

        <S.B3>
          Resultat och rekommendationer på åtgärder beskrivs efter varje
          kontrollpunkt under rubrik - Resultat/åtgärder.
        </S.B3>
      </S.DataSummaryInner>

      <S.DataSummaryInner
        type="multiple"
        paddingbottom={72}
        defaultValue={['2', '3', '4', '5', '6']}
      >
        <S.Body value="2">
          <S.Trigger>
            <S.FlexRow>
              <S.Icon src="Diagram" />
              <S.H6>Sammanställd statistik</S.H6>
            </S.FlexRow>

            <S.AnimatedIcon src="ChevronDown" />
          </S.Trigger>

          <S.BodyInner>
            {controlStatistics.isLoading && <Loading />}

            {controlStatistics.isError && (
              <S.B3 color="error">
                Ett fel uppstod när statistiken skulle hämtas.
              </S.B3>
            )}

            {controlStatistics.isSuccess && (
              <StatisticsTable statistics={controlStatistics.data} />
            )}
          </S.BodyInner>
        </S.Body>

        <S.Body value="3">
          <S.Trigger>
            <S.FlexRow>
              <S.Icon src="Chart" />
              <S.H6>Underlag - avvikelse/riskanalys</S.H6>
            </S.FlexRow>

            <S.AnimatedIcon src="ChevronDown" />
          </S.Trigger>

          <S.BodyInner>
            <DeviationTable />

            <S.Margin $spacing="xs" />

            <EvaluationTable />
          </S.BodyInner>
        </S.Body>

        {!!companiesData?.length && (
          <S.Body value="4">
            <S.Trigger>
              <S.FlexRow>
                <S.Icon src="Validation" />
                <S.H6>Företag</S.H6>
              </S.FlexRow>

              <S.AnimatedIcon src="ChevronDown" />
            </S.Trigger>

            {/* <S.BodyInner>
              <WorkplaceTable companies={companiesData} />
            </S.BodyInner> */}
            <S.BodyInner>
              <ForeignWorkplaceTable companies={companiesData} />
            </S.BodyInner>
          </S.Body>
        )}

        <S.Footer>
          <S.Button $outlined onClick={navigateToDescription}>
            Föregående
          </S.Button>

          <S.Button onClick={navigateToResult}>Nästa</S.Button>
        </S.Footer>
      </S.DataSummaryInner>
    </S.DataSummaryContainer>
  );
};
