import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';

type Options = {
  onCreateFileSuccess?: () => void;
  onCreateFileError?: (error: unknown) => void;
};

export function useCreateFaqFile(options?: Options) {
  const getApi = useApi();

  const createFaqFile = async ({
    faqId,
    imageId,
  }: {
    faqId: string;
    imageId: string;
  }) => {
    const api = await getApi();

    const res = await api.faqsCreateFile(faqId, {
      fileId: imageId,
    });

    return res.data;
  };

  const onSuccess = () => {
    options?.onCreateFileSuccess?.();
  };

  const onError = (error: unknown) => {
    options?.onCreateFileError?.(error);
  };

  const mutation = useMutation(createFaqFile, {
    onSuccess,
    onError,
  });

  return mutation;
}
