import { ChangeEvent, useRef } from 'react';
import { usePostWorkRegister } from '../../../../../traits/api/workRegisters/usePostWorkRegister';
import { useControl } from '../../../components/ControlLayout';

export const useWorkRegisterUpload = () => {
  const control = useControl();
  const postWorkRegister = usePostWorkRegister(control.data.controlId);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    postWorkRegister.mutate({ file });
  };

  return {
    inputRef,
    handleUploadClick,
    handleFileChange,
  };
};
