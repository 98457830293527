import React, { useState } from 'react';
import { S } from './Controls.styles';
import { ControlsTable } from './components/ControlsTable';
import { ControlsLayout } from '../../components/ControlsLayout';
import { ControlsSortDropdown } from './components/ControlsSortDropdown';
import { Loading } from '../../../../components/Loading';
import { Error } from '../../../../components/Error';
import { useGetControls } from '../../../../traits/api/controls/useGetControls';
import type { SortControls } from '../../../../traits/api/controls/useGetControls';
import { useNavigate, useParams } from 'react-router-dom';

export const Controls: React.FC = () => {
  const { state } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const isCompleted = state === 'open' ? false : true;

  const [sort, setSort] = useState<SortControls>('date_DESC');
  const controls = useGetControls(page, sort, isCompleted);

  return (
    <ControlsLayout
      isCompleted={isCompleted}
      setIsCompleted={(completed) => {
        if (completed) {
          navigate('/kontroller/closed');
        } else {
          navigate('/kontroller/open');
        }
      }}
    >
      <S.ControlsContainer>
        {controls.isLoading && <Loading />}

        {controls.isError && <Error refetch={controls.refetch} />}

        {controls.isSuccess && (
          <React.Fragment>
            <S.Margin $spacing="xs" />

            <S.ControlsHeader>
              <S.B3 $color="darkGray">
                {controls.count} {isCompleted ? 'avslutade' : 'pågående'}{' '}
                kontroller
              </S.B3>

              <ControlsSortDropdown sort={sort} setSort={setSort} />
            </S.ControlsHeader>

            <ControlsTable
              data={controls.data}
              page={page}
              setPage={setPage}
              numberOfPages={Math.ceil(controls.count / 20)}
            />
          </React.Fragment>
        )}
      </S.ControlsContainer>
    </ControlsLayout>
  );
};
