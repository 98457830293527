import React, { useMemo, useState } from 'react';
import { useGetAllFrameCompanies } from '../../../../../../../../../traits/api/frameCompany/useGetFrameCompanies';
import { FrameCompaniesTable } from './components/FrameCompaniesTable';
import { Loading } from '../../../../../../../../../components/Loading';
import { Pagination } from '../../../../../../../../../components/Pagination';
import { Margin } from '@ansvar-sakerhet/ui/src/layout/Margin';

type Props = {
  clientId: string;
};

export const FrameCompaniesList = ({ clientId }: Props) => {
  const [page, setPage] = useState(0);
  const entitiesPerPage = 10;
  const pagination = useMemo(
    () => ({
      skip: entitiesPerPage * page,
      take: entitiesPerPage,
    }),
    [page]
  );

  const {
    data: frameCompanies,
    isLoading: frameCompaniesLoading,
    count,
  } = useGetAllFrameCompanies(clientId, pagination.take, pagination.skip);

  if (frameCompaniesLoading || !frameCompanies) {
    return <Loading />;
  }

  return (
    <>
      <FrameCompaniesTable frameCompanies={frameCompanies} />
      <Margin $top="m" />
      <Pagination
        numberOfPages={Math.ceil(count / entitiesPerPage)}
        page={page}
        setPage={setPage}
      />
    </>
  );
};
