const all = ['faq'] as const;

const findMany = (filters: { page: number }) => {
  return [...all, { ...filters }] as const;
};

const findOne = (faqId: string) => {
  return [...all, faqId] as const;
};

export const faqKeys = {
  all,
  findMany,
  findOne,
};
