import React from 'react';

import { S } from './DialogTrigger.styles';

type Props = {
  onClick: () => void;
};

export const DialogTrigger: React.FC<Props> = (props) => {
  return (
    <S.Trigger onClick={props.onClick}>
      <S.Icon src="Pen" height={22} color="secondary" />

      <S.H6 color="secondary">Redigera</S.H6>
    </S.Trigger>
  );
};
