import React, { useState } from 'react';
import { MarkDownEditor } from '../../components/MarkDownEditor';
import { Input } from '../../../../components/Input';
import { useCreateQuestion } from '../../../../traits/api/faq/useCreateQuestion';
import { useNavigate } from 'react-router-dom';
import {
  CreateFaqDtoFaqCategoryEnum,
  UploadFileResponse,
} from '@ansvar-sakerhet/api-client';
import { toast } from '../../../../components/Toast';
import { useCreateFaqFile } from '../../../../traits/api/images/useCreateFaqFile';
import { S } from './CreateFaq.styles';
import { CancelQuestion } from '../../components/CancelQuestion';
import { RadioInput } from '../../../../components/RadioInput';
import {
  createFaqFormValues,
  useCreateFaqForm,
} from './hooks/useCreateFaqForm';

export const CreateFaq: React.FC = () => {
  const navigate = useNavigate();
  const faq = useCreateQuestion();
  const createFaqFile = useCreateFaqFile();
  const { control, register, watch, setValue, handleSubmit } =
    useCreateFaqForm();

  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [images, setImages] = useState<UploadFileResponse[]>([]);

  const handleSave = async ({
    question,
    answer,
    faqCategory,
  }: createFaqFormValues) => {
    try {
      const res = await faq.mutateAsync({ question, answer, faqCategory });

      if (images?.length) {
        await Promise.all(
          images.map((image) =>
            createFaqFile.mutateAsync({
              faqId: res.faqId,
              imageId: image.fileId,
            })
          )
        );
      }
    } catch (err) {
      toast.error('Något gick fel när frågan skulle uppdateras');
    } finally {
      navigate('/faq');
    }
  };

  return (
    <S.FaqEditor data-color-mode="light">
      <S.FaqInner>
        <form onSubmit={handleSubmit(handleSave)}>
          <S.Body>
            <S.H4>Skapa</S.H4>

            <RadioInput
              control={control}
              state={watch('faqCategory')}
              question="FAQ för"
              name="faqCategory"
              options={[
                { value: CreateFaqDtoFaqCategoryEnum.App, label: 'App' },
                {
                  value: CreateFaqDtoFaqCategoryEnum.CustomerPortal,
                  label: 'Kundportal',
                },
              ]}
            />

            <Input
              label="Fråga"
              placeholder="FAQ fråga"
              onFocus={() => setHasBeenEdited(true)}
              {...register('question')}
            />

            <MarkDownEditor
              setNewImages={setImages}
              value={watch('answer')}
              onChange={(value) => setValue('answer', value)}
              onFocus={() => setHasBeenEdited(true)}
            />
          </S.Body>

          <S.Margin $top="m" />

          <S.Footer>
            <CancelQuestion hasBeenEdited={hasBeenEdited} />

            <S.Button disabled={!hasBeenEdited} type="submit">
              Spara
            </S.Button>
          </S.Footer>
        </form>
      </S.FaqInner>
    </S.FaqEditor>
  );
};
