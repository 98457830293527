import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 80px 0;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const WorkRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px - 68px - 48px);
  justify-content: space-between;
`;

const Footer = styled.section`
  display: flex;
  justify-content: flex-end;
  padding: 32px 0 10px 0;
  border-top: 1px solid #cbcbcb;
`;

export const S = {
  ...UIElements,
  TableContainer,
  EmptyContainer,
  TextContainer,
  Footer,
  WorkRegisterContainer,
};
