import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 0.8px solid ${({ theme }) => theme.colors.gray};
`;

const HeaderInner = styled.div`
  max-width: 1444px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 160px;
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 160px - 68px);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const BodyInner = styled.div`
  max-width: 1444px;
  min-height: calc(100vh - 160px - 68px);
  width: 100%;
  padding: 24px 72px;
`;

const InfoContainer = styled.div`
  padding: 24px 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-width: 400px;
`;

const NavigationContainer = styled.div`
  padding: 16px 72px 0px;
  display: flex;
  gap: 16px;
`;

const NavLink = styled.div<{
  $isActive: boolean;
}>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.black : theme.colors.darkGray};

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    margin-top: 16px;
    background-color: ${({ theme }) => theme.colors.secondary};
    visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  }
`;

const BackIcon = styled(UIElements.Icon)`
  cursor: pointer;
`;

export const S = {
  ...UIElements,
  BackIcon,
  HeaderContainer,
  HeaderInner,
  BodyContainer,
  BodyInner,
  InfoContainer,
  NavigationContainer,
  NavLink,
};
