import React, { useState } from 'react';
import { MarkDownEditor } from '../../components/MarkDownEditor';
import { Input } from '../../../../components/Input';
import { useNavigate } from 'react-router-dom';
import { toast } from '../../../../components/Toast';

import { S } from './CreateNews.styles';
import { CancelNews } from '../../components/CancelNews';
import { useCreateNews } from '../../../../traits/api/news/useCreateNews';

export const CreateNews: React.FC = () => {
  const navigate = useNavigate();
  const news = useCreateNews();

  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');

  const handleSave = async () => {
    try {
      if (header.length < 1) {
        return toast.error('Nyhet måste anges');
      }

      if (content.length < 1) {
        return toast.error('Innehåll måste anges');
      }

      await news.mutateAsync({ header, content });
      navigate('/news');
    } catch (err) {
      toast.error('Något gick fel när frågan skulle uppdateras');
    }
  };

  return (
    <S.Editor data-color-mode="light">
      <S.Inner>
        <S.Body>
          <S.H4>Skapa</S.H4>

          <Input
            label="Nyhet"
            placeholder="Nyhet"
            value={header}
            onFocus={() => setHasBeenEdited(true)}
            onChange={(e) => setHeader(e.target.value)}
          />

          <MarkDownEditor
            setNewImages={() => 'TODO: Set images'}
            value={content}
            onChange={(value) => setContent(value)}
            onFocus={() => setHasBeenEdited(true)}
          />
        </S.Body>

        <S.Footer>
          <CancelNews hasBeenEdited={hasBeenEdited} />

          <S.Button disabled={!hasBeenEdited} onClick={handleSave}>
            Spara
          </S.Button>
        </S.Footer>
      </S.Inner>
    </S.Editor>
  );
};
