import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  UpdateCompanyResponse,
  UpdateCompanyDto,
} from '@ansvar-sakerhet/api-client';
import { controlKeys } from './controls.key';
import { useApi } from '../useApi';

type Options = {
  onPutCompanySuccess?: (data: UpdateCompanyResponse) => void;
  onPutCompanyError?: (error: unknown) => void;
};

export function usePutCompany(inspectionId: string, options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const putCompany = async (data: UpdateCompanyDto & { companyId: string }) => {
    const api = await getApi();
    const { companyId, ...body } = data;
    const res = await api.companiesUpdate(companyId, body);
    return res.data;
  };

  const onSuccess = (data: UpdateCompanyResponse) => {
    queryClient.invalidateQueries({
      queryKey: controlKeys.findCompanies(inspectionId),
    });
    options?.onPutCompanySuccess?.(data);
  };

  const onError = (error: unknown) => {
    options?.onPutCompanyError?.(error);
  };

  const mutation = useMutation(putCompany, {
    onSuccess,
    onError,
  });

  return mutation;
}
