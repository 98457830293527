import {
  BackgroundControlWithFiles,
  FindAllFrameCompaniesResponse,
} from '@ansvar-sakerhet/api-client';

export function findLatestBackgroundControl(
  company: FindAllFrameCompaniesResponse
): BackgroundControlWithFiles | undefined {
  return company.BackgroundControl.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}
