import React, { useState } from 'react';
import { S } from './ControlsTableDeleteButton.styles';
import { DeleteDialog } from '../DeleteDialog/DeleteDialog';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

type Props = {
  controlId: string;
};

export const ControlsTableDeleteButton: React.FC<Props> = (props) => {
  const [openAlert, setOpenAlert] = useState(false);

  return (
    <AlertDialog.Root open={openAlert}>
      <AlertDialog.Trigger asChild>
        <S.DeleteDataCell
          onClick={(e) => {
            e.stopPropagation();
            setOpenAlert(true);
          }}
        >
          <S.Icon src="Trash" width={24} height={24} color="secondary" />

          <S.B2 $top="xs" $color="secondary" $weight={500}>
            Radera känslig data
          </S.B2>
        </S.DeleteDataCell>
      </AlertDialog.Trigger>

      <DeleteDialog
        onClose={() => setOpenAlert(false)}
        controlId={props.controlId}
      />
    </AlertDialog.Root>
  );
};
