import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { newsKeys } from './news.key';
import { FindAllNewsResponse } from '@ansvar-sakerhet/api-client';

export function useGetNews(page: number) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getNews = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    const res = await api.newsFindAll(take, skip);

    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const getNextPageParam = (
    lastPage: FindAllNewsResponse[],
    pages: FindAllNewsResponse[][]
  ) => {
    if (lastPage.length < 20) return undefined;
    return pages.length + 1;
  };

  const query = useQuery({
    queryKey: newsKeys.findMany({ page }),
    queryFn: getNews,
    keepPreviousData: true,
    getNextPageParam,
  });

  return {
    ...query,
    count,
  };
}
