import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const ProfileDropdownBackground = styled.div<{ $isOpen: boolean }>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ $isOpen }) => ($isOpen ? '#00000033' : 'transparent')};
  transition: visibility 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
`;

const ProfileDropdownContainer = styled.div`
  z-index: 100;
  min-width: 280px;
  position: absolute;
  top: calc(56px + ${({ theme }) => theme.spacing.xs});
  right: ${({ theme }) => theme.spacing['l']};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.xs};
  border: 1px solid ${({ theme }) => theme.colors.gray};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray};
`;

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const MailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background-color: #023e5210;
  }
`;

const LogoutContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px;

  &:hover {
    background-color: #023e5210;
  }
`;

const LogoutInner = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.s};
  align-items: center;
`;

export const S = {
  ...UIElements,
  ProfileDropdownBackground,
  ProfileDropdownContainer,
  AccountContainer,
  MailContainer,
  Divider,
  LogoutContainer,
  LogoutInner,
  FaqContainer,
};
