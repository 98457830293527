import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetControl } from '../../../../../traits/api/controls/useGetControl';
import { useEffect } from 'react';
import { nullsToUndefined } from '../../../../../traits/utils/nullsToUndefined';

export const flyleafSchema = z.object({
  title: z.string().optional(),
  date: z.date().optional(),
  completedAt: z.date().optional(),
  project: z.string().optional(),
  client: z.string().optional(),
  inspectionCompany: z.string().optional(),
  inspectionResponsible: z.string().optional(),
  phoneNumber: z.string().optional(),
  inspectors: z.string().optional(),
});

export type FlyleafFormValues = z.infer<typeof flyleafSchema>;

export const useFlyleafForm = (inspectionId: string) => {
  const control = useGetControl(inspectionId);

  const methods = useForm<FlyleafFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(flyleafSchema),
    defaultValues: {
      date: new Date(),
    },
  });

  useEffect(() => {
    if (!control.data) return;

    const {
      title,
      date,
      project,
      client,
      inspectionCompany,
      inspectionResponsible,
      phoneNumber,
      inspectors,
      completedAt,
    } = control.data;

    methods.reset(
      nullsToUndefined({
        title,
        date: new Date(date),
        completedAt: completedAt ? new Date(completedAt) : null,
        project: project.name,
        client: client.name,
        inspectionCompany,
        inspectionResponsible,
        phoneNumber,
        inspectors,
      })
    );
  }, [control.isSuccess]);

  return methods;
};
