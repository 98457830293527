import {
  FindAllPersonnelResponse,
  FindOneControlResponse,
} from '@ansvar-sakerhet/api-client';
import React from 'react';
import { Row, TableInstance, UseExpandedRowProps } from 'react-table';
import { useWorkRegisterTableData } from '../hooks/useWorkRegisterTableData';
import { useGetPersonnelFiles } from '../../../../../../../traits/api/workRegisters/useGetPersonnelFiles';
import { useGetPersonnel } from '../../../../../../../traits/api/workRegisters/useGetPersonnel';
import { S } from '../WorkRegisterTable.styles';
import { WorkRegisterTableSubRow } from '../../WorkRegisterTableSubRow';

type ExpandedRow = UseExpandedRowProps<
  Row<ReturnType<typeof useWorkRegisterTableData>['data']>
>;

type Props = {
  row: Row<{
    readonly personnelId: string;
    readonly name: `${string} ${string}`;
    readonly identityNumber: string;
    readonly companyName: string;
    readonly orgNumber: string;
    readonly id06:
      | 'Saknas'
      | 'Inaktivt'
      | 'Avstängt'
      | 'Ogiltigt'
      | 'Annat'
      | 'Aktivt';
    readonly workRegister: 'Avvikelse' | 'Inga avvikelser';
    readonly person: FindAllPersonnelResponse;
  }>;
  table: TableInstance<{
    readonly personnelId: string;
    readonly name: `${string} ${string}`;
    readonly identityNumber: string;
    readonly companyName: string;
    readonly orgNumber: string;
    readonly id06:
      | 'Saknas'
      | 'Inaktivt'
      | 'Avstängt'
      | 'Ogiltigt'
      | 'Annat'
      | 'Aktivt';
    readonly workRegister: 'Avvikelse' | 'Inga avvikelser';
    readonly person: FindAllPersonnelResponse;
  }>;
  control: FindOneControlResponse;
  showExtra: boolean;
  setHoverEPL: (val: boolean) => void;
  hoverEPL: boolean;
  setHoverBolt: (val: boolean) => void;
  hoverBolt: boolean;
};

export const WorkRegisterTableRow = ({
  row,
  table,
  control,
  showExtra,
  setHoverBolt,
  setHoverEPL,
  hoverBolt,
  hoverEPL,
}: Props) => {
  table.prepareRow(row);
  const expanded = row as unknown as ExpandedRow;
  const files = useGetPersonnelFiles(
    row.original.personnelId,
    control.controlId
  );

  const personnel = useGetPersonnel(
    row.original.personnelId,
    control.controlId
  );

  if (files.isLoading || files.isError) {
    return <S.H4 key={row.getRowProps().key}>Loading...</S.H4>;
  }

  return (
    <React.Fragment key={row.getRowProps().key}>
      <S.TBodyRow
        {...row.getRowProps()}
        onClick={() => expanded.toggleRowExpanded()}
      >
        {row.cells.map((cell) => {
          if (cell.column.id === 'id06') {
            if (!showExtra) return null;
            return (
              <S.TBodyCell
                {...cell.getCellProps()}
                key={cell.getCellProps().key}
              >
                <S.ID06Tag
                  $type={cell.row.original.id06}
                  $hasComment={cell.row.original.person.hasID06CardComment}
                >
                  {cell.render('Cell')}
                </S.ID06Tag>
              </S.TBodyCell>
            );
          }

          if (cell.column.id === 'workRegister') {
            if (!showExtra) return null;
            return (
              <S.TBodyCell
                {...cell.getCellProps()}
                key={cell.getCellProps().key}
              >
                <S.WorkRegisterTag
                  $type={cell.row.original.workRegister}
                  $hasComment={
                    cell.row.original.person.hasPersonnelQuestionnaireComment
                  }
                >
                  {cell.render('Cell')}
                </S.WorkRegisterTag>
              </S.TBodyCell>
            );
          }

          if (cell.column.id === 'name') {
            return (
              <S.TBodyCell
                {...cell.getCellProps()}
                key={cell.getCellProps().key}
              >
                {!personnel.data?.arrivedAt && (
                  <div
                    onMouseEnter={() => setHoverEPL(true)}
                    onMouseLeave={() => setHoverEPL(false)}
                    role="button"
                  >
                    {hoverEPL ? (
                      <S.HoverText>Ej incheckad i EPL</S.HoverText>
                    ) : null}
                    <S.Icon src="Alert" />
                  </div>
                )}
                {personnel.data?.arrivedAt &&
                  !personnel.data.questionnaire?.hasIdCard && (
                    <div
                      onMouseEnter={() => setHoverBolt(true)}
                      onMouseLeave={() => setHoverBolt(false)}
                      role="button"
                    >
                      {hoverBolt ? (
                        <S.HoverText>Hittades ej</S.HoverText>
                      ) : null}
                      <S.Icon src="Bolt" />
                    </div>
                  )}
                {cell.render('Cell')}
                {files.data?.length ? <S.Icon src="File" /> : null}
              </S.TBodyCell>
            );
          }
          return (
            <S.TBodyCell {...cell.getCellProps()} key={cell.getCellProps().key}>
              {cell.render('Cell')}
            </S.TBodyCell>
          );
        })}

        <S.TriggerWrapper>
          <S.Trigger $isExpanded={expanded.isExpanded}>
            <S.AnimatedIcon src="ChevronDown" />
          </S.Trigger>
        </S.TriggerWrapper>
      </S.TBodyRow>

      {expanded.isExpanded && (
        <tr>
          <td colSpan={table.columns.length + 1}>
            <WorkRegisterTableSubRow personnelId={row.original.personnelId} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};
