import React from 'react';
import { S } from './DeviationsTable.styles';
import { FindAllCompanyStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { Row, UseExpandedRowProps, useExpanded, useTable } from 'react-table';
import { useDeviationsTableData } from './hooks/useDeviationsTableData';
import { DeviationsTableSubRow } from '../DeviationsTableSubRow';
import { Pagination } from '../../../../../../components/Pagination';

type Props = {
  data: FindAllCompanyStatisticsResponse[];
  page: number;
  setPage: (page: number) => void;
  numberOfPages: number;
};

type ExpandedRow = UseExpandedRowProps<
  Row<ReturnType<typeof useDeviationsTableData>['data']>
>;

export const DeviationsTable: React.FC<Props> = (props) => {
  const data = useDeviationsTableData(props.data);
  const table = useTable(data, useExpanded);

  return (
    <S.TableContainer>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);
            const expanded = row as unknown as ExpandedRow;

            return (
              <React.Fragment key={row.getRowProps().key}>
                <S.TBodyRow
                  {...row.getRowProps()}
                  onClick={() => expanded.toggleRowExpanded()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <S.TBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </S.TBodyCell>
                    );
                  })}

                  <S.TriggerWrapper>
                    <S.Trigger $isExpanded={expanded.isExpanded}>
                      <S.AnimatedIcon src="ChevronDown" />
                    </S.Trigger>
                  </S.TriggerWrapper>
                </S.TBodyRow>

                {expanded.isExpanded && (
                  <tr>
                    <td colSpan={table.columns.length + 1}>
                      <DeviationsTableSubRow
                        companyNationality={row.original.companyNationality}
                        numberOfCheckedInPersonnel={
                          row.original.numberOfCheckedInPersonnel
                        }
                        numberOfControlledPersonnel={
                          row.original.numberOfControlledPersonnel
                        }
                        numberOfAdditionalPersonnel={
                          row.original.numberOfAdditionalPersonnel
                        }
                        numberOfNoSafePersonnel={
                          row.original.numberOfNoSafePersonnel
                        }
                        numberOfNoId={row.original.numberOfNoId}
                        numerOfId06Card={row.original.numerOfId06Card}
                        numberOfWithoutSafteyEquipment={
                          row.original.numberOfWithoutSafteyEquipment
                        }
                        numberOfPersonnelWithoutValidA1Certificate={
                          row.original
                            .numberOfPersonnelWithoutValidA1Certificate
                        }
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </S.TBody>
      </S.Table>

      <S.PaginationContainer>
        <Pagination
          page={props.page}
          setPage={props.setPage}
          numberOfPages={props.numberOfPages}
        />
      </S.PaginationContainer>
    </S.TableContainer>
  );
};
