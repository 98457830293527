import React from 'react';
import { useTable } from 'react-table';
import { useRiskTableData } from '../../hooks/useRiskTableData';

import { S } from './EvaluationTable.styles';

export const EvaluationTable: React.FC = () => {
  const data = useRiskTableData();
  const table = useTable(data);

  return (
    <S.TWrapper>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    {column.render('Header')}
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.TBodyRow
                $underline
                {...row.getRowProps()}
                key={row.getRowProps().key}
              >
                {row.cells.map((cell) => {
                  if (cell.column.id === 'description') {
                    return (
                      <S.TBodyCell key={cell.getCellProps().key}>
                        <S.B2>{cell.row.original.description.title}</S.B2>
                        <S.B3>{cell.row.original.description.text}</S.B3>
                      </S.TBodyCell>
                    );
                  }

                  if (cell.column.id === 'evaluation') {
                    return (
                      <S.TBodyCell
                        key={cell.getCellProps().key}
                        $background={cell.row.original.evaluation?.color}
                      >
                        {cell.row.original.evaluation?.value}
                      </S.TBodyCell>
                    );
                  }
                  return (
                    <S.TBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.TBodyCell>
                  );
                })}
              </S.TBodyRow>
            );
          })}
        </S.TBody>
      </S.Table>
    </S.TWrapper>
  );
};
