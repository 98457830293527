import React, { useState } from 'react';
import { S } from './CompaniesTableSubRow.styles';
import { CreateBackgroundControlModal } from '../CreateBackgroundControlModal';
import * as Dialog from '@radix-ui/react-dialog';
import { DialogTrigger } from '../DialogTrigger';
import {
  BackgroundControlWithFilesRiskEnum,
  FindAllCompaniesResponse,
} from '@ansvar-sakerhet/api-client';
import { format } from 'date-fns';
import { DownloadReportButton } from '../DownloadReportButton';
import { findLatestReport } from './utils/findLatestReport';

const StatusTranslations: Record<BackgroundControlWithFilesRiskEnum, string> = {
  NoRisk: 'Inga risker identifierade.',
  LesserRisk: 'Avvikelser identifierade, OK att samarbeta.',
  MediumHighRisk: 'Avvikelser identifierade, åtgärder föreslås.',
  HighRisk:
    'Väsentliga risker. Avrådan från att anlita företaget eller åtgärda avvikelser.',
};

type Props = {
  company: FindAllCompaniesResponse;
};

export const CompaniesTableSubRow: React.FC<Props> = ({ company }) => {
  const { backgroundControls } = company;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.TableSubRowContainer>
      <S.CompanyInfoContainer>
        <S.H6>Namn</S.H6>
        <S.B3>{company.name}</S.B3>

        <S.H6>Org nr</S.H6>
        <S.B3>{company.registrationNumber}</S.B3>
      </S.CompanyInfoContainer>

      <S.Divider />

      <S.BackgroundControlsContainer>
        {company.BackgroundControlOrder.map(
          ({ backgroundControlOrderId, createdAt }) => {
            const created = new Date(createdAt);
            return (
              <S.B4 key={backgroundControlOrderId}>
                Bakgrundskontroll beställd {created.toLocaleDateString()}
              </S.B4>
            );
          }
        )}
        {backgroundControls.length === 0 ? (
          <S.H6>Det finns ännu inga bakgrundskontroller</S.H6>
        ) : (
          backgroundControls.map(
            ({ backgroundControlId, risk, createdAt, reportFiles }) => {
              const formattedDate = format(new Date(createdAt), 'yyyy/MM/dd');

              return (
                <React.Fragment key={backgroundControlId}>
                  <S.H6>Bakgrundskontroll - {formattedDate}</S.H6>
                  <S.B3>{StatusTranslations[risk]}</S.B3>
                  <DownloadReportButton
                    downloadUrl={findLatestReport(reportFiles)?.file.url}
                  />
                </React.Fragment>
              );
            }
          )
        )}
      </S.BackgroundControlsContainer>

      <S.EditButtonContainer>
        <Dialog.Root open={isOpen}>
          <DialogTrigger onClick={() => setIsOpen(true)} />
          <CreateBackgroundControlModal
            open={isOpen}
            companyRegistrationNumber={company.registrationNumber}
            onCancel={() => setIsOpen(false)}
          />
        </Dialog.Root>
      </S.EditButtonContainer>
    </S.TableSubRowContainer>
  );
};
