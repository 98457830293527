import { UpdateFaqDto, UpdateFaqResponse } from '@ansvar-sakerhet/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { faqKeys } from '../faq/faq.key';
import { toast } from '../../../components/Toast';

type Options = {
  onUseUpdateQuestionSuccess?: (data: UpdateFaqResponse) => void;
  onUseUpdateQuestionError?: (error: unknown) => void;
};

export function useUseUpdateQuestion(faqId: string, options?: Options) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const updateQuestion = async (data: UpdateFaqDto) => {
    const api = await getApi();

    const res = await api.faqsUpdate(faqId, data);
    return res.data;
  };

  const onSuccess = (data: UpdateFaqResponse) => {
    toast.success('Frågan har uppdaterats');
    queryClient.invalidateQueries({
      queryKey: faqKeys.findOne(faqId),
    });

    options?.onUseUpdateQuestionSuccess?.(data);
  };

  const onError = (error: unknown) => {
    options?.onUseUpdateQuestionError?.(error);
  };

  const mutation = useMutation(updateQuestion, {
    onSuccess,
    onError,
  });

  return mutation;
}
