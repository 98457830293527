import React, { useState } from 'react';
import { useGetQuestion } from '../../../../traits/api/faq/useGetQuestion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUseUpdateQuestion } from '../../../../traits/api/faq/useUpdateQuestion';
import { Input } from '../../../../components/Input';
import { MarkDownEditor } from '../../components/MarkDownEditor';
import { CancelQuestion } from '../../components/CancelQuestion';
import { useDeleteFaqFile } from '../../../../traits/api/images/useDeleteFaqFile';
import { useCreateFaqFile } from '../../../../traits/api/images/useCreateFaqFile';
import {
  CreateFaqDtoFaqCategoryEnum,
  UploadFileResponse,
} from '@ansvar-sakerhet/api-client';
import { toast } from '../../../../components/Toast';

import { S } from './FaqEditor.styles';
import { editFaqFormValues, useEditFaqForm } from './hooks/useEditFaqForm';
import { Loading } from '../../../../components/Loading';
import { RadioInput } from '../../../../components/RadioInput';

export const FaqEditor: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const faqId = location.pathname.split('/').pop() || '';
  const faq = useGetQuestion(faqId);
  const deleteFaqFile = useDeleteFaqFile();
  const createFaqFile = useCreateFaqFile();
  const updateFaq = useUseUpdateQuestion(faqId, { onUseUpdateQuestionSuccess });
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const { control, register, watch, setValue, handleSubmit, getValues } =
    useEditFaqForm(faq.data);
  const [images, setImages] = useState<UploadFileResponse[]>([]);

  const findDeletedImages = () => {
    const oldImageStrings = faq.data?.images.map((image) => ({
      url: image.file.url,
      id: image.faqFileId,
    }));
    const answer = getValues('answer');
    const newImageStrings = answer.match(/!\[.*\]\(.*\)/g);
    const deletedImages = oldImageStrings?.filter(
      (image) => !newImageStrings?.includes(image.url)
    );

    return deletedImages;
  };

  const handleSave = async ({
    question,
    answer,
    faqCategory,
  }: editFaqFormValues) => {
    try {
      await updateFaq.mutateAsync({ question, answer, faqCategory });
      const deletedImages = findDeletedImages();

      if (images?.length) {
        await Promise.all(
          images.map((image) =>
            createFaqFile.mutateAsync({ faqId, imageId: image.fileId })
          )
        );
      }

      if (deletedImages?.length) {
        await Promise.all(
          deletedImages.map((image) => deleteFaqFile.mutateAsync(image.id))
        );
      }
    } catch (err) {
      toast.error('Något gick fel när frågan skulle uppdateras');
    }
  };

  function onUseUpdateQuestionSuccess() {
    navigate('/faq');
  }

  if (faq.isLoading) {
    return <Loading />;
  }

  return (
    <S.FaqEditor data-color-mode="light">
      <S.FaqInner>
        <form onSubmit={handleSubmit(handleSave)}>
          <S.Body>
            <S.H4>Redigera</S.H4>

            <RadioInput
              control={control}
              onChange={() => setHasBeenEdited(true)}
              state={watch('faqCategory')}
              question="FAQ för"
              name="faqCategory"
              options={[
                { value: CreateFaqDtoFaqCategoryEnum.App, label: 'App' },
                {
                  value: CreateFaqDtoFaqCategoryEnum.CustomerPortal,
                  label: 'Kundportal',
                },
              ]}
            />

            <Input
              label="Fråga"
              placeholder="FAQ fråga"
              onFocus={() => setHasBeenEdited(true)}
              {...register('question')}
            />

            <MarkDownEditor
              setNewImages={setImages}
              value={watch('answer')}
              onChange={(value) => setValue('answer', value)}
              onFocus={() => setHasBeenEdited(true)}
            />
          </S.Body>

          <S.Margin $top="m" />

          <S.Footer>
            <CancelQuestion hasBeenEdited={hasBeenEdited} />

            <S.Button disabled={!hasBeenEdited} type="submit">
              Spara
            </S.Button>
          </S.Footer>
        </form>
      </S.FaqInner>
    </S.FaqEditor>
  );
};
