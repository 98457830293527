import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  cursor: pointer;
`;

export const S = {
  ...UIElements,
  Header,
  AddButton,
};
