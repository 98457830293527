import { styled } from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const ControlsHeader = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px - 68px - 48px);
  justify-content: space-between;
`;

export const S = {
  ...UIElements,
  ControlsHeader,
  ControlsContainer,
};
