import React, { useState } from 'react';
import { S } from './Clients.style';
import { useGetAllClients } from '../../../../traits/api/clients/useGetAllClients';
import { Loading } from '../../../../components/Loading';
import { ClientsTable } from './components/ClientsTable';

export const Clients: React.FC = () => {
  const clientsPerPage = 20;
  const [page, setPage] = useState(0);
  const clients = useGetAllClients({
    take: clientsPerPage,
    skip: page * clientsPerPage,
  });

  if (clients.isLoading || !clients.data) {
    return (
      <S.Container>
        <S.H3>Kunder</S.H3>
        {/* <S.Button onClick={() => navigate('/ny-kund')}>Ny kund</S.Button> */}
        <S.Margin $top="m" />
        <S.Clients>
          <Loading />
        </S.Clients>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <S.H3>Kunder</S.H3>
      {/* <S.Button onClick={() => navigate('/ny-kund')}>Ny kund</S.Button> */}
      <S.Margin $top="m" />
      <S.Clients>
        <ClientsTable
          page={page}
          setPage={setPage}
          clients={clients.data}
          numberOfPages={Math.ceil(clients.count / clientsPerPage)}
        />
      </S.Clients>
    </S.Container>
  );
};
