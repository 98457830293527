import { FindAllCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';
import { dash } from '../../../../../traits/utils/dash';

export const useForeignWorkplaceTable = (
  companies: FindAllCompaniesResponse[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Företagsnamn',
        accessor: 'companyName',
      },
      {
        Header: 'Org.nummer',
        accessor: 'companyRegistrationNumber',
      },
      {
        Header: 'Förkvaliciferad',
        accessor: 'isPreQualified',
      },
      {
        Header: 'Är svenskt',
        accessor: 'companyNationality',
      },
      {
        Header: 'Anmälningsskyldighet till AV Utstationeringsregister',
        accessor: 'hasReportingObligation',
      },
      {
        Header:
          'Ej fullgjort sin anmälningsskyldighet till AV Utstationeringsregister',
        accessor: 'isReportingObligationFulfilled',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return companies.map((company) => {
      return {
        companyId: company.companyId,
        companyName: company.name,
        companyRegistrationNumber: dash(company.registrationNumber),
        companyNationality: company.nationality,
        isPreQualified: company.isPreQualified,
        hasReportingObligation: company.hasReportingObligation,
        isReportingObligationFulfilled: company.isReportingObligationFulfilled,
      } as const;
    });
  }, [companies]);

  return { columns, data };
};
