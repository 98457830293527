import { BackgroundControlWithFilesRiskEnum } from '@ansvar-sakerhet/api-client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const editPersonnelSchema = z.object({
  backgroundControlStatus: z.enum([
    BackgroundControlWithFilesRiskEnum.NoRisk,
    BackgroundControlWithFilesRiskEnum.LesserRisk,
    BackgroundControlWithFilesRiskEnum.MediumHighRisk,
    BackgroundControlWithFilesRiskEnum.HighRisk,
  ]),
  optedOut: z.enum(['yes', 'no']),
  fileId: z.string().min(1),
});

export type CreateBackgroundControlFormValues = z.infer<
  typeof editPersonnelSchema
>;

export const useCreateBackgroundControlForm = () => {
  const methods = useForm<CreateBackgroundControlFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(editPersonnelSchema),
    defaultValues: {
      backgroundControlStatus: BackgroundControlWithFilesRiskEnum.NoRisk,
      optedOut: 'no',
    },
  });

  return methods;
};
