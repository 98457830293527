import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { workRegisterKeys } from './workRegisters.key';
import { toast } from '../../../components/Toast';
import {
  UpdateID06CardDto,
  UpdateID06CardResponse,
} from '@ansvar-sakerhet/api-client';

type Options = {
  onUpdateCommentSuccess?: (data: UpdateID06CardResponse) => void;
  onUpdateCommentError?: (error: unknown) => void;
};

export function useUpdateID06Comment(
  personnelId: string,
  controlId: string,
  options?: Options
) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const updateID06Comment = async (data: UpdateID06CardDto) => {
    const api = await getApi();

    const res = await api.personnelUpdateID06Card(personnelId, data);
    return res.data;
  };

  const onSuccess = (data: UpdateID06CardResponse) => {
    queryClient.invalidateQueries({
      queryKey: workRegisterKeys.personnelFindOne(controlId, personnelId),
    });

    options?.onUpdateCommentSuccess?.(data);
  };

  const onError = () => {
    toast.error('Något gick fel, gick inte att spara');
  };

  const mutation = useMutation(updateID06Comment, {
    onSuccess,
    onError,
  });

  return mutation;
}
