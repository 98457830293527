import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { CreateFaqDtoFaqCategoryEnum } from '@ansvar-sakerhet/api-client';

export const createFaqForm = z.object({
  faqCategory: z.nativeEnum(CreateFaqDtoFaqCategoryEnum),
  question: z.string().min(1, 'Fråga måste anges'),
  answer: z.string().min(1, 'Svar måste anges'),
});

export type createFaqFormValues = z.infer<typeof createFaqForm>;

export const useCreateFaqForm = () => {
  const methods = useForm<createFaqFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(createFaqForm),
    defaultValues: {
      faqCategory: CreateFaqDtoFaqCategoryEnum.App,
      question: '',
      answer: '',
    },
  });

  return methods;
};
