import React from 'react';
import { Input } from '../../../../components/Input';
import { DateTimePicker } from './components/DateTimePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlyleafFormContext } from '../../components/ControlLayout';

import { S } from './FlyLeaf.styles';

export const FlyLeaf: React.FC = () => {
  const navigate = useNavigate();
  const { inspectionId } = useParams();
  const { state } = useParams();
  const {
    register,
    trigger,
    control,
    formState: { errors },
  } = useFlyleafFormContext();

  const navigateToWorkRegister = async () => {
    navigate(`/kontroller/${state}/${inspectionId}/persondata`);
  };

  const navigateToDescription = async () => {
    const isValid = await trigger();
    if (!isValid) return;
    navigate(`/kontroller/${state}/${inspectionId}/uppdragsbeskrivning`);
  };

  return (
    <S.FlyLeafContainer>
      <S.FlyLeafInner type="single" defaultValue="1">
        <S.H4>Försättsblad</S.H4>

        <S.Body value="1">
          <S.Header asChild>
            <div>
              <S.Icon src="File" />
              <S.H6>Detaljer</S.H6>
            </div>
          </S.Header>

          <S.BodyInner>
            {/* <Input
              label="Titel"
              {...register('title')}
              placeholder="Titel"
              error={errors.title}
            /> */}

            <DateTimePicker name="date" control={control} label="Datum" />

            <DateTimePicker
              name="completedAt"
              control={control}
              label="Kontroll färdig"
            />

            <Input
              label="Projekt"
              {...register('project')}
              placeholder="Projekt"
            />

            <Input
              label="Uppdragsgivare"
              {...register('client')}
              placeholder="Uppdragsgivare"
            />

            <Input
              label="Inspektionsbolag"
              {...register('inspectionCompany')}
              placeholder="Inspektionsbolag"
            />

            <Input
              label="Inspektionsansvarig"
              {...register('inspectionResponsible')}
              placeholder="Inspektionsansvarig"
            />

            <Input
              label="Telefonnummer"
              type="tel"
              {...register('phoneNumber')}
              placeholder="Telefonnummer"
              error={errors.phoneNumber}
            />

            <Input
              label="Kontrollanter/inspektörer"
              {...register('inspectors')}
              placeholder="Kontrollanter/inspektörer"
            />
          </S.BodyInner>
        </S.Body>

        <S.Footer>
          <S.Button $outlined onClick={navigateToWorkRegister}>
            Föregående
          </S.Button>

          <S.Button onClick={navigateToDescription}>Nästa</S.Button>
        </S.Footer>
      </S.FlyLeafInner>
    </S.FlyLeafContainer>
  );
};
