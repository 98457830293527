import { useMemo } from 'react';
import { FindAllControlsResponse } from '@ansvar-sakerhet/api-client';
import { format } from 'date-fns';

export const useControlsTableData = (controls: FindAllControlsResponse[]) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Datum',
        accessor: 'date',
      },
      {
        Header: 'Uppdragsgivare',
        accessor: 'clientName',
      },
      {
        Header: 'Projekt',
        accessor: 'projectName',
      },
      {
        Header: 'Kontrollnr',
        accessor: 'inspectionId',
      },
      {
        Header: 'Teamledare',
        accessor: 'teamLeader',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return controls.map((control) => {
      return {
        isRead: control.reportStatedAt !== null,
        date: format(new Date(control.date), 'yyyy/MM/dd HH:mm'),
        clientName: control.client.name,
        projectName: control.project.name,
        controlId: control.controlId,
        inspectionId: control.inspectionId,
        teamLeader: control.creatorName,
        actions: control.gdprDataDeletedAt,
      };
    });
  }, [controls]);

  return { columns, data };
};
