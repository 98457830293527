import { useMemo } from 'react';
import { useSummaryFormContext } from '../../../components/ControlLayout/ControlLayout';
import { useGetRiskColor } from './useGetRiskColor';

export const useRiskTableData = () => {
  const { watch } = useSummaryFormContext();

  const evaluate = (probability: number, consequence: number) => {
    if (!probability || !consequence) return { value: '-', color: 'none' };
    const value = probability + consequence;
    const color = useGetRiskColor(Math.round(value / 2));
    return { value, color };
  };

  const deviations = [
    {
      id: 1,
      evaluation: evaluate(
        Number(watch('shellProtectionProbability')),
        Number(watch('shellProtectionConsequence'))
      ),
      description: {
        title: 'Skalskydd/inhägnad',
        text: 'Kontroll av skalskydd/avgränsning kring byggarbetsplatsen.',
      },
    },

    {
      id: 2,
      evaluation: evaluate(
        Number(watch('workEnvironmentProbability')),
        Number(watch('workEnvironmentConsequence'))
      ),
      description: {
        title: 'Arbetsmiljö',
        text: 'Kontroll av arbetsmiljön på arbetsplatsen.',
      },
    },

    {
      id: 3,
      evaluation: evaluate(
        Number(watch('workRegisterProbability')),
        Number(watch('workRegisterConsequence'))
      ),
      description: {
        title: 'Elektronisk personalliggare',
        text: 'Kontroll av elektronisk personalliggare, hård och mjukvara.',
      },
    },
    {
      id: 4,
      evaluation: evaluate(
        Number(watch('identificationProbability')),
        Number(watch('identificationConsequence'))
      ),
      description: {
        title: 'Avsaknad av giltig legitimation',
        text: 'Personkontroll av giltig legitimation.',
      },
    },
    {
      id: 5,
      evaluation: evaluate(
        Number(watch('id06DeviationsProbability')),
        Number(watch('id06DeviationsConsequence'))
      ),
      description: {
        title: 'Brott mot ID06 regelverk',
        text: 'Personkontroll avseende ID06.',
      },
    },
    {
      id: 6,
      evaluation: evaluate(
        Number(watch('safetyEquipmentProbability')),
        Number(watch('safetyEquipmentConsequence'))
      ),
      description: {
        title: 'Personlig säkerhetsutrustning',
        text: 'Personlig säkerhetsutrustning i enlighet med arbetsplatsens krav på individen.',
      },
    },
    {
      id: 7,
      evaluation: evaluate(
        Number(watch('workPermitProbability')),
        Number(watch('workPermitConsequence'))
      ),
      description: {
        title: 'Kontroll av arbetstillstånd och uppehållshandlingar',
        text: 'I förekommande fall, kontroll av arbetstillstånd samt uppehållshandlingar.',
      },
    },
    {
      id: 8,
      evaluation: evaluate(
        Number(watch('preApprovedSubcontractorProbability')),
        Number(watch('preApprovedSubcontractorConsequence'))
      ),
      description: {
        title: 'Förgodkända UE',
        text: 'Inventering av underentreprenörer och i förekommande fall, kontroll gentemot projektbeställarens förteckning av förgodkända UE. Kontroll mot ID06 Bolagsdeklaration.',
      },
    },

    {
      id: 9,
      evaluation: evaluate(
        Number(watch('secondmentRegulationsProbability')),
        Number(watch('secondmentRegulationsConsequence'))
      ),
      description: {
        title: 'Utstationeringsregelverket',
        text: 'Kontroll av utländska bolag mot utstationeringsregistret.',
      },
    },
    {
      id: 10,
      evaluation: evaluate(
        Number(watch('intoxicationProbability')),
        Number(watch('intoxicationConsequence'))
      ),
      description: {
        title: 'Onykterhet och drogpåverkan',
        text: 'Tecken på onykterhet och drogpåverkan.',
      },
    },

    {
      id: 11,
      evaluation: evaluate(
        Number(watch('otherProbability')),
        Number(watch('otherConsequence'))
      ),
      description: {
        title: 'Övrig kontrollverksamhet',
        text: 'Ö̈vrig kontrollverksamhet (disk rimineringsrisker, misstankar om boende på arbetsplats).',
      },
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID#',
        accessor: 'id',
      },
      {
        Header: 'Riskvärdering',
        accessor: 'evaluation',
      },
      {
        Header: 'Beskrivning',
        accessor: 'description',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return deviations.map((row) => {
      return {
        id: row.id,
        evaluation: row.evaluation,
        description: row.description,
      };
    });
  }, [deviations]);

  return { columns, data };
};
